import React, {useState} from 'react';
import styles from './edit-calendar-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useQueryClient} from "react-query";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {Form, Input, Select} from "antd";
import {useCalendar} from "../view-calendar-screen/view-calendar-hooks";
import {useUpdateCalendar} from "./edit-calendar-hooks";

function EditCalendarScreen() {
    const { workspace, calendarId } = useParams();
    const customNavigation = useCustomNavigate();
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { data: calendar } = useCalendar(calendarId!, { enabled: !!calendarId });

    const { mutate: updateCalendar, isLoading } = useUpdateCalendar(calendarId!, (calendar) => {
        queryClient.refetchQueries(['calendar', calendarId]).then(() => {
            onBackClick();
        });
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        setErrorMessage(defaultMessage);
    }, {  });

    const onFinish = (values: any) => {
        const calendarData = {
            title: values.title,
            description: values.description,
            private: values.type === "private"
        }
        updateCalendar(calendarData);
    };

    const onFinishFailed = () => {

    };

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/calendars/${calendarId}`);
    };

    const formId = "edit-calendar";
    return (
        <CreateLayout
            title={"Edit Calendar"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.EditCalendarScreen}>
                {
                    calendar &&
                    <Form
                        layout="vertical"
                        id={formId}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                { required: true, message: "Please enter title" },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]
                            }
                            required
                            initialValue={calendar.title}
                        >
                            <Input
                                size="large"
                                placeholder="Enter title for calendar"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            initialValue={calendar.description}
                        >
                            <Input
                                size="large"
                                placeholder="Enter description for calendar"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            required
                            initialValue={calendar.private ? "private": "public"}
                        >
                            <Select
                                size="large"
                                placeholder="Select type"
                            >
                                <Select.Option value="public">Public</Select.Option>
                                <Select.Option value="private">Private</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                }
            </div>
        </CreateLayout>
    );
}

export default EditCalendarScreen;