import React from 'react';
import styles from './view-calendar-tag-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useCalendarTag} from "./view-calendar-tag-hooks";
import {Form} from "antd";
import CreateLayout from "../../../layouts/create-layout/create-layout";

function ViewCalendarTagScreen() {

    const { workspace, calendarId, calendarTagId } = useParams();
    const customNavigation = useCustomNavigate();
    const { data: calendarTag } = useCalendarTag(calendarTagId!, { enabled: !!calendarTagId });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/calendars/${calendarId}`);
    };

    const onEditClick = () => {
        customNavigation.push(`/${workspace}/calendars/${calendarId}/tags/${calendarTagId}/edit`);
    };

    return (
        <CreateLayout
            title={`View Calendar Tag`}
            formId={'view-calendar-tag'}
            onBackClick={onBackClick}
            isLoading={false}
            showSaveButton={false}
            editButtonTitle={'Edit'}
            onEditClick={onEditClick}
        >
            <>
                {
                    calendarTag &&
                    <Form
                        layout="vertical"
                        className={styles.ViewCalendarTagScreen}
                    >
                        <Form.Item
                            label="Name"
                        >
                            <div className={styles.value}>
                                {calendarTag?.name}
                            </div>
                        </Form.Item>
                    </Form>
                }
            </>
        </CreateLayout>
    );
}

export default ViewCalendarTagScreen;