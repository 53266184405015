import React from 'react';
import {useParams} from "react-router-dom";
import {useDocument} from "../view-document-screens/view-document-hooks";
import EditFreeFormDocumentScreen from "./edit-free-form-document-screen/edit-free-form-document-screen";
import EditListDocumentScreen from "./edit-list-document-screen/edit-list-document-screen";
import EditFlowDocumentScreen from "./edit-flow-ducment-screen/edit-flow-document-screen";

function EditDocumentScreen() {

    const { documentId } = useParams();
    const { data: document } = useDocument(documentId!, { enabled: !!documentId });

    return (
        <div>
            {
                document?.type === "FREE_FORM" &&
                <EditFreeFormDocumentScreen document={document} />
            }
            {
                document?.type === "LIST" &&
                <EditListDocumentScreen document={document} />
            }
            {
                document?.type === "FLOW" &&
                <EditFlowDocumentScreen document={document} />
            }
        </div>
    );
}

export default EditDocumentScreen;