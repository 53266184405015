import React from 'react';
import styles from './notifications-card.module.scss';
import {User} from "../../../models/User";
import {Notification} from "../../../models/Notification";
import {Button} from "antd";
import {convertTimeToDaysAgo} from "../../../utils/utils";

type NotificationsCardProps = {
    onUserAction: (user: User) => void;
    onFollowAction: (user: User) => void;
    followingUserIds: { [key: string]: string },
    notification: Notification;
    isLoading: boolean;
    currentId: string;
}

function NotificationsCard(props: NotificationsCardProps) {
    const {
        notification, followingUserIds, onUserAction, isLoading, currentId, onFollowAction,
    } = props;

    const {
        follow, type, contentTimeStamp, _id, createdAt,
    } = notification;

    return (
        <div className={styles.NotificationCard}>
            <div role="button" tabIndex={0} onClick={() => onUserAction(follow.follower)}>
                <img
                    className={styles.image}
                    src={follow.follower.image}
                    alt={follow.follower.image}
                />
            </div>
            <div className={styles.text}>
                <span role="button" tabIndex={0} className={styles.username} onClick={() => onUserAction(follow.follower)}>{follow.follower.name}</span>
                {' '}
                started following you.
                <div>{convertTimeToDaysAgo(createdAt)}</div>
            </div>
            {
                followingUserIds[`${follow.follower._id}`] === 'APPROVED'
                    ? <Button className={styles.follow} type="default">Following</Button>
                    : followingUserIds[`${follow.follower._id}`] === 'PENDING' ? <Button className={styles.follow} type="default">Requested</Button>
                    : (
                        <Button
                            className={styles.follow}
                            type="primary"
                            loading={currentId === _id && isLoading}
                            onClick={() => onFollowAction(follow.follower)}
                        >
                            Follow
                        </Button>
                    )
            }
        </div>
    );
}

export default NotificationsCard;