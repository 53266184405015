import React, {useEffect} from 'react';
import styles from './calendar-drawer-filters.module.scss';
import {Button, DatePicker, Drawer, Form, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {ActionTypes} from "../../../../actions/types";
import {CloseOutlined} from "@ant-design/icons";
import {CalendarFilters} from "../../../../models/CalendarFilters";
import moment from 'moment';
import {TimeStamps} from "../../../../models/TimeStamps";
import {useCalendarTagsByCalendar} from "../../../calendar-event-screens/create-calendar-event-screen/create-calendar-event-hooks";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {Calendar} from "../../../../models/Calendar";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

type CalendarDrawerFiltersProps = {
    currentWorkspaceId?: string;
}

const { Option, OptGroup } = Select;
function CalendarDrawerFilters(props: CalendarDrawerFiltersProps) {
    const { currentWorkspaceId } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const calendarFilters = useSelector<StoreState, CalendarFilters>((state) => state.calendarFilters);
    const isCalendarDrawerVisible = useSelector<StoreState, boolean>(state => state.isCalendarDrawerVisible)
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const location = useLocation();
    const locationSearch = queryString.parse(location.search) as { tab: string, year?: string, month?: string, day?: string, search?: string, tags?: string };
    const { calendarTag } = calendarFilters;

    const {
        data: allCalendars,
        isLoading: isCalendarsLoading,
        isFetching: isCalendarFetching,
    } = useInfiniteQuery(
        ['calendars'],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ calendars: Calendar[], nextId: number }>(`/api/calendars/workspaces/${currentWorkspaceId}?limit=${100}&page=${pageParam}&timestamp=${new Date()}`);
            return data;
        },
        {
            enabled: !!currentWorkspaceId,
            getNextPageParam: (lastPage) => lastPage.nextId,
        },
    );

    const onClose = () => {
        dispatch({ type: ActionTypes.SET_IS_CALENDAR_DRAWER_VISIBLE, value: false });
    };

    const onFinish = (values: any) => {
        const filters = {
            startDate: values.startDate?._d,
            endDate: values.endDate?._d,
            calendarTag: values.calendarTag,
        }
        dispatch({ type: ActionTypes.UPDATE_IS_AT_END_SCROLL, value: { CALENDARS: false } });
        dispatch({ type: ActionTypes.SET_CALENDAR_FILTERS, value: filters });
        onClose();
    };

    const onFinishFailed = () => {

    };

    const formId = "calendar-filters";

    const onResetClick = () => {
        const filters = {
            startDate: undefined,
            endDate: undefined,
            calendarTag: undefined
        }
        dispatch({ type: ActionTypes.SET_CALENDAR_FILTERS, value: filters });
        let newDate = new Date();
        const newTimestamps: Partial<TimeStamps> = { CREATE_CALENDAR_EVENT: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        form.setFieldsValue({ startDate: moment() });
        form.setFieldsValue({ calendarTag: null });
        onClose();
    };
    const calendars = allCalendars?.pages.map((page) => page.calendars).flat();
    const { data: allCalendarTagsByCalendar } = useCalendarTagsByCalendar(calendars?.map((c) => c._id) ?? [], { enabled: !!calendars && calendars.length > 0 });


    useEffect(() => {

    }, [calendarFilters]);

    const header = (<div className={styles.calendarDrawerFilterHeader}>
        <Button
            size="small"
            className={styles.close}
            type="link"
            icon={<CloseOutlined />}
            onClick={onClose}
        />
        <div className={styles.title}>
            Calendar filters
        </div>
        <Button size="small" type="link" onClick={onResetClick}>
            Reset
        </Button>
    </div>);

    return (
        <Drawer
            headerStyle={{ padding: '0px' }}
            title={header}
            closable={false}
            bodyStyle={{ padding: '0' }}
            placement="right"
            onClose={onClose}
            visible={isCalendarDrawerVisible}
        >
            <div className={styles.CalendarDrawerFilters}>
                <Form
                    layout="vertical"
                    form={form}
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={styles.formContainer}
                >
                    <Form.Item
                        label="Start Date"
                        name="startDate"
                        initialValue={calendarFilters.startDate ? moment(calendarFilters.startDate): undefined}
                    >
                        <DatePicker
                            className={styles.datePicker}
                            size="large"
                            placeholder={"Select start date"}
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Date"
                        name="endDate"
                        initialValue={calendarFilters.endDate ? moment(calendarFilters.endDate): undefined}
                    >
                        <DatePicker
                            className={styles.datePicker}
                            size="large"
                            placeholder={"Select end date"}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Tags"
                        name="calendarTag"
                        initialValue={calendarTag}
                    >
                        <Select placeholder="Select tag" showSearch allowClear size="large" optionFilterProp={"title"} >
                            {
                                allCalendarTagsByCalendar?.map((calendarTagByCalendar) => {
                                    return <OptGroup key={calendarTagByCalendar._id} label={calendarTagByCalendar.calendar.title}>
                                        {
                                            calendarTagByCalendar.tags.map((tag) => {
                                                return <Option key={tag._id} title={tag.name} value={tag._id}>{tag.name}</Option>
                                            })
                                        }
                                    </OptGroup>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
                <div className={styles.footer}>
                    <Button
                        className={styles.applyFilters}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        form={formId}
                    >
                        APPLY FILTERS
                    </Button>
                </div>
            </div>
        </Drawer>

    );
}

export default CalendarDrawerFilters;