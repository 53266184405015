import {ActionTypes} from "../actions/types";
import {Dispatch} from "redux";

export const updateTimeStamps = (value: any, callback: Function) => (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: ActionTypes.SET_TIME_STAMPS, value })
    callback();
};

export const toggleLoading = (callback: Function) => (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: ActionTypes.SET_LOADING, value: true });
    //dispatch({ type: ActionTypes.SET_TIME_STAMPS, value })
    callback();
};