import React from 'react';
import styles from './info-items-tab.module.scss';
import {Button, Form} from "antd";
import {Metric} from "../../../../models/Metric";
import {SyncOutlined} from "@ant-design/icons";
import {useSyncMetric} from "../view-metric-hooks";
import {useQueryClient} from "react-query";

type InfoItemsTabProps = {
    metric?: Metric
}

function InfoItemsTab(props: InfoItemsTabProps) {
    const { metric } = props;
    const queryClient = useQueryClient();

    const { mutate: syncMetricItems, isLoading } = useSyncMetric(metric?._id!, (metric) => {
        queryClient.refetchQueries(['sync-metric', 'metric', metric?._id]).then(() => {
        });
    }, (error) => {
    }, {  });

    const onSyncClick = () => {
        syncMetricItems({});
    }

    return (
        <div className={styles.InfoItemsTab}>
            <Form
                layout="vertical"
            >
                <Form.Item
                    label={"Title"}
                >
                    <div className={styles.value}>
                        {metric?.title}
                    </div>
                </Form.Item>
                {
                    metric?.description &&
                    <Form.Item
                        label={"Description"}
                    >
                        <div className={styles.value}>
                            {metric?.description}
                        </div>
                    </Form.Item>
                }
                <Form.Item
                    label={"Type"}
                >
                    <div className={styles.value}>
                        {metric?.type}
                    </div>
                </Form.Item>
                {
                    metric?.currency &&
                    <Form.Item
                        label={"Currency"}
                    >
                        <div className={styles.value}>
                            {metric?.currency}
                        </div>
                    </Form.Item>
                }
                {
                    metric?.ranking &&
                    <Form.Item
                        label={"Ranking"}
                    >
                        <div className={styles.value}>
                            {metric?.ranking}
                        </div>
                    </Form.Item>
                }
                <Form.Item
                    label={"Objective"}
                >
                    <div className={styles.value}>
                        {metric?.objective}
                    </div>
                </Form.Item>
                {
                    metric?.target &&
                    <Form.Item
                        label={"Target"}
                    >
                        <div className={styles.value}>
                            {metric?.target}
                        </div>
                    </Form.Item>
                }
                {
                    metric?.type === "METRICS_ITEMS_SUM" &&
                    <Form.Item
                        label={"Metric Items Sum"}
                    >
                        <div className={styles.value}>
                            {metric?.metricItemsSum}
                            <Button
                                type="link"
                                size="small"
                                loading={isLoading}
                                style={{ marginLeft: '16px' }}
                                icon={<SyncOutlined />}
                                onClick={onSyncClick}
                            />
                        </div>
                    </Form.Item>
                }

            </Form>
        </div>
    );
}

export default InfoItemsTab;