import {ActionTypes} from "../actions/types";
import {TimeStamps} from "../models/TimeStamps";
import {CalendarEvent} from "../models/CalendarEvent";

interface setTimeStamps {
    type: typeof ActionTypes.SET_TIME_STAMPS | typeof ActionTypes.UPDATE_TIME_STAMPS;
    value: TimeStamps;
}

interface setCalendarRecurringEvents {
    type: typeof ActionTypes.SET_CALENDAR_RECURRING_EVENTS;
    value: CalendarEvent[];
}

type homeCalendarActions = setTimeStamps | setCalendarRecurringEvents;

type homeCalendarValuesType = {
    search: string | undefined,
    nonRecurringLastDate: string | undefined
}

const defaultHomeCalendarValues: homeCalendarValuesType = {
    search: undefined,
    nonRecurringLastDate: undefined,
}

export const homeCalendarValues = (state: homeCalendarValuesType = defaultHomeCalendarValues, action: homeCalendarActions) => {
    if (action.type === ActionTypes.SET_TIME_STAMPS) {
        return action.value;
    }
    if (action.type === ActionTypes.UPDATE_TIME_STAMPS) {
        return {...state, ...action.value};
    }
    return state;
};

export const calendarRecurringEvents = (state: CalendarEvent[] = [], action: homeCalendarActions) => {
    if (action.type === ActionTypes.SET_CALENDAR_RECURRING_EVENTS) {
        return action.value;
    }
    return state;
};