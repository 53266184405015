import React from 'react';
import styles from './workspace-drawer-item.module.scss';
import {EllipsisOutlined} from "@ant-design/icons";
import {UserXWorkspace} from "../../../../../models/UserXWorkspace";
import {Button} from "antd";

type WorkspaceDrawerItemProps = {
    user_x_workspace: UserXWorkspace;
    onWorkspaceClick: (e: any, workspaceId: string) => void;
    workspace?: string;
    onEnterClick: (workspaceUniqueName: string) => void;
};

function WorkspaceDrawerItem(props: WorkspaceDrawerItemProps) {
    const { user_x_workspace, onWorkspaceClick, workspace, onEnterClick } = props;
    const { _id, uniqueName, displayName, image } = user_x_workspace.workspace;

    return (
        <div
            className={`${styles.WorkspaceDrawerItem} ${workspace === uniqueName && styles.activeWorkspaceDrawerItem}`}
            onClick={() => onEnterClick(uniqueName)}>
            <img
                className={`${styles.image} ${workspace === uniqueName && styles.activeImage}`}
                src={image}
            />
            <div className={styles.names}>
                <div className={styles.displayName}>
                    {displayName}
                </div>
                <div className={styles.uniqueName}>
                    {uniqueName}
                </div>
            </div>
            <div className={styles.actions}>
                <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    ghost
                    onClick={(e) => onWorkspaceClick(e, _id)} >
                    <EllipsisOutlined />
                </Button>
            </div>
        </div>
    );
}

export default WorkspaceDrawerItem;