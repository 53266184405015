import React, {useState} from 'react';
import styles from './create-list-document-screen.module.scss'
import CreateLayout from "../../../../layouts/create-layout/create-layout";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useCreateDocument} from "../create-document-hooks";
import {TimeStamps} from "../../../../models/TimeStamps";
import {ActionTypes} from "../../../../actions/types";
import {useCurrentUser, useCurrentWorkspace} from "../../../app-hooks";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import ViewListDocumentItem from "./view-list-document-item/view-list-document-item";
import {Button, Form, Input, Select} from "antd";
import {ListItem} from "../../../../models/ListItem";

function CreateListDocumentScreen() {

    const { workspace } = useParams();
    const customNavigation = useCustomNavigate();
    const [form] = Form.useForm();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const { data: currentUser } = useCurrentUser();
    const [items, setItems] = useState<ListItem[]>([]);
    const [itemText, setItemText] = useState<string>();
    const [counter, setCounter] = useState(0);
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!currentUser?._id && !!workspace })
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const dispatch = useDispatch();

    const { mutate: createDocument, isLoading } = useCreateDocument(currentWorkspace?._id, (document) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        const newTimestamps: Partial<TimeStamps> = { DOCUMENTS: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        onBackClick();
    }, (error) => {
        console.log("error: ",error.response);
        setErrorMessage("Something went wrong. Please try again.");
    }, {  });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/documents`);
    };

    const onConfirmAction = (itemKey: number, value: ListItem) => {
        items[itemKey] = value;
    };

    const onAddItemClick = () => {
        if (itemText) {
            setItems([...items, { index: counter, item: itemText, checked: false }]);
            setCounter(counter + 1);
            setItemText(undefined);
        }
    };

    const onDeleteAction = (itemKey: number) => {
        setItems(items.filter((ite) => ite.index !== itemKey));
    }

    const onTextChange = (data: any) => {
        setItemText(data.target.value);
    }

    const onFinish = (values: any) => {
        if (items.length > 0) {
            createDocument({ title: values.title, visibility: values.visibility, type: "LIST", listItems: items.map((item, index) => ({ index, item: item.item, checked: item.checked })) });
        }
    };

    const formId = "create-document";
    return (
        <CreateLayout
            title={"Create Document"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <Form
                layout="vertical"
                id={formId}
                form={form}
                onFinish={onFinish}
                className={styles.CreateListDocumentScreen}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        { required: true, message: "Please enter title" },
                        {
                            min: 3,
                            message: 'Please enter minimum of 3 letters'
                        }
                    ]
                    }
                    required
                >
                    <Input
                        size="large"
                        placeholder="Enter title for document"
                    />
                </Form.Item>
                <Form.Item
                    label="Visibility"
                    name="visibility"
                    required
                    initialValue={"PUBLIC"}
                >
                    <Select
                        size="large"
                        placeholder="Select type"
                    >
                        <Select.Option value="PUBLIC">Public</Select.Option>
                        <Select.Option value="PRIVATE">Private</Select.Option>
                    </Select>
                </Form.Item>
                <div>
                    {
                        items.map((item, index) => {
                            return <ViewListDocumentItem
                                key={`${item.index}`}
                                value={item}
                                onConfirmAction={onConfirmAction}
                                onDeleteAction={onDeleteAction}
                            />
                        })
                    }
                </div>
                <div>
                    <Input
                        placeholder={"Enter text..."}
                        size="large"
                        onChange={onTextChange}
                        value={itemText}
                        addonAfter={<Button type="primary" onClick={onAddItemClick} >Add</Button>}
                    />
                </div>
            </Form>
        </CreateLayout>
    );
}

export default CreateListDocumentScreen;