import React, {ReactNode} from 'react';
import {Button} from "antd";
import styles from './view-header.module.scss';
import {ArrowLeftOutlined} from "@ant-design/icons";

type ViewHeaderProps = {
    title?: string,
    onBackClick: () => void;
    onEditClick: () => void;
    iconComponent?: ReactNode
};

function ViewHeader(props: ViewHeaderProps) {
    const { title, onBackClick, onEditClick, iconComponent } = props;

    return (
        <div className={styles.ViewHeader}>
            <Button
                className={styles.goBack}
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={onBackClick}
            />
            <div className={styles.nameContainer}>
                <div className={styles.name}>
                    {title}
                </div>
                {
                    iconComponent &&
                    iconComponent
                }
            </div>
            <div className={styles.rightHeaderContent}>
                <Button
                    size="large"
                    onClick={onEditClick}
                >
                    Edit
                </Button>
            </div>
        </div>
    );
}

export default ViewHeader;