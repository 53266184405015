import React, {useState} from 'react';
import styles from './chats-friends-screen.module.scss';
import SearchableHeader from "../../components/searchable-header/searchable-header";
import Empty from "../../components/empty/empty";
import CardOneSkeleton from "../chats-screen/card-one-skeleton/card-one-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {Spin} from "antd";
import {Chat} from "../../models/Chat";
import ChatCard from "../chats-screen/chat-card/chat-card";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {useCurrentUser} from "../app-hooks";
import {User} from "../../models/User";
import CardUser from "../../components/card-user/card-user";
import {useCustomNavigate} from "../../hooks/navigation";

function ChatsFriendsScreen() {

    const customNavigation = useCustomNavigate();
    const [limit] = useState(12);
    const [searchQuery, setSearchQuery] = useState('');
    const [timeStamp] = useState(new Date());
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const { data: currentUser } = useCurrentUser();

    const {
        data,
        isFetching,
        fetchNextPage,
        isLoading,
    } = useInfiniteQuery(
        `friends-screen-${searchQuery}`,
        async (key, nextId = 0) => {
            const { data } = await axios.get<{ users: User[], nextId: number }>(`/api/users/friends?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
            if (data?.users?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage, pages) => lastPage.nextId,
            enabled: !!currentUser
        },
    );

    const onSearch = (data: string) => {
        //setSearchQuery(data);
    };

    const onBackClick = () => {
        customNavigation.replace('/chats');
    }

    const onCardSelect = (user: User) => {
        customNavigation.push(`/chats/${user._id}`)
    };

    return (
        <div className={styles.ChatsFriendsScreen}>
            <SearchableHeader
                onSearch={onSearch}
                onBackClick={onBackClick}
                title="Friends"
                placeholder="Search your friends"
            />
            <div className={styles.container}>
                {
                    isFetching === false && data?.pages.map((page) => page.users).flat().length === 0 &&
                    <Empty
                        title="Start your"
                        subTitle="Create notes in a few clicks"
                        icon="plus"
                        buttonTitle="Start chat"
                        url="/storynotes/create"
                        screen="chats"
                    />
                }
                {
                    isFetching && !data
                        ? (
                            [1, 1, 1, 1, 1, 1].map((item, index) => <CardOneSkeleton key={`${index}`} />)
                        ) : (
                            <InfiniteScroll
                                dataLength={data?.pages.map((page) => page.users).flat().length || 0}
                                next={() => fetchNextPage()}
                                hasMore={(data?.pages.map((page) => page.users).flat().length || 0) > 0 && !isAtTheEnd}
                                loader={(
                                    <div className={styles.spinningContainer}>
                                        <Spin />
                                    </div>
                                )}
                                endMessage={(<p style={{ textAlign: 'center' }} />)}
                            >
                                {
                                    data?.pages.map((page) => page.users).flat()
                                        .map((user) => (
                                            <CardUser
                                                key={user._id}
                                                user={user}
                                                currentUserId={currentUser?._id || ''}
                                                followingUserIds={{}}
                                                onCardSelect={onCardSelect}
                                            />
                                        ))
                                }
                            </InfiniteScroll>
                        )
                }
            </div>
        </div>
    );
}

export default ChatsFriendsScreen;