import React, {useState} from 'react';
import styles from './list-metrics-screen.module.scss';
import ListHeader from "../../../components/list-header/list-header";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import MetricsItem from "./metrcis-item/metrics-item";
import {useParams} from "react-router-dom";
import {Metric} from "../../../models/Metric";
import {useCurrentWorkspace} from "../../app-hooks";
import GridLayout from "../../../layouts/grid-layout/grid-layout";
import {useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {TimeStamps} from "../../../models/TimeStamps";

function ListMetricsScreen() {

    const { workspace } = useParams();
    const customNavigate = useCustomNavigate();
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const [search, setSearch] = useState('');
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace })

    const onCreateClick = () => {
        customNavigate.push(`/${workspace}/metrics/create`);
    };

    const onSearch = (value: string) => {
        setSearch(value);
    };

    const {
        data: metrics,
        isLoading,
        fetchNextPage,
    } = useInfiniteQuery(
        ['metrics', timeStamps.METRICS, search],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ metrics: Metric[], nextId: number }>(`/api/metrics/workspaces/${currentWorkspace?._id}?limit=${12}&page=${pageParam}&timestamp=${timeStamps.METRICS}&search=${search}`);
            if (data?.metrics?.length < 12) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!currentWorkspace
        },
    );

    const onMetricClick = (metric: Metric) => {
        customNavigate.push(`/${workspace}/metrics/${metric._id}`);
    }

    return (
        <>
            <ListHeader
                title={"Metrics"}
                onCreateClick={onCreateClick}
                onSearch={onSearch}
                searchPlaceholder={"Search your metrics"}
            />
            <div className={styles.ListMetricsScreen}>
                <GridLayout
                    isFetching={false}
                    isLoading={isLoading}
                    data={metrics?.pages.map((page) => page.metrics).flat()}
                    fetchNextPage={fetchNextPage}
                    isAtTheEnd={isAtTheEnd}
                    emptyView={{
                        title: "",
                        subTitle: "",
                        url: "",
                        icon: "",
                        buttonTitle: "",
                        screenItem: ""
                    }}
                >
                    <>
                        {
                            metrics?.pages.map((page) => page.metrics).flat().map((metric) => {
                                return <MetricsItem
                                    key={metric?._id}
                                    metric={metric}
                                    onMetricClick={onMetricClick}
                                />
                            })
                        }
                    </>
                </GridLayout>
            </div>
        </>
    );
}

export default ListMetricsScreen;