import React, {useState} from 'react';
import styles from './list-workspaces-sceen.module.scss';
import ListHeader from "../../../components/list-header/list-header";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useInfiniteQuery} from "react-query";
import axios from 'axios';
import WorkspacesItem from "./workspaces-item/workspaces-item";
import ListLayout from "../../../layouts/list-layout/list-layout";
import {UserXWorkspace} from "../../../models/UserXWorkspace";
import {useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {TimeStamps} from "../../../models/TimeStamps";
import {Helmet} from "react-helmet-async";
// @ts-ignore
import MetaTags from 'react-meta-tags';

function ListWorkspacesScreen() {
    const customNav = useCustomNavigate();
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const [limit] = useState(12);
    const [search, setSearch] = useState('');

    const {
        data: users_x_workspaces,
        isLoading,
        fetchNextPage,
    } = useInfiniteQuery(
        ['workspaces', limit, timeStamps.WORKSPACES, search],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ users_x_workspaces: UserXWorkspace[], nextId: number }>(`/api/workspaces?limit=${limit}&page=${pageParam}&timestamp=${timeStamps.WORKSPACES}&search=${search}&roles=ADMIN,MEMBER,INVITE`);
            if (data?.users_x_workspaces?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
        },
    );

    const onCreateClick = () => {
        customNav.push('/workspaces/create');
    };

    const onSearch = (value: string) => {
        setSearch(value);
    };

    const onWorkspaceClick = (workspaceId: string) => {
        customNav.push(`/workspaces/${workspaceId}`);
    };

    const onAcceptClick = (e: any) => {
        e.stopPropagation();
    };

    const onEnterClick = (e: any, workspaceUniqueName: string) => {
        e.stopPropagation();
        customNav.replace(`/${workspaceUniqueName}`);
    };

    return (
        <>
            <Helmet>
                <title>{"bruuh"}</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="description" key="description" content={"bruuh descr"} />
                <meta name="title" key="title" content={"bruh tit"} />
                <meta property="og:title" key="og:title" content={"bruh tit tit"} />
                <meta property="og:locale" key="og:locale" content="en_US" />
                <meta charSet="utf-8" />
                <meta property="og:type" key="og:type" content="website" />
                <meta
                    property="og:description"
                    key="og:description"
                    content={"good des"}
                />
                <meta
                    property="og:image"
                    key="og:image"
                    content={"https://i.ibb.co/PGwspCv/goodlores-new2.png"}
                />
            </Helmet>
            {/*<Helmet>*/}
            {/*    <title>no ways</title>*/}
            {/*    <meta name="description" content="Nested component" data-react-helmet="true" />*/}
            {/*    <meta name="og:title" content="Please work" data-react-helmet="true" />*/}
            {/*    <meta name="og:description" content="Please work" data-react-helmet="true" />*/}
            {/*    <meta name="title" content="Default Title updated" data-react-helmet="true" />*/}
            {/*    <meta property="og:image" itemProp="image" content="https://i.ibb.co/PGwspCv/goodlores-new2.png"/>*/}
            {/*</Helmet>*/}
            <MetaTags>
                <title>Page 1</title>
                <meta name="description" content="Some description." />
                <meta property="og:title" content="MyApp" />
                <meta property="og:description" content="MyApp" />
                <meta property="og:image" content="https://i.ibb.co/PGwspCv/goodlores-new2.png" />
            </MetaTags>
            <ListHeader
                title={"Workspaces2"}
                onCreateClick={onCreateClick}
                onSearch={onSearch}
                searchPlaceholder={"Search your workspaces"}
            />
            <div className={styles.ListWorkspacesScreen}>
                <ListLayout
                    isFetching={isLoading}
                    data={users_x_workspaces?.pages.map((page) => page.users_x_workspaces).flat()}
                    fetchNextPage={fetchNextPage}
                    isAtTheEnd={isAtTheEnd}
                    emptyButtonTitle={"Create Workspace"}
                    skeletonCardHeight={100}
                >
                    <>
                        {
                            users_x_workspaces?.pages.map((page) => page.users_x_workspaces).flat().map((user_x_workspace) => {
                                return <WorkspacesItem
                                    key={user_x_workspace.workspace?._id}
                                    user_x_workspace={user_x_workspace}
                                    onWorkspaceClick={onWorkspaceClick}
                                    onAcceptClick={onAcceptClick}
                                    onEnterClick={onEnterClick}
                                />
                            })
                        }
                    </>
                </ListLayout>
            </div>
        </>
    );
}

export default ListWorkspacesScreen;