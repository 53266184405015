import React from 'react';
import styles from './view-free-form-document-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../../hooks/navigation";
import { Document } from '../../../../models/Document';
import ViewHeader from "../../../../components/view-header/view-header";
import FreeFormDocument from "./free-form-document/free-form-document";
import {LockOutlined} from "@ant-design/icons";

type ViewFreeFormDocumentScreenProps = {
    document: Document;
}

function ViewFreeFormDocumentScreen(props: ViewFreeFormDocumentScreenProps) {
    const { document } = props;
    const { workspace, documentId } = useParams();
    const customNavigate = useCustomNavigate();

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/documents`);
    };

    const onEditClick = () => {
        customNavigate.push(`/${workspace}/documents/${documentId}/edit`);
    };

    return (
        <div className={styles.ViewFreeFormDocumentScreen}>
            <ViewHeader
                title={document?.title}
                iconComponent={document?.visibility === "PRIVATE" && <LockOutlined className={styles.privateIcon} />}
                onBackClick={onBackClick}
                onEditClick={onEditClick}
            />
            {
                document?.type === "FREE_FORM" &&
                <FreeFormDocument document={document} />
            }
        </div>
    );
}

export default ViewFreeFormDocumentScreen;