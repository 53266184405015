import React, {useState} from 'react';
import styles from './edit-flow-document-screen.module.scss';
import {Document} from "../../../../models/Document";
import {Form, Input, Select} from "antd";
import CreateLayout from "../../../../layouts/create-layout/create-layout";
import {useParams} from "react-router-dom";
import {useQueryClient} from "react-query";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useUpdateDocument} from "../edit-document-hooks";

type EditFlowDocumentScreenProps = {
    document: Document;
}

function EditFlowDocumentScreen(props: EditFlowDocumentScreenProps) {
    const { document } = props;
    const { workspace, documentId } = useParams();
    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const customNavigation = useCustomNavigate();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const { mutate: updateDocument, isLoading } = useUpdateDocument(documentId!, (workspace) => {
        queryClient.refetchQueries(['document', documentId]).then(() => {
            onBackClick();
        });
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        setErrorMessage(defaultMessage);
    }, {  });

    const onFinish = (values: any) => {
        updateDocument(values);
    }

    const formId = "edit-flow-document";

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/documents/${documentId}`);
    };

    return (
        <CreateLayout
            title={"Edit Document"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.EditFlowDocumentScreen}>
                {
                    document &&
                    <Form
                        layout="vertical"
                        id={formId}
                        form={form}
                        onFinish={onFinish}
                        className={styles.CreateListDocumentScreen}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                { required: true, message: "Please enter title" },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]
                            }
                            required
                            initialValue={document.title}
                        >
                            <Input
                                size="large"
                                placeholder="Enter title for document"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Visibility"
                            name="visibility"
                            required
                            initialValue={document.visibility}
                        >
                            <Select
                                size="large"
                                placeholder="Select type"
                            >
                                <Select.Option value="PUBLIC">Public</Select.Option>
                                <Select.Option value="PRIVATE">Private</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                }
            </div>
        </CreateLayout>
    );
}

export default EditFlowDocumentScreen;