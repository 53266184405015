import {useMutation, useQuery, UseQueryOptions} from "react-query";
import axios, {AxiosError} from "axios";
import {Workspace} from "../../../models/Workspace";
import {UseMutationOptions} from "react-query/types/react/types";

export const useWorkspace = (workspaceId: string, options?: UseQueryOptions<Workspace, AxiosError, Workspace>) => {
    return useQuery<Workspace, AxiosError, Workspace>(['workspace', workspaceId], async () => {
            const res = await axios.get<Workspace>(`/api/workspaces/${workspaceId}`);
            return res.data;
        },
        {
            ...options,
            staleTime: 5000,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};

export const useUpdateWorkspace = (workspaceId: string, onSuccess: (data: Workspace) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<Workspace, AxiosError, FormData>) =>
    useMutation<Workspace, AxiosError, FormData>(['update-workspace', workspaceId], async (workspaceData) => {
        const res = await axios.put<Workspace>(`/api/workspaces/${workspaceId}`, workspaceData);
        return res.data;
    }, { ...options, onSuccess, onError, });