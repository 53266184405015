import React, {useState} from 'react';
import styles from './create-calendar-screen.module.scss';
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useCurrentUser, useCurrentWorkspace} from "../../app-hooks";
import {useQueryClient} from "react-query";
import {Form, Input, Select} from "antd";
import {useCreateCalendar} from "./create-calendar-hooks";

function CreateCalendarScreen() {
    const { workspace } = useParams();
    const customNavigation = useCustomNavigate();
    const { data: currentUser } = useCurrentUser();
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!currentUser?._id && !!workspace })
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const { mutate: createCalendar, isLoading } = useCreateCalendar(currentWorkspace?._id, (calendar) => {
        queryClient.invalidateQueries('calendars');
        onBackClick();
    }, (error) => {
        console.log("error: ",error.response);
        setErrorMessage("Something went wrong. Please try again.");
    }, {  });

    const onFinish = (values: any) => {
        const calendarData = {
            title: values.title,
            description: values.description,
            private: values.type === "private"
        }
        createCalendar(calendarData);
    };

    const onFinishFailed = () => {

    };

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/calendars`);
    };

    const formId = "create-calendar";
    return (
        <CreateLayout
            title={"Create Calendar"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.CreateCalendarScreen}>
                <Form
                    layout="vertical"
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            { required: true, message: "Please enter title" },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter title for calendar"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input
                            size="large"
                            placeholder="Enter description for calendar"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        name="type"
                        required
                        initialValue={"private"}
                    >
                        <Select
                            size="large"
                            placeholder="Select type"
                        >
                            <Select.Option value="public">Public</Select.Option>
                            <Select.Option value="private">Private</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default CreateCalendarScreen;