import React from 'react';
import styles from './how-it-works.module.scss';
import HowItWorksItem from './how-it-works-item/how-it-works-item';
import {useTranslation} from "react-i18next";
import metrics from '../../../assets/images/metrics.svg';
import teams from '../../../assets/images/teams.svg';
import calendar from '../../../assets/images/calendar.svg';

function HowItWorks() {
  const {t} = useTranslation ();
  const products: any[] = [
    {
      title: t('main.metrics-title'),
      subtitle: t('main.metrics-subtitle'),
      description: t('main.metrics-description'),
      image: metrics,
      link: 'https://techbiits.com',
    },
    {
      title: t('main.teams-title'),
      subtitle: t('main.teams-subtitle'),
      description: t('main.teams-description'),
      image: teams,
      link: 'https://techbiits.com',
    },
    {
      title: t('main.calendar-title'),
      subtitle: t('main.calendar-subtitle'),
      description: t('main.calendar-description'),
      image: calendar,
      link: 'https://techbiits.com',
    },
  ];

  return (
    <div className={styles.HowItWorks}>
      <div className={styles.title}>
        {t("main.simple")}
      </div>
      <div className={styles.subtitle}>
        {t("main.packed")}
      </div>
      <div className={styles.container}>
        {
          products.map((val, index) => (<HowItWorksItem key={`productItem${index}`} product={val} />))
        }
      </div>
    </div>
  );
}

export default HowItWorks;
