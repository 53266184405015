import React, {useEffect} from 'react';
import {Checkbox, Input} from "antd";
import styles from "./view-list-document-item.module.scss";

type ViewListDocumentItemProps = {
    value: string;
    index: number;
    checked: boolean;
    onCheckboxChange?: (itemKey: number, checked: boolean) => void;
}

function ViewListDocumentItem(props: ViewListDocumentItemProps) {
    const { value, index, onCheckboxChange, checked } = props;

    const onChange = (data: any) => {
        if (onCheckboxChange) {
            onCheckboxChange(index, data.target.checked)
        }
    }

    return (
        <div className={styles.ViewListDocumentItem}>
            <Checkbox onChange={onChange} checked={checked}>
                <span className={styles.text}>{value}</span>
            </Checkbox>
        </div>
    );
}

export default ViewListDocumentItem;