import React from 'react';
import styles from './list-layout.module.scss';
import Empty from "../../components/empty/empty";
import CardOneSkeleton from "../../screens/chats-screen/card-one-skeleton/card-one-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {Skeleton, Spin} from "antd";

type ListLayoutProps = {
    isFetching: boolean,
    data: any[] | undefined,
    fetchNextPage: () => void;
    isAtTheEnd: boolean;
    children: JSX.Element,
    emptyButtonTitle?: string;
    skeletonCardHeight: number;
};

function ListLayout(props: ListLayoutProps) {
    const { isFetching, data, fetchNextPage, isAtTheEnd, children, emptyButtonTitle, skeletonCardHeight } = props;

    return (
        <div className={styles.ListLayout}>
            {
                (isFetching === true) && data === undefined &&
                <div className={styles.skeletons}>
                    {
                        Array.from(Array(Math.floor(window.innerHeight/((skeletonCardHeight)*1.5))).keys()).map((val, index) => {
                            return <Skeleton.Input key={`${index}`} active className={styles.skeletonItem} style={{ height: skeletonCardHeight }} />
                        })
                    }
                </div>
            }
            {
                isFetching === false && data?.length === 0 &&
                <Empty
                    title="Start your"
                    subTitle="Create notes in a few clicks"
                    icon="plus"
                    buttonTitle={emptyButtonTitle}
                    url="/storynotes/create"
                    screen="chats"
                />
            }
            {
                !isFetching && !!data?.length &&
                <InfiniteScroll
                    dataLength={data?.length || 0}
                    next={() => fetchNextPage()}
                    hasMore={(data?.length || 0) > 0 && !isAtTheEnd}
                    loader={(
                        <div className={styles.spinningContainer}>
                            <Spin />
                        </div>
                    )}
                    endMessage={(<p style={{ textAlign: 'center' }} />)}
                >
                    {children}
                </InfiniteScroll>
            }
        </div>
    );
}

export default ListLayout;