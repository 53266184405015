import React from 'react';
import styles from './bottom-nav-bar.module.scss';
import {
    BarChartOutlined,
    CalendarOutlined,
    SnippetsOutlined,
} from "@ant-design/icons";
import {useLocation, useParams} from "react-router-dom";
import {useCurrentUser} from "../../screens/app-hooks";
import {useCustomNavigate} from "../../hooks/navigation";

function BottomNavBar() {

    const { workspace, item } = useParams();
    const customNavigation = useCustomNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const { data: currentUser } = useCurrentUser();

    const tabs = ['home','explore','trending','notifications', 'chats'];
    const urlValue = pathname.replace('/','');

    if (!currentUser) {
        return <div/>
    }

    const showNav = () => {

        if (pathname.includes('workspaces')) {
            return false;
        }

        if (!!item) {
            return false;
        }

        return true;
    };

    const onMetricsClick = () => {
        customNavigation.push(`/${workspace}/metrics`)
    }

    const onCalendarsClick = () => {
        customNavigation.push(`/${workspace}/calendars`)
    }

    const onDocumentsClick = () => {
        customNavigation.push(`/${workspace}/documents`)
    }

    return (
        <div className={showNav() ? styles.BottomNavBar : styles.none}>
            <div
                className={`${styles.item} ${pathname.includes('metrics') && styles.active}`}
                onClick={onMetricsClick}
            >
                <BarChartOutlined
                    className={`${styles.icon} ${pathname.includes('metrics') && styles.active}`}
                />
            </div>
            <div
                className={`${styles.item} ${pathname.includes('calendars') && styles.active}`}
                onClick={onCalendarsClick}
            >
                <CalendarOutlined
                    className={`${styles.icon} ${pathname.includes('calendars') && styles.active}`}
                />
            </div>
            <div
                className={`${styles.item} ${pathname.includes('documents') && styles.active}`}
                onClick={onDocumentsClick}
            >
                <SnippetsOutlined
                    className={`${styles.icon} ${pathname.includes('documents') && styles.active}`}
                />
            </div>
            {/*<div*/}
            {/*    className={`${styles.item} ${pathname.includes('notifications') && styles.active}`}*/}
            {/*    onClick={() => { customNavigation.replace('/notifications'); }}*/}
            {/*>*/}
            {/*    <BellOutlined*/}
            {/*        className={`${styles.icon} ${pathname.includes('notifications') && styles.active}`}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
}

export default BottomNavBar;