import React from 'react';
import styles from './footer.module.scss';
import {Button} from "antd";
import {InstagramOutlined, TwitterOutlined} from "@ant-design/icons";

function Footer() {
    return (
        <div className={styles.Footer}>
            <div className={styles.container} >
                <div className={styles.social} >
                    <Button
                        className={styles.twitter}
                        type="default"
                        shape="circle"
                        icon={<TwitterOutlined/>}
                    />
                    <Button
                        className={styles.instagram}
                        type="default"
                        shape="circle"
                        icon={<InstagramOutlined />}
                    />
                </div>
                <div className={styles.copyright} >
                    © {new Date().getFullYear() } NavyFlow
                </div>
            </div>
        </div>
    );
}

export default Footer;