import {useMutation, useQuery, UseQueryOptions, UseMutationOptions} from "react-query";
import axios, {AxiosError} from "axios";
import {CalendarEvent} from "../../../models/CalendarEvent";

export const useCreateCalendarCompletedEvent = (workspaceId: string | undefined, onSuccess: (data: CalendarEvent) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<CalendarEvent, AxiosError, CalendarEvent>) =>
    useMutation<CalendarEvent, AxiosError, any>(['create-calendar-completed-events'], async (calendarCompletedEventData) => {
        const res = await axios.post<CalendarEvent>(`/api/calendar-completed-events/workspaces/${workspaceId}`, {...calendarCompletedEventData});
        return res.data;
    }, { ...options, onSuccess, onError, });

export const useCalendarEvent = (calendarEventId: string, options?: UseQueryOptions<CalendarEvent, AxiosError, CalendarEvent>) => {
    return useQuery<CalendarEvent, AxiosError, CalendarEvent>(['calendarEvent', calendarEventId], async () => {
            const res = await axios.get<CalendarEvent>(`/api/calendar-events/${calendarEventId}`);
            return res.data;
        },
        {
            ...options,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};