import React, {useState} from 'react';
import styles from './notifications-screen.module.scss';
import {Notification} from "../../models/Notification";
import {useInfiniteQuery, useQueryClient} from "react-query";
import axios from "axios";
import SearchableHeader from "../../components/searchable-header/searchable-header";
import Empty from "../../components/empty/empty";
import CardOneSkeleton from "../chats-screen/card-one-skeleton/card-one-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {Spin} from "antd";
import {useCurrentUser} from "../app-hooks";
import {User} from "../../models/User";
import NotificationsCard from "./notifications-card/notifications-card";
import {useCustomNavigate} from "../../hooks/navigation";

function NotificationsScreen() {

    const customNavigation = useCustomNavigate();
    const { data: currentUser } = useCurrentUser();
    const [limit] = useState(12);
    const [searchQuery, setSearchQuery] = useState('');
    const [timeStamp] = useState(new Date());
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const queryClient = useQueryClient();

    const {
        data,
        isFetching,
        fetchNextPage,
        isLoading,
    } = useInfiniteQuery(
        `notifications-screen`,
        async (key, nextId = 0) => {
            const { data } = await axios.get<{ notifications: Notification[], followingUserIds: { [key: string]: string }, nextId: number }>(`/api/notifications?search=${searchQuery}&limit=${limit}&page=${nextId}&timestamp=${timeStamp}`);
            if (data?.notifications?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage, pages) => lastPage.nextId,
            enabled: !!currentUser
        },
    );

    const onSearch = (data: string) => {
        setSearchQuery(data);
    };

    const onCreateClick = () => {
        customNavigation.push('/storynotes/create');
    };

    const onChatClick = () => {

    };

    // if (!currentUser) {
    //     return <JoinUs/>
    // }

    const onUserAction = (user: User) => {
        customNavigation.push(`/${user.name}`);
    };

    const onFollowAction = (user: User) => {
    }

    return (
        <div className={styles.NotificationsScreen}>
            <SearchableHeader
                title="Notifications"
                placeholder="Search your notifications"
            />
            <div className={styles.container}>
                {
                    isFetching === false && (data?.pages.map((page) => page.notifications).flat().length === 0) &&
                    <Empty
                        title="Start your"
                        subTitle="Create notes in a few clicks"
                        icon="plus"
                        buttonTitle="Start chat"
                        url="/storynotes/create"
                        screen="notifications"
                        hideButton
                    />
                }
                {
                    isFetching && !data
                        ? (
                            [1, 1, 1, 1, 1, 1].map((item, index) => <CardOneSkeleton key={`${index}`} />)
                        ) : (
                            <InfiniteScroll
                                dataLength={data?.pages.map((page) => page.notifications).flat().length || 0}
                                next={() => fetchNextPage()}
                                hasMore={(data?.pages.map((page) => page.notifications).flat().length || 0) > 0 && !isAtTheEnd}
                                loader={(
                                    <div className={styles.spinningContainer}>
                                        <Spin />
                                    </div>
                                )}
                                endMessage={(<p style={{ textAlign: 'center' }} />)}
                            >
                                {
                                    data?.pages.map((page) => page.notifications).flat()
                                        .map((notification: Notification) => (
                                            <NotificationsCard
                                                key={notification._id}
                                                notification={notification}
                                                currentId={''}
                                                isLoading={isLoading}
                                                followingUserIds={data?.pages.reduce((obj, item) => Object.assign(obj, { ...item.followingUserIds }), {})}
                                                onFollowAction={onFollowAction}
                                                onUserAction={onUserAction}
                                            />
                                        ))
                                }
                            </InfiniteScroll>
                        )
                }
            </div>
        </div>
    );
}

export default NotificationsScreen;