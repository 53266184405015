import React, {useState} from 'react';
import styles from './calendar-view.module.scss';
import {Calendar as CalendarComponent, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {CalendarEventXCalendars} from "../../../../models/CalendarXCalendarEvent";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {useCurrentWorkspace} from "../../../app-hooks";
import {TimeStamps} from "../../../../models/TimeStamps";
import {DateTime} from "luxon";
import {Button} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";

const localizer = momentLocalizer(moment);
function CalendarView() {

    const { workspace } = useParams();
    const customNavigate = useCustomNavigate();
    const [limit] = useState(1000);
    const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace });

    const getStartOfMonth = (dateTime: DateTime) => {
        return dateTime.set({ day: 1 });
    }

    const getEndOfMonth = (dateTime: DateTime) => {
        const firstOfCurrentMonth = getStartOfMonth(dateTime);
        const nextMonth = firstOfCurrentMonth.plus({ month: 1 });
        return nextMonth.minus({ day: 1 });
    }
    const currentDateStartDate = getStartOfMonth(currentDate);
    const currentDateEndDate = getEndOfMonth(currentDate);
    const {
        data: pagedCalendarEventsXCalendars,
    } = useInfiniteQuery(
        ['calendar-view', currentDate],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ calendarEventsXCalendars: CalendarEventXCalendars[], nextId: number }>(`/api/calendar-events/workspaces/${currentWorkspace?._id}?limit=${limit}&page=${pageParam}&timestamp=${timeStamps.CALENDAR_VIEW}&startDate=${currentDateStartDate.toJSDate() ?? ''}&endDate=${currentDateEndDate.toJSDate() ?? ''}`);
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!currentWorkspace?._id,
            keepPreviousData: true,
        },
    );
    const calendarEventsXCalendars = pagedCalendarEventsXCalendars?.pages.map((page) => page.calendarEventsXCalendars).flat()

    const onNavigate = (dataNav: Date, next: any, test: any) => {
        console.log("dataNav - onNavigate: ",DateTime.fromJSDate(dataNav).toLocal());
        console.log("dataNav - next: ",next);
        console.log("dataNav - test: ",test);
    }

    const onRangeChange = (data: any) => {
        console.log("onRangeChange: ",data);
    };

    const onSelectSlot = (data: any) => {
        customNavigate.push(`/${workspace}/calendars/events/${data.resource._id}`);
    };

    const onSelectEvent = (data: any) => {
        customNavigate.push(`/${workspace}/calendars/events/${data.resource._id}`);
    };

    const onNextClick = () => {
        setCurrentDate((prevState) => {
            return prevState.plus({ month: 1 });
        });
    };

    const onPrevClick = () => {
        setCurrentDate((prevState) => {
            return prevState.plus({ month: -1 });
        });
    };

    const head = (data: any) => {
        return <div className={styles.callyHeader}>
            <Button type="link" icon={<ArrowLeftOutlined />} onClick={onPrevClick}/>
            <div className={styles.title}>{data.label}</div>
            <Button type="link" icon={<ArrowRightOutlined />} onClick={onNextClick}/>
        </div>
    }

    return (
        <div className={styles.CalendarView}>
            <CalendarComponent
                className={styles.cally}
                localizer={localizer}
                events={calendarEventsXCalendars?.map((c) => {
                    return {
                        title: c.calendarEvent.title,
                        start: c.calendarEvent.startDate,
                        end: c.calendarEvent.endDate,
                        allDay: c.calendarEvent.allDay,
                        resource: c.calendarEvent,
                    }
                }) ?? []}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                views={['month']}
                onNavigate={onNavigate}
                date={currentDate.toJSDate()}
                // onRangeChange={onRangeChange}
                onSelectSlot={onSelectSlot}
                onSelectEvent={onSelectEvent}
                components={{ toolbar: head }}
            />
        </div>
    );
}

export default CalendarView;