import React, {useState} from 'react';
import styles from './edit-list-document-screen.module.scss';
import {Document} from "../../../../models/Document";
import CreateLayout from "../../../../layouts/create-layout/create-layout";
import {useParams} from "react-router-dom";
import {useQueryClient} from "react-query";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useUpdateDocument} from "../edit-document-hooks";
import {TimeStamps} from "../../../../models/TimeStamps";
import {ActionTypes} from "../../../../actions/types";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {Button, Form, Input, Select} from "antd";
import ViewListDocumentItem
    from "../../create-document-screens/create-list-document-screen/view-list-document-item/view-list-document-item";
import {ListItem} from "../../../../models/ListItem";

type EditListDocumentScreenProps = {
    document: Document;
}

function EditListDocumentScreen(props: EditListDocumentScreenProps) {
    const { document } = props;

    const { workspace, documentId } = useParams();
    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const [items, setItems] = useState<ListItem[]>(document.listItems ?? []);
    const [item, setItem] = useState<ListItem>();
    const [counter, setCounter] = useState(document.listItems?.length ?? 0);
    const customNavigate = useCustomNavigate();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const dispatch = useDispatch();

    const { mutate: updateDocument, isLoading } = useUpdateDocument(documentId!, (workspace) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        const newTimestamps: Partial<TimeStamps> = { DOCUMENTS: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        queryClient.refetchQueries(['document', documentId]).then(() => {
            onBackClick();
        });
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        setErrorMessage(defaultMessage);
    }, {  });

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/documents/${documentId}`);
    };

    const onAddItemClick = () => {
        if (item) {
            setItems([...items, item]);
            setCounter(counter + 1);
            setItem(undefined);
        }
    };

    const onTextChange = (data: any) => {
        setItem({ index: counter ?? 0, item: data.target.value, checked: false } );
    }

    const onConfirmAction = (itemKey: number, value: ListItem) => {
        items[itemKey] = value;
    };

    const onFinish = (values: any) => {
        if (items.length > 0) {
            updateDocument({ title: values.title, visibility: values.visibility, listItems: items.map((item, index) => ({ index, item: item.item, checked: item.checked })) });
        }
    };

    const onDeleteAction = (itemKey: number) => {
        setItems([...items.filter((ite) => ite.index !== itemKey)]);
    }

    const formId = "edit-document";

    return (
        <CreateLayout
            title={"Edit Document"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.EditListDocumentScreen}>
                {
                    document &&
                    <Form
                        layout="vertical"
                        id={formId}
                        form={form}
                        onFinish={onFinish}
                        className={styles.CreateListDocumentScreen}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                { required: true, message: "Please enter title" },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]
                            }
                            required
                            initialValue={document.title}
                        >
                            <Input
                                size="large"
                                placeholder="Enter title for document"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Visibility"
                            name="visibility"
                            required
                            initialValue={document.visibility}
                        >
                            <Select
                                size="large"
                                placeholder="Select type"
                            >
                                <Select.Option value="PUBLIC">Public</Select.Option>
                                <Select.Option value="PRIVATE">Private</Select.Option>
                            </Select>
                        </Form.Item>
                        <div>
                            {
                                items.map((item, index) => {
                                    return <ViewListDocumentItem
                                        key={`${item.index}`}
                                        value={item}
                                        onDeleteAction={onDeleteAction}
                                        onConfirmAction={onConfirmAction}
                                    />
                                })
                            }
                        </div>
                        <div>
                            <Input
                                placeholder={"Enter text..."}
                                size="large"
                                onChange={onTextChange}
                                value={item?.item}
                                addonAfter={<Button type="primary" onClick={onAddItemClick} >Add</Button>}
                            />
                        </div>
                    </Form>
                }
            </div>
        </CreateLayout>
    );
}

export default EditListDocumentScreen;