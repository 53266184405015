import React from 'react';
import styles from './workspaces-item.module.scss';
import {Button} from "antd";
import {UserXWorkspace} from "../../../../models/UserXWorkspace";
import {ArrowRightOutlined} from "@ant-design/icons";

type WorkspacesItemProps = {
    user_x_workspace: UserXWorkspace;
    onWorkspaceClick: (workspaceId: string) => void;
    onAcceptClick: (e: any) => void;
    onEnterClick: (e: any, workspaceUniqueName: string) => void;
};

function WorkspacesItem(props: WorkspacesItemProps) {
    const { user_x_workspace, onWorkspaceClick, onAcceptClick, onEnterClick } = props;
    const { _id, uniqueName, displayName, image } = user_x_workspace.workspace;

    return (
        <div className={styles.WorkspacesItem} onClick={() => onWorkspaceClick(_id)}>
            <img
                className={styles.image}
                src={image}
            />
            <div className={styles.names}>
                <div className={styles.displayName}>
                    {displayName}
                </div>
                <div className={styles.uniqueName}>
                    {uniqueName}
                </div>
            </div>
            <div className={styles.actions}>
                {
                    user_x_workspace.role !== 'INVITE' ?
                        <Button type="primary" size="large" ghost onClick={(e) => onEnterClick(e, uniqueName)} >
                            Enter
                            <ArrowRightOutlined />
                        </Button>:
                        <Button type="primary" size="large" onClick={onAcceptClick} >
                            ACCEPT
                        </Button>
                }
            </div>
        </div>
    );
}

export default WorkspacesItem;