import {useMutation, useQuery, UseQueryOptions} from "react-query";
import axios, {AxiosError} from "axios";
import {Metric} from "../../../models/Metric";
import {UseMutationOptions} from "react-query/types/react/types";

export const useMetric = (metricId: string, options?: UseQueryOptions<Metric, AxiosError, Metric>) => {
    return useQuery<Metric, AxiosError, Metric>(['metric', metricId], async () => {
            const res = await axios.get<Metric>(`/api/metrics/${metricId}`);
            return res.data;
        },
        {
            ...options,
            // staleTime: 5000,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};

export const useSyncMetric = (metricId: string, onSuccess: (data: Metric) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<Metric, AxiosError, Metric>) =>
    useMutation<Metric, AxiosError, any>(['sync-metric', 'metric', metricId], async () => {
        const res = await axios.get<Metric>(`/api/metrics/${metricId}/sync`);
        return res.data;
    }, { ...options, onSuccess, onError, });
