import React from 'react';
import {Drawer, Switch} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {ActionTypes} from "../../../actions/types";
import styles from './preferences-drawer.module.scss';
import {BulbOutlined, CloseOutlined} from "@ant-design/icons";
import {useThemeSwitcher} from "react-css-theme-switcher";

function PreferencesDrawer() {

    const dispatch = useDispatch();
    const isPreferencesDrawerOpen = useSelector<StoreState, boolean>((state) => state.isPreferencesDrawerOpen);
    const { switcher, themes } = useThemeSwitcher();

    const onClose = () => {
        dispatch({ type: ActionTypes.SET_IS_PREFERENCES_DRAWER_OPEN, value: false });
    };

    const onChangeTheme = (event: any) => {
        if (localStorage.getItem('navyflow-theme') === "dark") {
            document.documentElement.style.setProperty("--main-background-color", "var(--light-background-color)");
            document.documentElement.style.setProperty("--main-secondary-background-color", "var(--light-secondary-background-color)");
            document.documentElement.style.setProperty("--main-border-color", "var(--light-border-color)");
            document.documentElement.style.setProperty("--main-card-color", "var(--light-card-color)");
            document.documentElement.style.setProperty("--primary-text-color", "var(--light-primary-text-color)");
            document.documentElement.style.setProperty("--secondary-text-color", "var(--light-secondary-text-color)");
            document.documentElement.style.setProperty("--main-skeleton-color", "var(--light-skeleton-color)");
            document.documentElement.style.setProperty("--main-filter", "var(--light-filter)");
            document.documentElement.style.setProperty("--main-gradient", "var(--light-gradient)");
            document.documentElement.style.setProperty("--main-home-divider-color", "var(--light-home-divider-color)");
            document.documentElement.style.setProperty("--main-hover-color", "var(--light-hover-color)");
            localStorage.setItem('navyflow-theme', "light");
            switcher({ theme: themes.light });
        } else {
            document.documentElement.style.setProperty("--main-background-color", "var(--dark-background-color)");
            document.documentElement.style.setProperty("--main-secondary-background-color", "var(--dark-secondary-background-color)");
            document.documentElement.style.setProperty("--main-border-color", "var(--dark-border-color)");
            document.documentElement.style.setProperty("--main-card-color", "var(--dark-card-color)");
            document.documentElement.style.setProperty("--primary-text-color", "var(--dark-primary-text-color)");
            document.documentElement.style.setProperty("--secondary-text-color", "var(--dark-secondary-text-color)");
            document.documentElement.style.setProperty("--main-skeleton-color", "var(--dark-skeleton-color)");
            document.documentElement.style.setProperty("--main-filter", "var(--dark-filter)");
            document.documentElement.style.setProperty("--main-gradient", "var(--dark-gradient)");
            document.documentElement.style.setProperty("--main-home-divider-color", "var(--dark-home-divider-color)");
            document.documentElement.style.setProperty("--main-hover-color", "var(--dark-hover-color)");
            localStorage.setItem('navyflow-theme', "dark");
            switcher({ theme: themes.dark });
        }
    }

    return (
        <div >
            <Drawer
                placement="bottom"
                closable={false}
                onClose={onClose}
                visible={isPreferencesDrawerOpen}
                bodyStyle={{ padding: '0px' }}
            >
                <div className={styles.PreferencesDrawer}>
                    <div className={styles.title}>
                        Preferences
                    </div>
                    <div className={styles.themeContainer}>
                        <div className={styles.themeTitle}>
                            Theme
                        </div>
                        <Switch
                            className={styles.themeSwitch}
                            onChange={onChangeTheme}
                            checked={localStorage.getItem('navyflow-theme') === "dark"}
                            checkedChildren={<BulbOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default PreferencesDrawer;