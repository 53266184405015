import React from 'react';
import styles from './skeleton-gif-preview.module.scss';
import {Skeleton} from "antd";

function SkeletonGifPreview() {
    return (
        <div className={styles.SkeletonGifPreview}>
            <Skeleton.Input active className={styles.previewInput} />
        </div>
    );
}

export default SkeletonGifPreview;