import { Affix } from 'antd';
import React, {useEffect} from 'react';
import { Navigate, Route, Routes} from 'react-router';
import {useLocation} from 'react-router-dom';
import styles from './App.module.scss';
import LandingScreen from "./landing-screen/landing-screen";
import Menu from "../components/menu/Menu";
import NavBar from "../components/nav-bar/nav-bar";
import AuthModal from "../components/auth-modal/auth-modal";
import NotificationsScreen from "./notifications-screen/notifications-screen";
import cookies from "js-cookie";
import {useCurrentUser} from "./app-hooks";
import ProfileEditScreen from "./profile-edit-screen/profile-edit-screen";
import ChatsFriendsScreen from "./chats-friends-screen/chats-friends-screen";
import ChatScreen from "./chat-screen/chat-screen";
import BottomNavBar from "../components/bottom-nav-bar/bottom-nav-bar";
import {useThemeSwitcher} from "react-css-theme-switcher";
import {useCustomNavigate} from "../hooks/navigation";
import ChatsScreen from "./chats-screen/chats-screen";
import CreateWorkspacesScreen from "./workspaces-screens/create-workspaces-screen/create-workspaces-screen";
import EditWorkspacesScreen from "./workspaces-screens/edit-workspaces-screen/edit-workspaces-screen";
import ListWorkspacesScreen from './workspaces-screens/list-workspaces-screen/list-workspaces-screen';
import ListMetricsScreen from "./metrics-screens/list-metrics-screen/list-metrics-screen";
import ViewWorkspaceScreen from "./workspaces-screens/view-workspace-screen/view-workspace-screen";
import CreateMetricScreen from "./metrics-screens/create-metric-screen/create-metric-screen";
import ViewMetricScreen from "./metrics-screens/view-metric-screen/view-metric-screen";
import EditMetricScreen from "./metrics-screens/edit-metric-screen/edit-metric-screen";
import CreateMetricItemScreen from "./metrics-items-screens/create-metric-item-screen/create-metric-item-screen";
import EditMetricItemScreen from "./metrics-items-screens/edit-metric-item-screen/edit-metric-item-screen";
import ViewMetricItemScreen from "./metrics-items-screens/view-metric-item-screen/view-metric-item-screen";
import ListCalendarScreen from "./calendar-screens/list-calendars-screen/list-calendar-screen";
import CreateCalendarEventScreen
  from "./calendar-event-screens/create-calendar-event-screen/create-calendar-event-screen";
import ViewCalendarEventScreen from "./calendar-event-screens/view-calendar-event-screen/view-calendar-event-screen";
import EditCalendarEventScreen from "./calendar-event-screens/edit-calendar-event-screen/edit-calendar-event-screen";
import CreateCalendarScreen from "./calendar-screens/create-calendar-screen/create-calendar-screen";
import HomeCalendarScreen from "./calendar-screens/home-calendar-screen/home-calendar-screen";
import ViewCalendarScreen from "./calendar-screens/view-calendar-screen/view-calendar-screen";
import EditCalendarScreen from "./calendar-screens/edit-calendar-screen/edit-calendar-screen";
import CreateCalendarTagScreen from "./calendar-tag-screens/create-calendar-tag-screen/create-calendar-tag-screen";
import ViewCalendarTagScreen from "./calendar-tag-screens/view-calendar-tag-screen/view-calendar-tag-screen";
import EditCalendarTagScreen from "./calendar-tag-screens/edit-calendar-tag-screen/edit-calendar-tag-screen";
import ListDocumentsScreen from "./document-screens/list-documents-screen/list-documents-screen";
import EditDocumentScreen from "./document-screens/edit-document-screens/edit-document-screen";
import ListProductsScreen from "./products-screens/list-products-screen/list-products-screen";
import {useQueryClient} from "react-query";
import CreateFreeFormDocumentScreen
  from "./document-screens/create-document-screens/create-free-form-document-screen/create-free-form-document-screen";
import CreateFlowDocumentScreen
  from "./document-screens/create-document-screens/create-flow-document-screen/create-flow-document-screen";
import CreateListDocumentScreen
  from "./document-screens/create-document-screens/create-list-document-screen/create-list-document-screen";
import ViewDocumentsScreens from "./document-screens/view-document-screens/view-documents-screens";
import CreateFlowNodeScreen from "./document-screens/create-document-screens/create-flow-node-screen/create-flow-node-screen";
import EditFlowNodeScreen from "./document-screens/edit-document-screens/edit-flow-node-screen/edit-flow-node-screen";

function App() {

  const location = useLocation();
  const customNavigation = useCustomNavigate();
  const queryClient = useQueryClient();
  const { data: currentUser, isLoading } = useCurrentUser();
  const { switcher, themes } = useThemeSwitcher();

  useEffect(() => {
    if (window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches) {
      if (!localStorage.getItem('navyflow-theme')) {
        document.documentElement.style.setProperty("--main-background-color", "var(--dark-background-color)");
        document.documentElement.style.setProperty("--main-secondary-background-color", "var(--dark-secondary-background-color)");
        document.documentElement.style.setProperty("--main-border-color", "var(--dark-border-color)");
        document.documentElement.style.setProperty("--main-card-color", "var(--dark-card-color)");
        document.documentElement.style.setProperty("--primary-text-color", "var(--dark-primary-text-color)");
        document.documentElement.style.setProperty("--secondary-text-color", "var(--dark-secondary-text-color)");
        document.documentElement.style.setProperty("--main-skeleton-color", "var(--dark-skeleton-color)");
        document.documentElement.style.setProperty("--main-filter", "var(--dark-filter)");
        document.documentElement.style.setProperty("--main-gradient", "var(--dark-gradient)");
        document.documentElement.style.setProperty("--main-home-divider-color", "var(--dark-home-divider-color)");
        localStorage.setItem('navyflow-theme', "dark");
        switcher({ theme: themes.dark });
      }
    }

  }, [])

  useEffect(() => {
    if (!cookies.get('navyflow-token')) {
      customNavigation.replace('/');
    }
  }, [])

  const showMoreContent = () => {
    return ["home"].includes(`${location.pathname.split('/')[1]}`);
  }

  const dontShowMenu = () => {
    const urlVal = location.pathname.split('/')[1];
    if (urlVal.includes('user-exists')) {
      return false;
    }

    if (urlVal.includes('workspaces')) {
      return false;
    }

    return ![""].includes(`${urlVal}`);
  }

  const tabs = ['home', 'explore', 'trending', 'notifications', 'chats',
      'editprofile', 'chats/friends', 'user-exists', 'workspaces'];

    const callRedirect = () => {
      const urlArr = location.pathname.split('/token/');
        if (urlArr.length > 1) {
          const token = urlArr[1];
          cookies.set('navyflow-token', token, { expires: 7 });
          queryClient.invalidateQueries('current-user').then();
          return <Navigate to="/workspaces" />;
        }
    }

  useEffect(() => {
    if (cookies.get('navyflow-token') && !currentUser && !isLoading) {
      window.location.reload();
    }
  }, [currentUser])

  return (
      <div className={styles.App}>
        <AuthModal />
        <div className={styles.appBody}>
          <Affix offsetTop={0}>
            <Routes>
              <Route path="token" >
                <Route path=":token" element={callRedirect()}  />
              </Route>
              <Route path=":workspace" >
                <Route index element={<NavBar />} />
                <Route path=":menu" >
                  <Route index element={<NavBar />} />
                  <Route path=":item" >
                    <Route index element={<NavBar />} />
                    <Route path="*" element={<NavBar />}  />
                  </Route>
                  <Route path="*" element={<NavBar />}  />
                </Route>
              </Route>
              <Route path="*" element={<NavBar />}  />
            </Routes>
          </Affix>
          <div className={styles.mainContainer}>
            {
              dontShowMenu() && currentUser &&
              <div className={styles.menuMainContainer}>
                <Affix className={styles.leftContainer} offsetTop={80}>
                  <Routes>
                    {/*<Route path="token" >*/}
                    {/*  <Route path=":token" element={callRedirect2()}  />*/}
                    {/*</Route>*/}
                    <Route path=":workspace" >
                      <Route index element={<Menu />} />
                      <Route path=":menu" element={<Menu />}  />
                      <Route path="*" element={<Menu />}  />
                    </Route>
                  </Routes>
                </Affix>
              </div>
            }
            <div className={styles.contentMainContainer}>
              <div className={styles.centerMainContainer}>
                  {
                      !currentUser &&
                      <Routes>
                          <Route path="/" element={<LandingScreen/>}/>
                          <Route path="terms-and-services" element={<div />} />
                          <Route path="privacy-policy" element={<div />} />
                          {/*<Route path="token" >*/}
                          {/*    <Route path=":token" element={callRedirect3()}  />*/}
                          {/*</Route>*/}
                          <Route path="*" element={<div />} />
                      </Routes>
                  }
                  {
                      currentUser &&
                      <Routes>
                          <Route path="/" element={cookies.get('default-workspace') ? <Navigate to={`${cookies.get('default-workspace')}`} /> : <Navigate to={"workspaces"} />} />
                          <Route path="workspaces">
                              <Route index element={<ListWorkspacesScreen />} />
                              <Route path="create" element={<CreateWorkspacesScreen />} />
                              <Route path=":workspaceId">
                                <Route index element={<ViewWorkspaceScreen />} />
                                <Route path="edit" element={<EditWorkspacesScreen />} />
                              </Route>
                          </Route>
                          <Route path="chats">
                              <Route index element={<ChatsScreen />} />
                              <Route path=":chatId" element={<ChatScreen />} />
                              <Route path="friends" element={<ChatsFriendsScreen />} />
                          </Route>
                          <Route path="notifications">
                              <Route index element={<NotificationsScreen />} />
                          </Route>
                          <Route path="user-exists">
                              <Route index element={<LandingScreen userExists />} />
                          </Route>
                          <Route path="edit-profile" element={<ProfileEditScreen />} />
                          <Route path=":workspace">
                            <Route index element={<Navigate to={"metrics"} />} />
                            <Route path="metrics">
                              <Route index element={<ListMetricsScreen />} />
                              <Route path="create" element={<CreateMetricScreen />} />
                              <Route path=":metricId">
                                <Route index element={<ViewMetricScreen />} />
                                <Route path="edit" element={<EditMetricScreen />} />
                                <Route path="metric-items">
                                  <Route path="create" element={<CreateMetricItemScreen />} />
                                  <Route path=":metricItemId">
                                    <Route index element={<ViewMetricItemScreen />} />
                                    <Route path="edit" element={<EditMetricItemScreen />} />
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                            <Route path="calendars">
                              <Route index element={<HomeCalendarScreen />} />
                              <Route path="all" element={<ListCalendarScreen />} />
                              <Route path="create" element={<CreateCalendarScreen />} />
                              <Route path="events">
                                <Route index element={<HomeCalendarScreen />} />
                                <Route path="create" element={<CreateCalendarEventScreen />} />
                                <Route path=":eventId">
                                  <Route index element={<ViewCalendarEventScreen />} />
                                  <Route path="edit" element={<EditCalendarEventScreen />} />
                                  <Route path="calendar-events">
                                    <Route index element={<ListCalendarScreen />} />
                                    <Route path="create" element={<CreateMetricItemScreen />} />
                                    <Route path=":calendarEventId">
                                      <Route index element={<ViewMetricItemScreen />} />
                                      <Route path="edit" element={<EditMetricItemScreen />} />
                                    </Route>
                                  </Route>
                                </Route>
                              </Route>
                              <Route path=":calendarId">
                                <Route index element={<ViewCalendarScreen />} />
                                <Route path="edit" element={<EditCalendarScreen />} />
                                <Route path="tags" >
                                  <Route path="create" element={<CreateCalendarTagScreen />} />
                                  <Route path=":calendarTagId">
                                    <Route index element={<ViewCalendarTagScreen />} />
                                    <Route path="edit" element={<EditCalendarTagScreen />} />
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                            <Route path="documents">
                              <Route index element={<ListDocumentsScreen />} />
                              <Route path="create" >
                                <Route path="free-form" element={<CreateFreeFormDocumentScreen />} />
                                <Route path="flow" element={<CreateFlowDocumentScreen />} />
                                <Route path="list" element={<CreateListDocumentScreen />} />
                              </Route>
                              <Route path=":documentId">
                                <Route index element={<ViewDocumentsScreens />} />
                                <Route path="edit" element={<EditDocumentScreen />} />
                                <Route path="create-flow-node" element={<CreateFlowNodeScreen />} />
                                <Route path="edit-flow-node">
                                  <Route path=":flowNodeId">
                                    <Route index element={<EditFlowNodeScreen />} />
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                            <Route path="products">
                              <Route index element={<ListProductsScreen />} />
                              {/*<Route path="create" element={<CreateProductScreen />} />*/}
                              {/*<Route path=":productId">*/}
                              {/*  <Route index element={<ViewDocumentScreen />} />*/}
                              {/*  <Route path="edit" element={<EditDocumentScreen />} />*/}
                              {/*</Route>*/}
                            </Route>
                          </Route>
                        <Route path="*" element={<div />} />
                      </Routes>
                  }
              </div>
              {
                showMoreContent() &&
                <div className={styles.rightMainContainer}>
                  <Affix className={styles.leftContainer} offsetTop={80}>
                    {/*<TrendingContent />*/}
                  </Affix>
                </div>
              }
            </div>
          </div>
          <Affix offsetBottom={0}>
            <Routes>
              <Route path=":workspace" >
                <Route index element={<BottomNavBar />} />
                <Route path=":menu" >
                  <Route index element={<BottomNavBar />} />
                  <Route path=":item" >
                    <Route index element={<BottomNavBar />} />
                    <Route path="*" element={<BottomNavBar />}  />
                  </Route>
                  <Route path="*" element={<BottomNavBar />}  />
                </Route>
                <Route path="*" element={<BottomNavBar />}  />
              </Route>
            </Routes>
          </Affix>
        </div>
      </div>
  );
}

export default App;
