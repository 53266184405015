import {useMutation, useQuery, UseQueryOptions} from "react-query";
import {User} from "../../models/User";
import axios, {AxiosError} from "axios";
import {ChatRoom} from "../../models/ChatRoom";

export const useChat = (userId: string, options?: UseQueryOptions<ChatRoom, AxiosError, ChatRoom>) =>
    useQuery<ChatRoom, AxiosError, ChatRoom>(['chat-screen',userId], async () => {
            const res = await axios.get<ChatRoom>(`/api/chats/${userId}`);
            return res.data;
        },
        { ...options,
            onSuccess: data => {
                console.log("useChat - data: ",data);
            }, onError: error => {
                console.log("useChat - err: ",error);
            }, });

// export const useCreateMessage = (userId: string | undefined, onSuccess: (data: User) => void, onError: (error: AxiosError) => void, options?: UseQueryOptions<User, AxiosError, User>) =>
//     useMutation<User, AxiosError, FormData>(['profile', userId], async (userData) => {
//         const res = await axios.post<User>(`/api/chats/message`, userData);
//         return res.data;
//     }, { ...options, onSuccess, onError, });