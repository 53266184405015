import axios, {AxiosError} from "axios";
import {useMutation, useQuery, UseQueryOptions} from "react-query";
import {CalendarEvent} from "../../../models/CalendarEvent";
import {CalendarTagsGroupByCalendar} from "../../../models/CalendarTagsGroupByCalendar";
import {UseMutationOptions} from "react-query/types/react/types";

export const useCreateCalendarEvent = (workspaceId: string | undefined,
                                       onSuccess: (data: CalendarEvent) => void,
                                       onError: (error: AxiosError) => void,
                                       options?: UseMutationOptions<CalendarEvent, AxiosError, CalendarEvent>) =>
    useMutation<CalendarEvent, AxiosError, any>(['calendar-events'], async (calendarEventData) => {
        const res = await axios.post<CalendarEvent>(`/api/calendar-events/workspaces/${workspaceId}`, {...calendarEventData});
        return res.data;
    }, { ...options, onSuccess, onError });

export const useCalendarTagsByCalendar = (calendarIds: string[], options?: UseQueryOptions<CalendarTagsGroupByCalendar[], AxiosError, CalendarTagsGroupByCalendar[]>) => {
    return useQuery<CalendarTagsGroupByCalendar[], AxiosError, CalendarTagsGroupByCalendar[]>(['calendar-tags', calendarIds], async () => {
            const res = await axios.get<CalendarTagsGroupByCalendar[]>(`/api/calendar-tags/calendars?calendarIds=${calendarIds.join(',')}`);
            return res.data;
        },
        {
            ...options,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};