import React, {useState} from 'react';
import styles from './chat-screen.module.scss';
import {useInfiniteQuery, useQueryClient} from "react-query";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useCurrentUser} from "../app-hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import Empty from "../../components/empty/empty";
import {Button, Drawer, Input, Skeleton, Spin, Tabs} from "antd";
import MessageItem from "../chats-screen/message-item2/message-item";
import {ArrowLeftOutlined} from '@ant-design/icons';
import SkeletonMessage from "../../components/skeleton-message/skeleton-message";
import {useChat} from "./chat-hooks";
import { ChatMessage } from '../../models/ChatMessage';
import {useDispatch, useSelector} from "react-redux";
import {ActionTypes} from "../../actions/types";
import {StoreState} from "../../models/StoreState";
import {updateTimeStamps} from '../../services/timeService';
import TenorGifs from "./TenorGifs/TenorGifs";
import GiphyGifs from "./GiphyGifs/GiphyGifs";
import {useCustomNavigate} from "../../hooks/navigation";
import {TimeStamps} from "../../models/TimeStamps";

const { TabPane } = Tabs;
const { Search } = Input;
function ChatScreen() {

    const customNavigation = useCustomNavigate();
    const { chatId } = useParams();
    const [limit] = useState(12);
    const timeStampKey = `messages-${chatId}`;
    const timeStamps = useSelector<StoreState, TimeStamps>(state => state.timeStamps);
    const [execSearch, setExecSearch] = useState(false);
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const { data: currentUser } = useCurrentUser();
    const { data: currentChat, isLoading: isCurrentChatLoading } = useChat(chatId ?? '');
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const getTimeStamp = () => {
        // if (timeStamps[`${timeStampKey}`] && !isFetching) {
        //     return timeStamps[`${timeStampKey}`]
        // }
        const newTimeStamp = new Date();
        newTimeStamp.setSeconds(newTimeStamp.getSeconds() + 100);
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: {...timeStamps, [`${timeStampKey}`]: newTimeStamp } });
        return newTimeStamp;
    }

    const {
        data,
        isFetching,
        fetchNextPage,
        isLoading,
    } = useInfiniteQuery(
        timeStampKey,
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ messages: ChatMessage[], nextId: number }>(`/api/chats/messages/${currentChat?._id}?limit=${limit}&page=${pageParam}&timestamp=${getTimeStamp()}`);
            if (data?.messages?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!currentChat?._id
        },
    );

    const onBackClick = () => {
        customNavigation.replace('/chats');
    }

    const otherUser = currentChat?.user1?._id === currentUser?._id ? currentChat?.user2 : currentChat?.user1;

    const onClose = () => {
        setDrawerIsOpen(false);
        setExecSearch(false);
    }

    const [searchGifQuery, setSearchGifQuery] = useState('');

    const onSendClick = (gifUrl: string) => {
        axios.post('/api/chats/message', { gifUrl, chatRoomId: currentChat?._id })
            .then(() => {
                const newTimeStamp = new Date();
                newTimeStamp.setSeconds(newTimeStamp.getSeconds() + 10000);
                dispatch(updateTimeStamps({...timeStamps, [`${timeStampKey}`]: newTimeStamp }, () => {
                    queryClient.invalidateQueries(`messages-${chatId}`).then();
                    setDrawerIsOpen(false);
                    setSearchGifQuery('');
                }))
                //dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: {...timeStamps, [`${timeStampKey}`]: newTimeStamp }})
            })
    };

    const onSearchClick = () => {
        setDrawerIsOpen(true);
        setExecSearch(true);
    }

    const onInputChange = (input: any) => {
        setSearchGifQuery(input.target.value);
    }

    const onSearch = (text: string) => {
        setExecSearch(true);
        setSearchGifQuery(text);
    }

    return (
        <div className={styles.ChatScreen}>
            <Drawer
                placement="right"
                closable={false}
                onClose={onClose}
                visible={drawerIsOpen}
                className={styles.gifDrawer}
                width={300}
                bodyStyle={{ margin: 0, padding: 0 }}
            >
                <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
                    <Search
                        className={styles.searchInput}
                        placeholder={'Search for gifs'}
                        allowClear
                        size="large"
                        defaultValue={searchGifQuery}
                        onSearch={onSearch}
                    />
                </div>
                <Tabs defaultActiveKey="1" tabBarGutter={0} >
                    <TabPane tab={<div style={{ width: `${140}px`, textAlign: 'center' }}>Tenor</div>} key="1">
                        <TenorGifs id={chatId!} searchQuery={searchGifQuery} execSearch={execSearch} onSendClick={onSendClick} />
                    </TabPane>
                    <TabPane tab={<div style={{ width: `${140}px`, textAlign: 'center' }}>Giphy</div>} key="2">
                        <GiphyGifs id={chatId!} searchQuery={searchGifQuery} execSearch={execSearch} onSendClick={onSendClick} />
                    </TabPane>
                </Tabs>
            </Drawer>
            <div className={styles.headerContainer}>
                <Button className={styles.backButton} type="link" icon={<ArrowLeftOutlined/>} onClick={onBackClick} />
                {
                    isCurrentChatLoading ?
                        <div className={styles.centerContainer}>
                            <Skeleton.Avatar active className={styles.image} />
                            <Skeleton.Input active className={styles.username} style={{ width: 140, borderRadius: 6 }} />
                        </div>:
                        <div className={styles.centerContainer}>
                            <img
                                className={styles.image}
                                src={otherUser?.image}
                            />
                            <span className={styles.username}>{otherUser?.name}</span>
                        </div>
                }
                <div>
                </div>
            </div>
            <div
                id="scrollableMessagesDiv"
                className={styles.messagesContainer}
            >
                {
                    isFetching === true && !data &&
                    [1, 1, 1, 1].map((item, index) => <SkeletonMessage key={`${index}`} />)
                }
                {
                    isFetching === false && data?.pages.map((page) => page.messages).flat().length === 0 &&
                    <Empty
                        title="No messages"
                        subTitle="Messages will appear here"
                        icon="plus"
                        url="/createclubs"
                        screen="posts"
                    />
                }
                {
                    data?.pages.map((page) => page.messages).flat().length !== 0 &&
                    <InfiniteScroll
                        style={{ display: 'flex', flexDirection: 'column-reverse' }}
                        dataLength={data?.pages.map((page) => page.messages).flat().length || 0}
                        next={() => fetchNextPage()}
                        hasMore={(data?.pages.map((page) => page.messages).flat().length || 0) > 0 && !isAtTheEnd}
                        loader={(
                            <div className={styles.spinningContainer}>
                                <Spin />
                            </div>
                        )}
                        endMessage={(<p style={{ textAlign: 'center' }} />)}
                        inverse
                        scrollableTarget="scrollableMessagesDiv"
                        scrollThreshold={0.3}
                    >
                        {
                            data?.pages.map((page) => page.messages).flat().map((chatMessage: ChatMessage, index) => (
                                <MessageItem
                                    chatMessage={chatMessage}
                                    key={`post-card-${index}`}
                                />
                            ))
                        }
                    </InfiniteScroll>
                }
            </div>
            <div className={styles.inputContainer}>
                <Search
                    className={styles.searchInput}
                    placeholder={"Search for gifs"}
                    size="large"
                    loading={isLoading}
                    onChange={onInputChange}
                    value={searchGifQuery}
                    onSearch={onSearchClick}
                />
            </div>
        </div>
    );
}

export default ChatScreen;