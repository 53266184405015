import {useLocation, useNavigate} from "react-router-dom";
import queryString from "query-string";

export const useCustomNavigate = () => {
    const location: any = useLocation();
    const navigate = useNavigate();

    const push = (path: string) => {
        navigate(path, { state: {...location.state as {}, inApp: true } })
    }

    const replace = (path: string) => {
        navigate(path, { replace: true, state: {...location.state as {}, inApp: true } })
    }

    const pushWithState = (path: string, state: { [key: string]: any }) => {
        navigate(path, { state: {...location.state as {}, ...state, inApp: true} })
    }

    const pushWithUrlParams = (path: string, params: { [key: string]: any }) => {
        const updatedParams = queryString.stringify(params, { sort: false, skipNull: true })
        navigate(`${path}?${updatedParams}`, { state: {...location.state as {}, inApp: true} })
    }

    const replaceWithState = (path: string, state: { [key: string]: any }) => {
        navigate(path, { replace: true, state: {...location.state as {}, ...state, inApp: true} })
    }

    const goBack = (defaultPath?: string) => {
        if (location.state?.inApp) {
            navigate(-1);
        } else if (defaultPath) {
            navigate(defaultPath, { replace: true });
        } else {
            navigate('/', { replace: true });
        }
    }

    return {
        push,
        replace,
        pushWithState,
        replaceWithState,
        goBack,
        pushWithUrlParams
    };
}