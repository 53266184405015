import React from 'react';
import styles from './list-products-screen.module.scss';
import ReadingComp from "./reading-comp/reading-comp";

function ListProductsScreen() {
    return (
        <div className={styles.ListProductsScreen}>
            <ReadingComp />
        </div>
    );
}

export default ListProductsScreen;