import { ActionTypes } from '../actions/types';

interface viewPostImageModal {
    type: typeof ActionTypes.TOGGLE_POST_IMAGE
}

interface toggleAuthModal {
    type: typeof ActionTypes.TOGGLE_AUTH_MODAL;
    authType: string;
}

interface setLastLocation {
    type: typeof ActionTypes.SET_LAST_LOCATION;
    location: string;
}

interface setMenuItem {
    type: typeof ActionTypes.SET_MENU_ITEM;
    location: string;
}

interface toggleSettingsModal {
    type: typeof ActionTypes.TOGGLE_SETTINGS_MODAL;
}

interface toggleColorPaletteModal {
    type: typeof ActionTypes.TOGGLE_COLOR_PALETTE_MODAL;
}

interface toggleFontModalMain {
    type: typeof ActionTypes.TOGGLE_FONT_MODAL_MAIN;
    data?: any;
}

interface toggleFontModalSource {
    type: typeof ActionTypes.TOGGLE_FONT_MODAL_SOURCE;
    data?: any;
}

interface setFontDataMain {
    type: typeof ActionTypes.SET_FONT_MODAL_DATA_MAIN;
    data?: any;
}

interface setFontDataSource {
    type: typeof ActionTypes.SET_FONT_MODAL_DATA_SOURCE;
    data?: any;
}

interface toggleTextModal {
    type: typeof ActionTypes.TOGGLE_TEXT_MODAL;
}

interface toggleWiltTextModal {
    type: typeof ActionTypes.TOGGLE_WILT_TEXT_MODAL;
}

interface isLoading {
    type: typeof ActionTypes.TOGGLE_LOADING;
    value: boolean;
}

interface setLoading {
    type: typeof ActionTypes.SET_LOADING;
    value: boolean;
}

interface toggleMobileMenu {
    type: typeof ActionTypes.TOGGLE_MOBILE_MENU;
}

interface toggleInterestModalVisibility {
    type: typeof ActionTypes.TOGGLE_INTEREST_MODAL_VISIBILITY;
}

interface setInterestModalVisibility {
    type: typeof ActionTypes.SET_INTEREST_MODAL_VISIBILITY;
    value: boolean;
}

interface setCurrentId {
    type: typeof ActionTypes.SET_CURRENT_ID;
    value: string;
}

interface scrollPosition {
    type: typeof ActionTypes.SET_SCROLL_POSITION;
    value: number;
}

interface setScrollRef {
    type: typeof ActionTypes.SET_SCROLL_REF;
    value: any;
}

interface isNewMessageModalOpen {
    type: typeof ActionTypes.SET_NEM_MESSAGE_MODAL;
    value: boolean;
}

interface setIsWorkspacesDrawerOpen {
    type: typeof ActionTypes.SET_IS_WORKSPACES_DRAWER_OPEN;
    value: boolean;
}

interface setIsPreferencesDrawerOpen {
    type: typeof ActionTypes.SET_IS_PREFERENCES_DRAWER_OPEN;
    value: boolean;
}

interface setIsCalendarDrawerVisible {
    type: typeof ActionTypes.SET_IS_CALENDAR_DRAWER_VISIBLE;
    value: boolean;
}

type displayActions = setCurrentId | viewPostImageModal | toggleAuthModal
    | setLastLocation | setMenuItem | toggleSettingsModal | toggleColorPaletteModal
    | toggleTextModal | isLoading | toggleWiltTextModal | toggleMobileMenu | toggleFontModalMain
    | setFontDataMain | toggleFontModalSource | setFontDataSource | toggleInterestModalVisibility
    | setInterestModalVisibility | scrollPosition | setScrollRef | isNewMessageModalOpen | setLoading
    | setIsWorkspacesDrawerOpen | setIsPreferencesDrawerOpen | setIsCalendarDrawerVisible;

export const authModal = (state = { visible: false, authType: '' }, action: displayActions) => {
    if (action.type === ActionTypes.TOGGLE_AUTH_MODAL) {
        return { visible: !state.visible, authType: action.authType };
    }
    return state;
};

export const isMobileMenuOpen = (state = false, action: displayActions) => {
    if (action.type === ActionTypes.TOGGLE_MOBILE_MENU) {
        return !state;
    }
    return state;
};

export const isWorkspacesDrawerOpen = (state = false, action: displayActions) => {
    if (action.type === ActionTypes.SET_IS_WORKSPACES_DRAWER_OPEN) {
        return action.value;
    }
    return state;
};

export const isPreferencesDrawerOpen = (state = false, action: displayActions) => {
    if (action.type === ActionTypes.SET_IS_PREFERENCES_DRAWER_OPEN) {
        return action.value;
    }
    return state;
};

export const isLoading = (state = false, action: displayActions) => {
    if (action.type === ActionTypes.TOGGLE_LOADING) {
        return !state;
    }
    if (action.type === ActionTypes.SET_LOADING) {
        return action.value;
    }
    return state;
};

export const currentId = (state = '', action: displayActions) => {
    if (action.type === ActionTypes.SET_CURRENT_ID) {
        return action.value;
    }
    return state;
};

export const isNewMessageModalOpen = (state = false, action: displayActions) => {
    if (action.type === ActionTypes.SET_NEM_MESSAGE_MODAL) {
        return action.value;
    }
    return state;
};

export const isSettingsVisible = (state = false, action: displayActions) => {
    if (action.type === ActionTypes.TOGGLE_SETTINGS_MODAL) {
        return !state;
    }
    return state;
};

export const isCalendarDrawerVisible = (state = false, action: displayActions) => {
    if (action.type === ActionTypes.SET_IS_CALENDAR_DRAWER_VISIBLE) {
        return action.value;
    }
    return state;
};