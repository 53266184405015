import React from 'react';
import styles from './metric-item-card.module.scss';
import {MetricItem} from "../../../../models/MetricItem";
import {Metric} from "../../../../models/Metric";
import {getCurrency} from "../../../metrics-screens/list-metrics-screen/metrcis-item/metrics-item-helper";

type MetricItemProps = {
    metricItem: MetricItem;
    metric: Metric;
    onMetricItemClick: (metricItemId: string) => void;
}

function MetricItemCard(props: MetricItemProps) {
    const { metricItem, onMetricItemClick, metric } = props;
    const { _id } = metricItem;


    return (
        <div className={styles.MetricItemCard} onClick={() => onMetricItemClick(_id)}>
            <div className={styles.leftContent}>
                <div className={styles.numericValue}>
                    <span>{metric.unit === 'CURRENCY' && getCurrency(metric.currency)}</span>
                    <span>{metricItem.numericValue}</span>
                </div>
                <div className={styles.description}>
                    {metricItem.description}
                </div>
            </div>
            <div>
                {new Date(metricItem.timeStamp).toLocaleString(undefined, {
                    day: 'numeric', month: 'short', year: 'numeric'
                })}
            </div>
        </div>
    );
}

export default MetricItemCard;