import React, {useState} from 'react';
import styles from './create-metric-screen.module.scss';
import {useCustomNavigate} from "../../../hooks/navigation";
import {useCurrentUser, useCurrentWorkspace} from "../../app-hooks";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {Form, Input, InputNumber, Select} from "antd";
import {useParams} from "react-router-dom";
import {useCreateMetric} from "./create-metric-hooks";
import {arrayOfCurrencies} from "../../../utils/utils";
import {TimeStamps} from "../../../models/TimeStamps";
import {ActionTypes} from "../../../actions/types";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";

function CreateMetricScreen() {
    const { workspace } = useParams();
    const customNavigation = useCustomNavigate();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const { data: currentUser } = useCurrentUser();
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!currentUser?._id && !!workspace })
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [selectedUnit, setSelectedUnit] = useState<string>();
    const dispatch = useDispatch();

    const { mutate: createMetric, isLoading } = useCreateMetric(currentWorkspace?._id, (metric) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        dispatch({ type: ActionTypes.UPDATE_TIME_STAMPS, value: { METRICS: newDate } });
        onBackClick();
    }, (error) => {
        console.log("error: ",error.response);
        setErrorMessage("Something went wrong. Please try again.");
    }, { });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/metrics`);
    };

    const onFinish = (values: any) => {
        createMetric(values);
    };

    const onFinishFailed = () => {

    };

    const onUnitChange = (unitData: string) => {
        setSelectedUnit(unitData);
    }

    const formId = "create-workspace";

    return (
        <CreateLayout
            title={"Create Metric"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.CreateMetricScreen}>
                <Form
                    layout="vertical"
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            { required: true, message: "Please enter title" },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter title for metric"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input
                            size="large"
                            placeholder="Enter description for metric"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Unit"
                        name="unit"
                        required
                    >
                        <Select
                            size="large"
                            placeholder="Select unit"
                            onChange={onUnitChange}>
                            <Select.Option value="NUMERIC">Numeric</Select.Option>
                            <Select.Option value="CURRENCY">Currency</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Objective"
                        name="objective"
                        required
                    >
                        <Select
                            size="large"
                            placeholder="Select objective"
                        >
                            <Select.Option value="INCREASE">Increase</Select.Option>
                            <Select.Option value="DECREASE">Decrease</Select.Option>
                            <Select.Option value="CONSTANT">Keep constant</Select.Option>
                            <Select.Option value="INCREASE_CONSTANT">Increase then keep constant</Select.Option>
                            <Select.Option value="DECREASE_CONSTANT">Decrease then keep constant</Select.Option>
                        </Select>
                    </Form.Item>
                    {
                        selectedUnit?.toUpperCase() === "CURRENCY".toUpperCase() &&
                        <Form.Item
                            label="Currency"
                            name="currency"
                            required
                        >
                            <Select
                                size="large"
                                placeholder="Select currency"
                                showSearch
                                optionFilterProp={'title'}
                            >
                                {arrayOfCurrencies?.map((item) => (
                                    <Select.Option key={item.key} title={`${item.key} - ${item.value}`} value={item.key}>{`${item.key} - ${item.value}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }
                    <Form.Item
                        label="Type"
                        name="type"
                        required
                        initialValue={"LAST_METRIC_ITEM"}
                    >
                        <Select
                            size="large"
                            placeholder="Select preview"
                        >
                            <Select.Option value="METRICS_SUM">Metrics sum</Select.Option>
                            <Select.Option value="METRICS_ITEMS_SUM">Metrics Items sum</Select.Option>
                            <Select.Option value="LAST_METRIC_ITEM">Last Metric Item</Select.Option>
                        </Select>
                    </Form.Item>
                    <div className={styles.inputNumbers}>
                        <Form.Item
                            label="Target"
                            name="target"
                        >
                            <InputNumber
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Ranking"
                            name="ranking"
                            className={styles.ranking}
                        >
                            <InputNumber
                                min={1}
                                size="large"
                            />
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default CreateMetricScreen;