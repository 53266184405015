import React from 'react';
import {Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {ActionTypes} from "../../../actions/types";
import WorkspaceDrawerContent from "./workspace-drawer-content/workspace-drawer-content";
import PreferencesDrawer from "../preferences-drawer/preferences-drawer";

function WorkspacesDrawer() {

    const dispatch = useDispatch();
    const isWorkspacesDrawerOpen = useSelector<StoreState, boolean>((state) => state.isWorkspacesDrawerOpen);

    const onClose = () => {
        dispatch({ type: ActionTypes.SET_IS_WORKSPACES_DRAWER_OPEN, value: false });
    };

    return (
        <div>
            <PreferencesDrawer />
            <Drawer
                placement="left"
                closable={false}
                onClose={onClose}
                visible={isWorkspacesDrawerOpen}
                bodyStyle={{ padding: '0px' }}
            >
                <WorkspaceDrawerContent />
            </Drawer>
        </div>
    );
}

export default WorkspacesDrawer;