import React from 'react';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import App from './screens/App';
import thunk from 'redux-thunk';
import axios from 'axios';
import create from 'zustand'
import cookies from 'js-cookie';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, createStore } from 'redux';
import _rootReducers from "./reducers/_rootReducers";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import { QueryClientProvider, QueryClient } from 'react-query';
import './i18n';
import {CalendarEvent} from "./models/CalendarEvent";
import {DateTime} from "luxon";
import { Helmet, HelmetProvider } from 'react-helmet-async';

if (process.env.NODE_ENV === 'development') {

    axios.interceptors.request.use(req => {
        // console.log(`axiooos - ${req.method} ${req.url}`);
        // console.log(`axiooos:`, req);
        // Important: request interceptors **must** return the request.
        return req;
    });

    axios.defaults.baseURL = 'http://localhost:5000/';
    //app.use('/api/*', proxy.createProxyMiddleware( { target: 'http://localhost:5000', changeOrigin: true }));
}

if (cookies.get('navyflow-access')) {
    axios.defaults.headers.common = {
        'navyflow-access': cookies.get('navyflow-access') ?? '',
        Authorization: `Bearer ${cookies.get('navyflow-token')}`,
    };
} else {
    axios.defaults.headers.common = { Authorization: `Bearer ${cookies.get('navyflow-token')}` };
}

const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

if (localStorage.getItem('navyflow-theme')) {

    if (localStorage.getItem('navyflow-theme') === "dark") {
        document.documentElement.style.setProperty("--main-background-color", "var(--dark-background-color)");
        document.documentElement.style.setProperty("--main-secondary-background-color", "var(--dark-secondary-background-color)");
        document.documentElement.style.setProperty("--main-border-color", "var(--dark-border-color)");
        document.documentElement.style.setProperty("--main-card-color", "var(--dark-card-color)");
        document.documentElement.style.setProperty("--primary-text-color", "var(--dark-primary-text-color)");
        document.documentElement.style.setProperty("--secondary-text-color", "var(--dark-secondary-text-color)");
        document.documentElement.style.setProperty("--main-skeleton-color", "var(--dark-skeleton-color)");
        document.documentElement.style.setProperty("--main-filter", "var(--dark-filter)");
        document.documentElement.style.setProperty("--main-gradient", "var(--dark-gradient)");
        document.documentElement.style.setProperty("--main-home-divider-color", "var(--dark-home-divider-color)");
        document.documentElement.style.setProperty("--main-hover-color", "var(--dark-hover-color)");
    } else {
        document.documentElement.style.setProperty("--main-background-color", "var(--light-background-color)");
        document.documentElement.style.setProperty("--main-secondary-background-color", "var(--light-secondary-background-color)");
        document.documentElement.style.setProperty("--main-border-color", "var(--light-border-color)");
        document.documentElement.style.setProperty("--main-card-color", "var(--light-card-color)");
        document.documentElement.style.setProperty("--primary-text-color", "var(--light-primary-text-color)");
        document.documentElement.style.setProperty("--secondary-text-color", "var(--light-secondary-text-color)");
        document.documentElement.style.setProperty("--main-skeleton-color", "var(--light-skeleton-color)");
        document.documentElement.style.setProperty("--main-filter", "var(--light-filter)");
        document.documentElement.style.setProperty("--main-gradient", "var(--light-gradient)");
        document.documentElement.style.setProperty("--main-home-divider-color", "var(--light-home-divider-color)");
        document.documentElement.style.setProperty("--main-hover-color", "var(--light-hover-color)");
    }

}

const store = createStore(_rootReducers, applyMiddleware(thunk));

const queryClient = new QueryClient();

export const useStore = create((set: any) => ({
    calendarRecurringEvents: [],
    setCalendarRecurringEvents: (events: CalendarEvent[] | undefined) => set({ calendarRecurringEvents: events }),
    isNonRecurringAtTheEnd: false,
    setIsNonRecurringAtTheEnd: (value: boolean) => set({ isNonRecurringAtTheEnd: value }),
    tempNonRecurringLastDate: undefined,
    setTempNonRecurringLastDate: (value: string | undefined) => set({ tempNonRecurringLastDate: value }),
    untilDate: DateTime.now().plus({ month: 1 }),
    setUntilDate: (value: DateTime) => set({ untilDate: value }),
    search: undefined,
    setSearch: (value: string) => set({ search: value }),
    timeStampsDocumentList: DateTime.now(),
    setTimeStampsDocumentList: (value: DateTime) => set({ timeStampsDocumentList: value }),
    isCreateApiModalVisible: false,
    setIsCreateApiModalVisible: (value: boolean) => set({isCreateApiModalVisible: value})
}));

ReactDOM.hydrate(
    <HelmetProvider>
        <React.StrictMode>
            <ThemeSwitcherProvider themeMap={themes} defaultTheme={localStorage.getItem('navyflow-theme') ?? "light"}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Suspense fallback={<div>Loading...</div>}>
                            <QueryClientProvider client={queryClient}>
                                <App />
                            </QueryClientProvider>
                        </Suspense>
                    </BrowserRouter>
                </Provider>
            </ThemeSwitcherProvider>
        </React.StrictMode>
    </HelmetProvider>,
    document.getElementById('root')
)
// ReactDOM.render(
//     <HelmetProvider>
//         <React.StrictMode>
//             <ThemeSwitcherProvider themeMap={themes} defaultTheme={localStorage.getItem('navyflow-theme') ?? "light"}>
//                 <Provider store={store}>
//                     <BrowserRouter>
//                         <Suspense fallback={<div>Loading...</div>}>
//                             <QueryClientProvider client={queryClient}>
//                                 <App />
//                             </QueryClientProvider>
//                         </Suspense>
//                     </BrowserRouter>
//                 </Provider>
//             </ThemeSwitcherProvider>
//         </React.StrictMode>
//     </HelmetProvider>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
