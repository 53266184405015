export enum ActionTypes {
    TOGGLE_POST_IMAGE,
    TOGGLE_AUTH_MODAL,
    SET_CURRENT_USER,
    SET_MEDIUMS,
    SET_MEDIUM,
    SET_LAST_LOCATION,
    SET_MENU_ITEM,
    TOGGLE_SETTINGS_MODAL,
    CLEAR_NOTIFICATIONS,
    UPDATE_ADD_POST,
    TOGGLE_COLOR_PALETTE_MODAL,
    TOGGLE_FONT_MODAL_MAIN,
    TOGGLE_FONT_MODAL_SOURCE,
    SET_FONT_MODAL_DATA_MAIN,
    SET_FONT_MODAL_DATA_SOURCE,
    TOGGLE_TEXT_MODAL,
    UPDATE_ADD_POST_THOUGHT,
    SET_SEARCH_USERS,
    SET_POSTS,
    SET_NOTIFICATIONS,
    SET_VIEWING_USER,
    TOGGLE_LOADING,
    SET_LOADING,
    SET_SELECTED_POST,
    UPDATE_CREATE_WILT,
    TOGGLE_WILT_TEXT_MODAL,
    SET_SEARCH_BOOKS,
    SET_SEARCH_PODCASTS,
    SET_SEARCH_SERIES,
    SET_SEARCH_MOVIES,
    TOGGLE_MOBILE_MENU,
    SET_IS_WORKSPACES_DRAWER_OPEN,
    SET_IS_PREFERENCES_DRAWER_OPEN,
    UPDATE_CREATE_SUMMARY,
    SET_DRAFTS,
    SET_CREATE_QUOTE,
    UPDATE_VIEWING_USER_POSTS,
    UPDATE_VIEWING_USER_BOOKS,
    UPDATE_VIEWING_USER_SERIES,
    UPDATE_VIEWING_USER_MOVIES,
    UPDATE_VIEWING_USER_PODCASTS,
    TOGGLE_INTEREST_MODAL_VISIBILITY,
    SET_INTEREST_MODAL_VISIBILITY,
    SET_WISHLIST_BOOKS,
    SET_WISHLIST_MOVIES,
    SET_WISHLIST_SERIES,
    SET_WISHLIST_PODCASTS,
    SET_CURRENT_ID,
    REMOVE_FROM_WISHLIST,
    SET_MY_MEDIUMS,
    SET_TOP_RANKING_VIEWING_USER,
    SET_SCROLL_POSITION,
    SET_SCROLL_REF,
    SET_REACTION_IDS,
    SET_SHELF_IDS,
    SET_FOLLOWING_USER_IDS,
    SET_FOLLOW_REQUEST_USER_IDS,
    SET_PODCAST_EPISODE,
    SET_REQUESTS_COUNT,
    SET_NEM_MESSAGE_MODAL,
    SET_CURRENT_CHAT_USER,
    SET_SELECTED_USERS,
    SET_CURRENT_CHAT_ROOM,
    SET_UNREAD_CHATS_COUNT,
    SET_SEARCH_VALUE,
    SET_TIME_STAMPS,
    UPDATE_TIME_STAMPS,
    SET_CALENDAR_FILTERS,
    UPDATE_CALENDAR_FILTERS,
    SET_IS_CALENDAR_DRAWER_VISIBLE,
    SET_IS_AT_END_SCROLL,
    UPDATE_IS_AT_END_SCROLL,
    SET_CALENDAR_RECURRING_EVENTS
}
