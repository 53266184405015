import React from 'react';
import styles from "./searchable-header.module.scss";
import {Button, Input} from "antd";
import {PlusOutlined, BellOutlined, ArrowLeftOutlined} from '@ant-design/icons';

type SearchableHeaderProps = {
    onSearch?: (data: string) => void;
    onCreateClick?: () => void;
    onBackClick?: () => void;
    onSecondaryItemClick?: () => void;
    showNotification?: boolean;
    title: string;
    placeholder: string;
}

const { Search } = Input;
function SearchableHeader(props: SearchableHeaderProps) {
    const { onSearch, onCreateClick, onSecondaryItemClick, showNotification, title, placeholder, onBackClick } = props;
    return (
        <div className={styles.SearchableHeader}>
            <div className={styles.headerWhiteboard}>
                <div className={styles.messageHeader}>
                    <div className={styles.topHeader}>
                        {
                            onBackClick &&
                            <Button className={styles.backButton} type="link" icon={<ArrowLeftOutlined/>} onClick={onBackClick} />
                        }
                        <div className={styles.titleHeader}>
                            {title}
                        </div>
                        <div className={styles.create}>
                            {
                                showNotification &&
                                <Button
                                    className={styles.remindersButton}
                                    size="large"
                                    shape="circle"
                                    onClick={onSecondaryItemClick}
                                >
                                    <BellOutlined />
                                </Button>
                            }
                            {
                                onCreateClick &&
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={onCreateClick}
                                    icon={<PlusOutlined />}
                                />
                            }
                        </div>
                    </div>
                    {
                        onSearch &&
                        <div className={styles.searchContainer}>
                            <Search
                                className={styles.searchInput}
                                placeholder={placeholder}
                                allowClear
                                size="large"
                                onSearch={onSearch}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SearchableHeader;
