import React, {useState} from 'react';
import {useQueryClient} from "react-query";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import { Document } from "../../../../models/Document";
import {TimeStamps} from "../../../../models/TimeStamps";
import {useUpdateDocument} from "../edit-document-hooks";
import {ActionTypes} from "../../../../actions/types";
import {useParams} from "react-router-dom";
import {Form, Input, Select} from "antd";
import CreateLayout from "../../../../layouts/create-layout/create-layout";
import styles from './edit-free-form-document-screen.module.scss';

type EditFreeFormDocumentScreenProps = {
    document: Document;
}

function EditFreeFormDocumentScreen(props: EditFreeFormDocumentScreenProps) {
    const { document } = props;
    const { workspace, documentId } = useParams();
    const queryClient = useQueryClient();
    const customNavigate = useCustomNavigate();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const dispatch = useDispatch();

    const { mutate: updateDocument, isLoading } = useUpdateDocument(documentId!, (workspace) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        const newTimestamps: Partial<TimeStamps> = { DOCUMENTS: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        queryClient.refetchQueries(['document', documentId]).then(() => {
            onBackClick();
        });
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        setErrorMessage(defaultMessage);
    }, {  });

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/documents/${documentId}`);
    };

    const onFinish = (values: any) => {
        updateDocument(values);
    };

    const onFinishFailed = () => {

    };

    const formId = "edit-document";

    return (
        <CreateLayout
            title={"Edit Document"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.EditFreeFormDocumentScreen}>
                {
                    document &&
                    <Form
                        layout="vertical"
                        id={formId}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                { required: true, message: "Please enter title" },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]
                            }
                            required
                            initialValue={document.title}
                        >
                            <Input
                                size="large"
                                placeholder="Enter title for document"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Visibility"
                            name="visibility"
                            required
                            initialValue={document.visibility}
                        >
                            <Select
                                size="large"
                                placeholder="Select type"
                            >
                                <Select.Option value="PUBLIC">Public</Select.Option>
                                <Select.Option value="PRIVATE">Private</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="freeForm"
                            initialValue={document.freeForm}
                        >
                            <Input.TextArea
                                size="large"
                                placeholder="Start typing here..."
                                rows={8}
                            />
                        </Form.Item>
                    </Form>
                }
            </div>
        </CreateLayout>
    );
}

export default EditFreeFormDocumentScreen;