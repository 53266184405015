import React, {useEffect, useState} from 'react';
import styles from './edit-calendar-tag-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useQueryClient} from "react-query";
import {useCalendarTag} from "../view-calendar-tag-screen/view-calendar-tag-hooks";
import {useUpdateCalendarTag} from "./edit-calendar-tag-hooks";
import {Form, Input} from "antd";
import CreateLayout from "../../../layouts/create-layout/create-layout";

function EditCalendarTagScreen() {

    const { workspace, calendarId, calendarTagId } = useParams();
    const customNavigation = useCustomNavigate();
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { data: calendarTag } = useCalendarTag(calendarTagId!, { enabled: !!calendarTagId });

    useEffect(() => {
    }, [calendarTag])

    const { mutate: updateCalendarTag, isLoading } = useUpdateCalendarTag(calendarTagId!, calendarId!, (calendarTag) => {
        queryClient.invalidateQueries(['calendar-tags', 'calendar-tag', calendarTagId]).then(() => {
            onBackClick();
        });
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        setErrorMessage(defaultMessage);
    }, {  });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/calendars/${calendarId}/tags/${calendarTagId}`);
    };

    const onFinish = (values: any) => {
        updateCalendarTag(values);
    };

    const onFinishFailed = () => {

    };

    const formId = "edit-calendar-tag";

    return (
        <CreateLayout
            title={"Edit Calendar Tag"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.EditCalendarTagScreen}>
                <Form
                    layout="vertical"
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            { required: true, message: "Please enter unique name" },
                            {
                                pattern: /^[0-9A-Za-z-]+$/,
                                message: 'Please only use lowercase letters, numbers and hyphens'
                            },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                        initialValue={calendarTag?.name}
                    >
                        <Input
                            size="large"
                            placeholder="Enter unique name for tag"
                        />
                    </Form.Item>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default EditCalendarTagScreen;