import React, {useState} from 'react';
import styles from './edit-calendar-event-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useInfiniteQuery, useQueryClient} from "react-query";
import {useCurrentWorkspace} from "../../app-hooks";
import {useCalendarEvent} from "../view-calendar-event-screen/view-calendar-event-hooks";
import {useUpdateCalendarEvent} from "./edit-calendar-event-hooks";
import {Button, DatePicker, Drawer, Form, Input, Select, Switch, TimePicker} from "antd";
import {formatTime} from "../../../utils/utils";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import moment, {Moment} from "moment";
import axios from "axios";
import {Calendar} from "../../../models/Calendar";
import {useCalendarTagsByCalendar} from "../create-calendar-event-screen/create-calendar-event-hooks";
import {RRule} from "rrule";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import examples from "../create-calendar-event-screen/examples.json";

const { Option, OptGroup } = Select;
function EditCalendarEventScreen() {

    const [form] = Form.useForm();
    const { workspace, eventId } = useParams();
    const customNavigate = useCustomNavigate();
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [repeat, setRepeat] = useState<boolean>();
    const [hasNoEndDate, setHasNoEndDate] = useState<boolean>();
    const [startDate, setStartDate] = useState<Moment>();
    const [endDate, setEndDate] = useState<Moment>();
    const [search, setSearch] = useState('');
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace })
    const { data: calendarEvent } = useCalendarEvent(eventId!, { enabled: !!eventId && !!workspace });
    const [visible, setVisible] = useState<boolean>();
    const calendars = calendarEvent?.calendars;
    const calendarTags = calendarEvent?.tags;
    const calendarRecurringEvent = calendarEvent?.calendarRecurringEvent;
    const [validRruleText, setValidRruleText] = useState<string>();
    const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[] | undefined>(calendars?.map((c) => c._id));

    const {
        data: allCalendars,
        isLoading: isCalendarsLoading,
        isFetching: isCalendarFetching,
    } = useInfiniteQuery(
        ['calendars', search],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ calendars: Calendar[], nextId: number }>(`/api/calendars/workspaces/${currentWorkspace?._id}?limit=${100}&page=${pageParam}&timestamp=${new Date()}&search=${search}`);
            return data;
        },
        {
            enabled: !!currentWorkspace?._id,
            getNextPageParam: (lastPage) => lastPage.nextId,
        },
    );

    const { data: allCalendarTagsByCalendar } = useCalendarTagsByCalendar(selectedCalendarIds!, { enabled: !!selectedCalendarIds && selectedCalendarIds.length > 0 });


    const { mutate: updateCalendarEvent, isLoading } = useUpdateCalendarEvent(eventId!, (workspace) => {
        onBackClick();
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        setErrorMessage(defaultMessage);
    }, { });

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/calendars/events/${eventId}`);
    };

    const getTagIdsFromCalendarIds = (calendarIds: string[]): string[] => {
        return allCalendarTagsByCalendar
            ?.filter((val) => calendarIds.includes(val._id))
            .map((val) => val.tags)
            .flat()
            .map((val) => val._id) ?? [];
    }

    const allSelectedTagsAreFromSelectedCalendars = (selectedTags: string[], calendarIds: string[]): boolean => {
        const selectedTagIdsFromCalendar = getTagIdsFromCalendarIds(calendarIds);
        return selectedTags.every((val) => selectedTagIdsFromCalendar.includes(val));
    }
    const [allDay, setAllDay] = useState<boolean>();

    const onFinish = (values: any) => {
        const rruleData = RRule.fromText(values.rrule);
        const rrule = { rrule: rruleData, text: rruleData.toText() }
        const calendarEventData = {
            title: values.title,
            description: values.description,
            allDay: allDay ?? calendarEvent?.allDay,
            startDate: values.startDate?._d,
            endDate: hasNoEndDate ? undefined : values.endDate?._d,
            startTime: (allDay ?? calendarEvent?.allDay) ? undefined : values.startTime?._d,
            endTime: hasNoEndDate ? undefined : ((allDay ?? calendarEvent?.allDay) ? undefined : values.endTime?._d),
            calendarIds: values.calendar,
            calendarTags: values.calendarTags,
            recurring: values.repeat,
            rrule: values.repeat === false ? undefined : rrule,
            calendarRecurringEventId: calendarRecurringEvent ? calendarRecurringEvent?._id : undefined
        };
        if (allSelectedTagsAreFromSelectedCalendars(values.calendarTags, values.calendar)) {
            if (values.startDate?._d > values.endDate?._d) {
                setErrorMessage("Start date cannot be after end date");
            } else {
                console.log("calendarEventData: ",calendarEventData);
                updateCalendarEvent(calendarEventData);
            }
        } else {
            setErrorMessage("Selected tags do not match selected calendars")
        }
    };

    const onFinishFailed = () => {

    };

    const onAllDaySwitchChange = (data: boolean) => {
        setAllDay(data);
    };

    const onRepeatSwitchChange = (data: boolean) => {
        setRepeat(data);
    }

    const onCalendarSearch = (searchValue: string) => {
        setSearch(searchValue);
    }

    const onCalendarChange = (data: string[]) => {
        setSelectedCalendarIds(data);
    };

    const onExampleClick = () => {
        setVisible(true);
    }

    const getRruleText = () => {
        try {
            return RRule.fromText(form.getFieldValue("rrule")).toText();
        } catch (e: any) {
            return undefined;
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    const onExampleItemClick = (example: string) => {
        form.setFieldsValue({ rrule: example });
        onClose();
    }

    const onNoEndDateChange = (data: boolean) => {
        if (data === true) {
            setEndDate(undefined);
        }
        setHasNoEndDate(data);
    }

    const formId = "edit-calendar-event";

    return (
        <CreateLayout
            title={`Edit Calendar Event`}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.EditCalendarEventScreen}>
                <Drawer
                    placement="bottom"
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                    bodyStyle={{ paddingLeft: 24, paddingRight: 24 }}
                    height={500}
                >
                    <div className={styles.ExampleDrawer}>
                        <div className={styles.exampleDrawerTitle}>
                            Examples
                        </div>
                        {
                            (examples as string[]).map((example) => {
                                return <div
                                    key={example}
                                >
                                    <Button
                                        size="large"
                                        className={styles.example}
                                        onClick={() => onExampleItemClick(example)}
                                    >
                                        {example}
                                    </Button>
                                </div>;
                            })
                        }
                    </div>
                </Drawer>
                {
                    calendarEvent && isCalendarsLoading === false &&
                    <Form
                        layout="vertical"
                        id={formId}
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                { required: true, message: "Please enter title" },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]
                            }
                            required
                            initialValue={calendarEvent?.title}
                        >
                            <Input
                                size="large"
                                placeholder="Enter title for event"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Calendar"
                            name="calendar"
                            required
                            rules={[{ required: true, message: "Please select calendars" }]}
                            initialValue={calendars ? calendars?.map((c) => c._id): []}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                loading={isCalendarsLoading}
                                allowClear size="large"
                                optionFilterProp={"title"}
                                onSearch={onCalendarSearch}
                                onChange={onCalendarChange}
                            >
                                {
                                    allCalendars?.pages.map((page) => page.calendars).flat().map((calendar) => {
                                        return <Option value={calendar._id} title={calendar.title}>{calendar.title}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Tags"
                            name="calendarTags"
                            initialValue={calendarTags ? calendarTags?.map((c) => c._id): []}
                        >
                            <Select mode="multiple" showSearch allowClear size="large" optionFilterProp={"title"} >
                                {
                                    allCalendarTagsByCalendar?.map((calendarTagByCalendar) => {
                                        return <OptGroup key={calendarTagByCalendar._id} label={calendarTagByCalendar.calendar.title}>
                                            {
                                                calendarTagByCalendar.tags.map((tag) => {
                                                    return <Option key={tag._id} title={tag.name} value={tag._id}>{tag.name}</Option>
                                                })
                                            }
                                        </OptGroup>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            initialValue={calendarEvent?.description}
                        >
                            <Input
                                size="large"
                                placeholder="Enter description for event"
                            />
                        </Form.Item>
                        <div className={styles.switchContainers}>
                            <Form.Item
                                label="All-day"
                                name="all-day"
                                valuePropName="checked"
                                initialValue={allDay ?? calendarEvent?.allDay}
                            >
                                <Switch onChange={onAllDaySwitchChange} />
                            </Form.Item>
                            <Form.Item
                                className={styles.repeatSwitch}
                                label="Repeat"
                                name="repeat"
                                valuePropName="checked"
                                initialValue={repeat ?? calendarEvent?.recurring}
                            >
                                <Switch onChange={onRepeatSwitchChange} />
                            </Form.Item>
                        </div>
                        {
                            (repeat ?? calendarRecurringEvent) &&
                            <Form.Item
                                label="Repeat"
                                name="rrule"
                                required={repeat}
                                rules={[
                                    { required: repeat, message: "Please enter repeat" },
                                    { validator: (_, value) => {
                                            try {
                                                const rrule = RRule.fromText(value);
                                                if (rrule.isFullyConvertibleToText() && Object.keys(rrule.options).length !== 0) {
                                                    setValidRruleText(RRule.fromText(value).toText());
                                                    return Promise.resolve();
                                                }
                                                setValidRruleText(undefined);
                                                return Promise.reject(new Error('Invalidate recurring value'));
                                            } catch (e) {
                                                setValidRruleText(undefined);
                                                return Promise.reject(new Error('Invalidate recurring value'));
                                            }
                                        },
                                    }
                                ]}
                                initialValue={calendarRecurringEvent.rrule.text}
                            >
                                <Input
                                    addonBefore={!!(validRruleText ?? calendarRecurringEvent.rrule.text) ? <CheckCircleOutlined className={styles.correct} />: <CloseCircleOutlined className={styles.incorrect} />}
                                    size="large"
                                    addonAfter={<Button type="link" onClick={onExampleClick} >Examples</Button>}
                                    placeholder={"Enter repeating sentence"}
                                />
                            </Form.Item>
                        }
                        {
                            (repeat ?? calendarRecurringEvent) && !!getRruleText() && form.getFieldValue("rrule") &&
                            <Form.Item>
                                <span className={styles.output}>{getRruleText()}</span>
                            </Form.Item>
                        }
                        <div className={styles.dateContainer}>
                            <Form.Item
                                label="Start Date"
                                name="startDate"
                                required
                                rules={[{ required: true, message: "Please select start date" }]}
                                initialValue={moment(calendarEvent?.startDate)}
                            >
                                <DatePicker size="large" onSelect={setStartDate} />
                            </Form.Item>
                            {
                                !(allDay ?? calendarEvent?.allDay) &&
                                <Form.Item
                                    className={styles.timePicker}
                                    label="Time"
                                    name="startTime"
                                    required={!allDay}
                                    rules={[{ required: !allDay, message: "Please select start time" }]}
                                    initialValue={moment(calendarEvent?.startDate)}
                                >
                                    <TimePicker showSecond={false} format={formatTime} />
                                </Form.Item>
                            }
                        </div>
                        <div className={styles.dateContainer}>
                            <Form.Item
                                label="End Date"
                                name="endDate"
                                required={(hasNoEndDate === false ?? calendarEvent?.recurring === true)}
                                rules={[{ required: (hasNoEndDate === false ?? calendarEvent?.recurring === true), message: "Please select end date" }]}
                                initialValue={calendarEvent?.endDate ? moment(calendarEvent?.endDate): undefined}
                            >
                                <DatePicker
                                    size="large"
                                    value={endDate ?? startDate}
                                    onSelect={setEndDate}
                                    disabled={(hasNoEndDate ?? !calendarEvent?.endDate) ?? repeat}
                                />
                            </Form.Item>
                            {
                                !(allDay ?? calendarEvent?.allDay) && !(repeat ?? calendarRecurringEvent) &&
                                <Form.Item
                                    className={styles.timePicker}
                                    label="Time"
                                    name="endTime"
                                    required={!allDay}
                                    rules={[{ required: !allDay, message: "Please select end time" }]}
                                    initialValue={moment(calendarEvent?.endDate)}
                                >
                                    <TimePicker showSecond={false} format={formatTime} />
                                </Form.Item>
                            }
                            {
                                (repeat ?? calendarRecurringEvent) &&
                                <Form.Item
                                    className={styles.timePicker}
                                    label="No end date"
                                    valuePropName="checked"
                                >
                                    <Switch onChange={onNoEndDateChange} defaultChecked={!calendarEvent?.endDate} />
                                </Form.Item>
                            }
                        </div>
                    </Form>
                }
            </div>
        </CreateLayout>
    );
}

export default EditCalendarEventScreen;