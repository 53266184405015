import React from 'react';
import styles from './calendar-item.module.scss';
import {Calendar} from "../../../../models/Calendar";
import {Tag} from "antd";

type CalendarItemProps = {
    calendar: Calendar;
    onCalendarClick: (calendar: Calendar) => void;
}

function CalendarItem(props: CalendarItemProps) {
    const { calendar, onCalendarClick } = props;
    return (
        <div className={styles.CalendarItem} onClick={() => onCalendarClick(calendar)}>
            <div className={styles.title}>
                {calendar.title}
            </div>
            <div className={styles.private}>
                {
                    calendar.private ?
                        <Tag>private</Tag>
                        :
                        <Tag>public</Tag>
                }
            </div>
        </div>
    );
}

export default CalendarItem;