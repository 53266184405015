import React, {useState} from 'react';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import styles from './create-calendar-event-screen.module.scss';
import {Button, DatePicker, Drawer, Form, Input, Select, Switch, TimePicker} from "antd";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useCurrentUser, useCurrentWorkspace} from "../../app-hooks";
import {useInfiniteQuery, useQueryClient} from "react-query";
import {useCalendarTagsByCalendar, useCreateCalendarEvent} from "./create-calendar-event-hooks";
import {Moment} from "moment";
import axios from "axios";
import {Calendar} from "../../../models/Calendar";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {TimeStamps} from "../../../models/TimeStamps";
import {ActionTypes} from "../../../actions/types";
import {formatTime, getFilteredDate} from "../../../utils/utils";
import {CheckCircleOutlined, CloseCircleOutlined, SettingOutlined} from "@ant-design/icons";
import examples from './examples.json';

const { Option, OptGroup } = Select;
function CreateCalendarEventScreen() {

    const [form] = Form.useForm();
    const { workspace } = useParams();
    const customNavigation = useCustomNavigate();
    const { data: currentUser } = useCurrentUser();
    const [carryover, setCarryover] = useState(false);
    const [allDay, setAllDay] = useState(false);
    const [repeat, setRepeat] = useState(false);
    const [hasNoEndDate, setHasNoEndDate] = useState(false);
    const [startDate, setStartDate] = useState<Moment>();
    const [endDate, setEndDate] = useState<Moment>();
    const [endTime, setEndTime] = useState<Moment>();
    const [selectedCalendarIds, setSelectedCalendarIds] = useState<string[]>([]);
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!currentUser?._id && !!workspace })
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const [search, setSearch] = useState('');
    const [visible, setVisible] = useState<boolean>();
    const [validRruleText, setValidRruleText] = useState<string>();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const {
        data: allCalendars,
        isLoading: isCalendarsLoading,
        isFetching: isCalendarFetching,
    } = useInfiniteQuery(
        ['calendars', search],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ calendars: Calendar[], nextId: number }>(`/api/calendars/workspaces/${currentWorkspace?._id}?limit=${100}&page=${pageParam}&timestamp=${new Date()}&search=${search}`);
            return data;
        },
        {
            enabled: !!currentWorkspace?._id,
            getNextPageParam: (lastPage) => lastPage.nextId,
        },
    );

    const calendars = allCalendars?.pages.map((page) => page.calendars).flat();
    const { data: calendarTagsByCalendar } = useCalendarTagsByCalendar(selectedCalendarIds, { enabled: selectedCalendarIds.length > 0 });

    const { mutate: createCalendarEvent, isLoading } = useCreateCalendarEvent(currentWorkspace?._id, (newCalendarEvent) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 10);
        const newTimestamps: Partial<TimeStamps> = { CALENDAR_EVENTS: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        queryClient.invalidateQueries('calendar-recurring-events');
        queryClient.invalidateQueries('calendar-events');
        onBackClick();
    }, (error) => {
        setErrorMessage("Something went wrong. Please try again.");
    }, {  });

    const onFinish = (values: any) => {
        const rruleData = RRule.fromText(values.rrule);
        const rrule = { rrule: rruleData, text: rruleData.toText() }
        const calendarEventData = {
            title: values.title,
            description: values.description,
            allDay: allDay,
            carryover: carryover,
            startDate: values.startDate?._d,
            endDate: hasNoEndDate ? undefined : values.endDate?._d,
            startTime: allDay ? undefined : values.startTime?._d,
            endTime: hasNoEndDate ? undefined : (allDay ? undefined : values.endTime?._d),
            calendarIds: values.calendar,
            calendarTags: values.calendarTags ?? [],
            recurring: values.repeat,
            rrule,
        }

        if (allSelectedTagsAreFromSelectedCalendars(values.calendarTags ?? [], values.calendar)) {
            if (values.startDate?._d > values.endDate?._d) {
                setErrorMessage("Start date cannot be after end date");
            } else {
                createCalendarEvent(calendarEventData);
            }
        } else {
            setErrorMessage("Selected tags do not match selected calendars")
        }
    };

    const getTagIdsFromCalendarIds = (calendarIds: string[]): string[] => {
        return calendarTagsByCalendar
            ?.filter((val) => calendarIds.includes(val._id))
            .map((val) => val.tags)
            .flat()
            .map((val) => val._id) ?? [];
    }

    const allSelectedTagsAreFromSelectedCalendars = (selectedTags: string[], calendarIds: string[]): boolean => {
        const selectedTagIdsFromCalendar = getTagIdsFromCalendarIds(calendarIds);
        return selectedTags.every((val) => selectedTagIdsFromCalendar.includes(val));
    }

    const onFinishFailed = () => {

    };

    const onAllDaySwitchChange = (data: boolean) => {
        setAllDay(data);
    };

    const onRepeatSwitchChange = (data: boolean) => {
        setRepeat(data);
    };

    const onCarryOverSwitchChange = (data: boolean) => {
        setCarryover(data);
    };

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/calendars`);
    };

    const formId = "create-calendar-event";

    const onCalendarSearch = (searchValue: string) => {
        setSearch(searchValue);
    }

    const onCalendarChange = (data: string[]) => {
        setSelectedCalendarIds(data);
    };

    const onSelectStartDate = (data: Moment) => {
        setStartDate(data);
        if (!endDate) {
            form.setFieldsValue({ endDate: data });
            setEndDate(data);
        }
    };

    const onTimeOkClick = (data: Moment) => {
        if (!endTime) {
            form.setFieldsValue({ endTime: data });
            setEndTime(data);
        }
    };

    const onClose = () => {
        setVisible(false);
    };

    const onExampleClick = () => {
        setVisible(true);
    }

    const onExampleItemClick = (example: string) => {
        form.setFieldsValue({ rrule: example });
        onClose();
    }

    const getRruleText = () => {
        try {
            return RRule.fromText(form.getFieldValue("rrule")).toText();
        } catch (e: any) {
            return undefined;
        }
    };

    const disabledStartDate = (current: Moment): boolean => {
        // Can not select days before today and today
        if (endDate) {
            return current && current > endDate?.endOf('day');
        }
        return false;
    }

    const disabledEndDate = (current: Moment): boolean => {
        // Can not select days before today and today
        if (startDate) {
            return current && current < startDate?.endOf('day');
        }
        return false;
    }

    const onNoEndDateChange = (data: boolean) => {
        if (data === true) {
            setEndDate(undefined);
        }
        setHasNoEndDate(data);
    }

    return (
        <CreateLayout
            title={"Create Calendar Event"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.CreateCalendarEventScreen}>
                <Drawer
                    placement="bottom"
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                    bodyStyle={{ paddingLeft: 24, paddingRight: 24 }}
                    height={500}
                >
                    <div className={styles.ExampleDrawer}>
                        <div className={styles.exampleDrawerTitle}>
                            Examples
                        </div>
                        {
                            (examples as string[]).map((example) => {
                                return <div
                                    key={example}
                                >
                                    <Button
                                        size="large"
                                        className={styles.example}
                                        onClick={() => onExampleItemClick(example)}
                                    >
                                        {example}
                                    </Button>
                                </div>;
                            })
                        }
                    </div>
                </Drawer>
                <Form
                    layout="vertical"
                    id={formId}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            { required: true, message: "Please enter title" },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter title for calendar"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input
                            size="large"
                            placeholder="Enter description for calendar"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Calendar"
                        name="calendar"
                        required
                        rules={[{ required: true, message: "Please select calendars" }]}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            loading={isCalendarsLoading}
                            allowClear
                            size="large"
                            optionFilterProp={"title"}
                            onChange={onCalendarChange}
                            onSearch={onCalendarSearch}
                        >
                            {
                                calendars?.map((calendar) => {
                                    return <Option value={calendar._id} key={calendar._id} title={calendar.title}>{calendar.title}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Tags"
                        name="calendarTags"
                    >
                        <Select mode="multiple" showSearch allowClear size="large" optionFilterProp={"title"} >
                            {
                                calendarTagsByCalendar?.map((calendarTagByCalendar) => {
                                    return <OptGroup key={calendarTagByCalendar._id} label={calendarTagByCalendar.calendar.title}>
                                        {
                                            calendarTagByCalendar.tags.map((tag) => {
                                                return <Option key={tag._id} title={tag.name} value={tag._id}>{tag.name}</Option>
                                            })
                                        }
                                    </OptGroup>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    label="Repeat"*/}
                    {/*    name="repeat"*/}
                    {/*    initialValue={"ONCE"}*/}
                    {/*>*/}
                    {/*    <Select*/}
                    {/*        allowClear*/}
                    {/*        size="large"*/}
                    {/*        placeholder="Select repeat"*/}
                    {/*    >*/}
                    {/*        <Option value={"ONCE"} key={"ONCE"} >Once</Option>*/}
                    {/*        <Option value={"DAILY"} key={"DAILY"} >Daily</Option>*/}
                    {/*        <Option value={"WEEKLY"} key={"WEEKLY"} >Weekly</Option>*/}
                    {/*        <Option value={"MONTHLY"} key={"MONTHLY"} >Monthly</Option>*/}
                    {/*        <Option value={"YEARLY"} key={"YEARLY"} >Yearly</Option>*/}
                    {/*    </Select>*/}
                    {/*</Form.Item>*/}
                    <div className={styles.switchContainers}>
                        <Form.Item label="All-day" name="all-day" valuePropName="checked" initialValue={allDay}>
                            <Switch onChange={onAllDaySwitchChange} />
                        </Form.Item>
                        <Form.Item className={styles.repeatSwitch} label="Repeat" name="repeat" valuePropName="checked" initialValue={repeat}>
                            <Switch onChange={onRepeatSwitchChange} />
                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*    className={styles.carryOverSwitch}*/}
                        {/*    label="Carry Over"*/}
                        {/*    name="carryover"*/}
                        {/*    valuePropName="checked"*/}
                        {/*    initialValue={carryover}*/}
                        {/*    tooltip="If the event is not marked as complete it will carry over to the next day"*/}
                        {/*>*/}
                        {/*    <Switch onChange={onCarryOverSwitchChange} />*/}
                        {/*</Form.Item>*/}
                    </div>
                    {
                        repeat &&
                        <Form.Item
                            label="Repeat"
                            name="rrule"
                            required={repeat}
                            rules={[
                                { required: repeat, message: "Please enter repeat" },
                                { validator: (_, value) => {
                                    try {
                                        const rrule = RRule.fromText(value);
                                        if (rrule.isFullyConvertibleToText() && Object.keys(rrule.options).length !== 0) {
                                            setValidRruleText(RRule.fromText(value).toText());
                                            return Promise.resolve();
                                        }
                                        setValidRruleText(undefined);
                                        return Promise.reject(new Error('Invalidate recurring value'));
                                    } catch (e) {
                                        setValidRruleText(undefined);
                                        return Promise.reject(new Error('Invalidate recurring value'));
                                    }

                                    },
                                }
                                ]}
                        >
                            <Input
                                addonBefore={!!validRruleText ? <CheckCircleOutlined className={styles.correct} />: <CloseCircleOutlined className={styles.incorrect} />}
                                size="large"
                                addonAfter={<Button type="link" onClick={onExampleClick} >Examples</Button>}
                                placeholder={"Enter repeating sentence"}
                            />
                        </Form.Item>
                    }
                    {
                        repeat && !!getRruleText() && form.getFieldValue("rrule") &&
                            <Form.Item>
                                <span className={styles.output}>{getRruleText()}</span>
                            </Form.Item>
                    }
                    <div className={styles.dateContainer}>
                        <Form.Item
                            label="Start Date"
                            name="startDate"
                            required
                            rules={[{ required: true, message: "Please select start date" }]}
                        >
                            <DatePicker
                                disabledDate={disabledStartDate}
                                size="large"
                                onSelect={onSelectStartDate}
                            />
                        </Form.Item>
                        {
                            (!allDay) && <Form.Item
                                className={styles.timePicker}
                                label="Time"
                                name="startTime"
                                required={!allDay}
                                rules={[{required: !allDay, message: "Please select start time"}]}
                            >
                                <TimePicker showSecond={false} format={formatTime} onOk={onTimeOkClick}/>
                            </Form.Item>
                        }
                    </div>
                    <div className={styles.dateContainer}>
                        <Form.Item
                            label="End Date"
                            name="endDate"
                            required={!hasNoEndDate}
                            rules={[{ required: !hasNoEndDate, message: "Please select end date" }]}
                            initialValue={endDate}
                        >
                            <DatePicker
                                disabledDate={disabledEndDate}
                                size="large"
                                onSelect={setEndDate}
                                disabled={hasNoEndDate}
                            />
                        </Form.Item>
                        {
                            (!allDay && !repeat) &&
                            <Form.Item
                                className={styles.timePicker}
                                label="Time"
                                name="endTime"
                                required={!allDay}
                                rules={[{ required: !allDay, message: "Please select end time" }]}
                            >
                                <TimePicker showSecond={false} format={formatTime} />
                            </Form.Item>
                        }
                        {
                            repeat &&
                            <Form.Item className={styles.timePicker} label="No end date" valuePropName="checked" initialValue={hasNoEndDate}>
                                <Switch onChange={onNoEndDateChange} />
                            </Form.Item>
                        }
                    </div>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default CreateCalendarEventScreen;