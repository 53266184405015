import React, {useState} from 'react';
import styles from './metric-items-tab.module.scss';
import {Button} from "antd";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useParams} from "react-router-dom";
import {useCurrentWorkspace} from "../../../app-hooks";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import { MetricItem } from '../../../../models/MetricItem';
import MetricItemCard from "../../../metrics-items-screens/list-metric-items-screen/metric-item-card/metric-item-card";
import ListLayout from "../../../../layouts/list-layout/list-layout";
import {useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {TimeStamps} from "../../../../models/TimeStamps";
import {getCurrency, getDisplayFields} from "../../list-metrics-screen/metrcis-item/metrics-item-helper";
import {Metric} from "../../../../models/Metric";

type MetricItemsTabProps = {
    metric: Metric
};

function MetricItemsTab(props: MetricItemsTabProps) {
    const { metric } = props;
    const { workspace, metricId } = useParams();
    const customNavigation = useCustomNavigate();
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [limit] = useState(12);
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace });
    const { value } = getDisplayFields(metric);

    const {
        data: metricItems,
        fetchNextPage,
        isLoading,
    } = useInfiniteQuery(
        ['metric-items', metricId],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ metricItems: MetricItem[], nextId: number }>(`/api/metric-items/metric/${metricId}?limit=${limit}&page=${pageParam}&timestamp=${timeStamps.METRIC_ITEMS}`);
            if (data?.metricItems?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!currentWorkspace,
            keepPreviousData: true
        },
    );

    const onCreateNewMetricItemClick = () => {
        customNavigation.push(`/${workspace}/metrics/${metricId}/metric-items/create`);
    };

    const onMetricItemClick = (metricItemId: string) => {
        customNavigation.push(`/${workspace}/metrics/${metricId}/metric-items/${metricItemId}`);
    };

    return (
        <div className={styles.MetricItemsTab}>
            <div className={styles.sumContainer}>
                <span>{metric.unit === 'CURRENCY' && getCurrency(metric.currency)}</span>
                <span>{value}</span>
            </div>
            <div className={styles.addMetricItemContainer}>
                <Button
                    type="dashed"
                    size="large"
                    className={styles.addMetricButton}
                    onClick={onCreateNewMetricItemClick}
                >
                    ADD METRIC ITEM
                </Button>
            </div>
            <ListLayout
                isFetching={isLoading}
                data={metricItems?.pages.map((page) => page.metricItems).flat()}
                fetchNextPage={fetchNextPage}
                isAtTheEnd={isAtTheEnd}
                skeletonCardHeight={60}
            >
                <>
                    {
                        metricItems?.pages.map((page) => page.metricItems).flat().map((val, index) => {
                            return <MetricItemCard
                                key={val._id}
                                metricItem={val}
                                metric={metric}
                                onMetricItemClick={onMetricItemClick}
                            />
                        })
                    }
                </>
            </ListLayout>
        </div>
    );
}

export default MetricItemsTab;