import React, {useState} from 'react';
import {Switch} from 'antd';
import {
  SnippetsOutlined,
  BulbOutlined,
  CloseOutlined,
  BarChartOutlined, CalendarOutlined, TabletOutlined,
} from '@ant-design/icons';
import {useLocation, useParams} from 'react-router-dom';
import styles from './Menu.module.scss';
import {useThemeSwitcher} from "react-css-theme-switcher";
import {useCurrentUser} from "../../screens/app-hooks";
import {useCustomNavigate} from "../../hooks/navigation";

function Menu() {

  const params = useParams<'workspace'>();
  const { workspace } = params;
  const customNavigation = useCustomNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { data: currentUser } = useCurrentUser();
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(localStorage.getItem('navyflow-theme') === 'dark');
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();

  const onChangeTheme = (event: any) => {
    if (localStorage.getItem('navyflow-theme') === "dark") {
      document.documentElement.style.setProperty("--main-background-color", "var(--light-background-color)");
      document.documentElement.style.setProperty("--main-secondary-background-color", "var(--light-secondary-background-color)");
      document.documentElement.style.setProperty("--main-border-color", "var(--light-border-color)");
      document.documentElement.style.setProperty("--main-card-color", "var(--light-card-color)");
      document.documentElement.style.setProperty("--primary-text-color", "var(--light-primary-text-color)");
      document.documentElement.style.setProperty("--secondary-text-color", "var(--light-secondary-text-color)");
      document.documentElement.style.setProperty("--main-skeleton-color", "var(--light-skeleton-color)");
      document.documentElement.style.setProperty("--main-filter", "var(--light-filter)");
      document.documentElement.style.setProperty("--main-gradient", "var(--light-gradient)");
      document.documentElement.style.setProperty("--main-home-divider-color", "var(--light-home-divider-color)");
      document.documentElement.style.setProperty("--main-hover-color", "var(--light-hover-color)");
      localStorage.setItem('navyflow-theme', "light");
      setIsDarkTheme(false);
      switcher({ theme: themes.light });
    } else {
      document.documentElement.style.setProperty("--main-background-color", "var(--dark-background-color)");
      document.documentElement.style.setProperty("--main-secondary-background-color", "var(--dark-secondary-background-color)");
      document.documentElement.style.setProperty("--main-border-color", "var(--dark-border-color)");
      document.documentElement.style.setProperty("--main-card-color", "var(--dark-card-color)");
      document.documentElement.style.setProperty("--primary-text-color", "var(--dark-primary-text-color)");
      document.documentElement.style.setProperty("--secondary-text-color", "var(--dark-secondary-text-color)");
      document.documentElement.style.setProperty("--main-skeleton-color", "var(--dark-skeleton-color)");
      document.documentElement.style.setProperty("--main-filter", "var(--dark-filter)");
      document.documentElement.style.setProperty("--main-gradient", "var(--dark-gradient)");
      document.documentElement.style.setProperty("--main-home-divider-color", "var(--dark-home-divider-color)");
      document.documentElement.style.setProperty("--main-hover-color", "var(--dark-hover-color)");
      localStorage.setItem('navyflow-theme', "dark");
      setIsDarkTheme(true);
      switcher({ theme: themes.dark });
    }
  }

  const tabs = ['home','explore','trending','notifications', 'chats'];

  const urlValue = pathname.replace('/','');

  const onMetricsClick = () => {
    customNavigation.push(`/${workspace}/metrics`)
  }

  const onCalendarClick = () => {
    customNavigation.push(`/${workspace}/calendars`)
  }

  const onDocumentsClick = () => {
    customNavigation.push(`/${workspace}/documents`)
  }

  const onProductsClick = () => {
    customNavigation.push(`/${workspace}/products`)
  }

  return (
    <div className={`${["edit-profile"].includes(workspace ?? '') ? styles.hideMenu : styles.Menu}`}>
      <div className={styles.container}>
        {/*<div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('home') && styles.active}`} onClick={() => { router.replace('/home'); }}>*/}
        {/*  <HomeOutlined className={`${styles.icon} ${pathname.includes('home') && styles.active}`} type="home" />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Home*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('metrics') && styles.active}`} onClick={onMetricsClick}>
          <BarChartOutlined className={`${styles.icon} ${pathname.includes('metrics') && styles.active}`} />
          <div className={styles.title}>
            Metrics
          </div>
        </div>
        <div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('calendars') && styles.active}`} onClick={onCalendarClick}>
          <CalendarOutlined className={`${styles.icon} ${pathname.includes('calendars') && styles.active}`} />
          <div className={styles.title}>
            Calendars
          </div>
        </div>
        <div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('documents') && styles.active}`} onClick={onDocumentsClick}>
          <SnippetsOutlined className={`${styles.icon} ${pathname.includes('documents') && styles.active}`} />
          <div className={styles.title}>
            Documents
          </div>
        </div>
        {/*<div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('products') && styles.active}`} onClick={onProductsClick}>*/}
        {/*  <TabletOutlined className={`${styles.icon} ${pathname.includes('products') && styles.active}`} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Products*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('teams') && styles.active}`} onClick={() => { customNavigation.replace('/teams'); }}>*/}
        {/*  <TeamOutlined className={`${styles.icon} ${pathname.includes('teams') && styles.active}`} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Teams*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('flows') && styles.active}`} onClick={() => { customNavigation.replace('/flows'); }}>*/}
        {/*  <PartitionOutlined className={`${styles.icon} ${pathname.includes('flows') && styles.active}`} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Flows*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('explore') && styles.active}`} onClick={() => { customNavigation.replace('/explore'); }}>*/}
        {/*  <NumberOutlined className={`${styles.icon} ${pathname.includes('explore') && styles.active}`} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Explore*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div role="button" tabIndex={0} className={`${styles.menuItem} ${pathname.includes('trending') && styles.active}`} onClick={() => { customNavigation.replace('/trending'); }}>*/}
        {/*  <FireOutlined className={`${styles.icon} ${pathname.includes('trending') && styles.active}`} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Trending*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*    role="button"*/}
        {/*    tabIndex={0}*/}
        {/*    className={`${styles.menuItem} ${pathname.includes('chats') && styles.active}`}*/}
        {/*    onClick={() => { customNavigation.replace('/chats'); }}*/}
        {/*>*/}
        {/*  <MessageOutlined className={`${styles.icon} ${pathname.includes('chats') && styles.active}`} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Chats*/}
        {/*  </div>*/}
        {/*  /!*<Badge count={currentUser?.notificationCount} overflowCount={10} />*!/*/}
        {/*</div>*/}
        {/*<div*/}
        {/*    role="button"*/}
        {/*    tabIndex={0}*/}
        {/*    className={`${styles.menuItem} ${pathname.includes('notifications') && styles.active}`}*/}
        {/*    onClick={() => { customNavigation.replace('/notifications'); }}*/}
        {/*>*/}
        {/*  <BellOutlined className={`${styles.icon} ${pathname.includes('notifications') && styles.active}`} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Notifications*/}
        {/*  </div>*/}
        {/*  <Badge count={currentUser?.notificationCount} overflowCount={10} />*/}
        {/*</div>*/}
        {/*<div*/}
        {/*    role="button"*/}
        {/*    tabIndex={0}*/}
        {/*    className={`${styles.menuItem} ${isProfile && styles.active}`}*/}
        {/*    onClick={() => { customNavigation.replace(`/${currentUser?.username || 'unknown'}`); }}*/}
        {/*>*/}
        {/*  <UserOutlined className={`${styles.icon} ${isProfile && styles.active}`} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Profile*/}
        {/*  </div>*/}
        {/*  /!*<Badge count={unreadChats} />*!/*/}
        {/*</div>*/}
        {/*<div role="button" tabIndex={0} className={styles.menuItem} onClick={() => { router.replace('/messages'); }}>*/}
        {/*  <MailOutlined className={styles.icon} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Messages*/}
        {/*  </div>*/}
        {/*  <Badge count={unreadChats} />*/}
        {/*</div>*/}
        {/*<div role="button" tabIndex={0} className={styles.menuItem} onClick={() => { router.replace('/drafts'); }}>*/}
        {/*  <SnippetsOutlined className={styles.icon} />*/}
        {/*  <div className={styles.title}>*/}
        {/*    Drafts*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<Button*/}
        {/*    type="primary"*/}
        {/*    size="large"*/}
        {/*    className={styles.create}*/}
        {/*    onClick={() => { router.replace('/create'); }}*/}
        {/*>*/}
        {/*  Create*/}
        {/*</Button>*/}
      </div>
      <div className={styles.themeContainer}>
        <Switch
            className={styles.themeSwitch}
            onChange={onChangeTheme}
            checked={isDarkTheme}
            checkedChildren={<BulbOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked
        />
      </div>
    </div>
  );
}

export default Menu;
