import React, {useState} from 'react';
import styles from './create-free-form-document-screen.module.scss';
import {Button, Form, Input, Select} from "antd";
import CreateLayout from "../../../../layouts/create-layout/create-layout";
import {TimeStamps} from "../../../../models/TimeStamps";
import {ActionTypes} from "../../../../actions/types";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {useCurrentUser, useCurrentWorkspace} from "../../../app-hooks";
import {useCreateDocument} from "../create-document-hooks";

function CreateFreeFormDocumentScreen() {

    const { workspace } = useParams();
    const customNavigation = useCustomNavigate();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const { data: currentUser } = useCurrentUser();
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!currentUser?._id && !!workspace })
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [selectedType, setSelectedType] = useState<string>();
    const dispatch = useDispatch();

    const { mutate: createDocument, isLoading } = useCreateDocument(currentWorkspace?._id, (document) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        const newTimestamps: Partial<TimeStamps> = { DOCUMENTS: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        onBackClick();
    }, (error) => {
        console.log("error: ",error.response);
        setErrorMessage("Something went wrong. Please try again.");
    }, {  });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/documents`);
    };

    const onFinish = (values: any) => {
        createDocument({...values, type: "FREE_FORM" });
    };

    const onFinishFailed = () => {

    };

    const formId = "create-document";

    return (
        <CreateLayout
            title={"Create Document"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.CreateMetricScreen}>
                <Form
                    layout="vertical"
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            { required: true, message: "Please enter title" },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter title for document"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Visibility"
                        name="visibility"
                        required
                        initialValue={"PUBLIC"}
                    >
                        <Select
                            size="large"
                            placeholder="Select type"
                        >
                            <Select.Option value="PUBLIC">Public</Select.Option>
                            <Select.Option value="PRIVATE">Private</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        // label="Description"
                        name="freeForm"
                    >
                        <Input.TextArea
                            size="large"
                            placeholder="Start typing here..."
                            rows={8}
                        />
                    </Form.Item>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default CreateFreeFormDocumentScreen;