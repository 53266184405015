import React from 'react';
import styles from './list-header.module.scss';
import {Button, Input} from "antd";
import {ArrowLeftOutlined, FilterOutlined, PlusOutlined} from "@ant-design/icons";

type ListHeaderProps = {
    title: string,
    onCreateClick?: () => void,
    onBackClick?: () => void,
    onSearch?: (value: string) => void,
    onAdvanceFilter?: () => void,
    searchPlaceholder?: string,
    filterComponent?: JSX.Element;
    rightItems?: JSX.Element;
    defaultSearchValue?: string
};

const { Search } = Input;
function ListHeader(props: ListHeaderProps) {
    const { title, onCreateClick, onSearch, searchPlaceholder, filterComponent, rightItems,
        onBackClick, onAdvanceFilter, defaultSearchValue } = props;

    return (
        <div className={styles.ListHeader}>
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    {
                        onBackClick &&
                        <Button
                            className={styles.goBack}
                            type="link"
                            icon={<ArrowLeftOutlined />}
                            onClick={onBackClick}
                        />
                    }
                    <span className={styles.title}>{title}</span>
                </div>
                <div className={styles.rightContainer}>
                    {
                        rightItems &&
                            <>{rightItems}</>
                    }
                    {
                        !rightItems && onCreateClick &&
                        <Button
                            size="large"
                            type="primary"
                            onClick={onCreateClick}
                            icon={<PlusOutlined />}
                        />
                    }
                </div>
            </div>
            {
                onSearch &&
                <div className={styles.searchContainer}>
                    <Search
                        className={styles.searchInput}
                        placeholder={searchPlaceholder}
                        allowClear
                        size="large"
                        onSearch={onSearch}
                        defaultValue={defaultSearchValue}
                    />
                    {
                        onAdvanceFilter &&
                        <Button
                            className={styles.advancedFilters}
                            size="large"
                            onClick={onAdvanceFilter}
                            icon={<FilterOutlined />}
                        />
                    }
                </div>
            }
            {
                filterComponent &&
                <>{filterComponent}</>
            }
        </div>
    );
}

export default ListHeader;