import React, {useState} from 'react';
import styles from './create-flow-document-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {TimeStamps} from "../../../../models/TimeStamps";
import {useCurrentUser, useCurrentWorkspace} from "../../../app-hooks";
import {useCreateDocumentFlow} from "../create-document-hooks";
import {ActionTypes} from "../../../../actions/types";
import {Button, Divider, Form, Input, Modal, Select, Tree, TreeSelect} from "antd";
import CreateLayout from "../../../../layouts/create-layout/create-layout";
import {PlusOutlined} from "@ant-design/icons";
import CreateApiModal from "./CreateApiModal/CreateApiModal";
import {useStore} from "../../../../index";
import {ApiLeafNode, ApiTreeNode} from "../../../../models/TreeNode";

function CreateFlowDocumentScreen() {

    const { workspace } = useParams();
    const customNavigation = useCustomNavigate();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const { data: currentUser } = useCurrentUser();
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!currentUser?._id && !!workspace })
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [apiTreeData, setApiTreeData] = useState<ApiTreeNode[]>([]);
    const [apiCounter, setApiCounter] = useState(0);
    const [tag, setTag] = useState<{ displayName: string, uniqueName: string }>();
    const [tags, setTags] = useState<{ displayName: string, uniqueName: string }[]>([]);
    const setIsCreateApiModalVisible = useStore(state => state.setIsCreateApiModalVisible);
    const dispatch = useDispatch();

    const { mutate: createFlowDocument, isLoading } = useCreateDocumentFlow(currentWorkspace?._id, (document) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        const newTimestamps: Partial<TimeStamps> = { DOCUMENTS: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        onBackClick();
    }, (error) => {
        setErrorMessage("Something went wrong. Please try again.");
    }, {  });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/documents`);
    };

    const formId = "create-document";

    const onFinish = (values: any) => {
        const newDocumentFlow = {...values, apis: apiTreeData };
        createFlowDocument(newDocumentFlow);
    };

    const onFinishFailed = () => {

    };

    const onAddApi = () => {
        setIsCreateApiModalVisible(true);
    }

    const onCreateApiAction = (values: any) => {
        const tempChildren: ApiLeafNode[] = [];

        if (values.title) {
            tempChildren.push({ title: `Title: ${values.title}`, value: `Title: ${values.title}`, level: 1, children: [], isLeaf: true, disabled: true });
        }

        if (values.type) {
            // tempChildren.push({ title: `Type: ${values.type}`, value: `Type: ${values.type}`, key: `${apiCounter}-type`, level: 1, children: [], isLeaf: true, disabled: true });
            tempChildren.push({ title: `Type: ${values.type}`, value: `Type: ${values.type}`, level: 1, children: [], isLeaf: true, disabled: true });
        }

        if (values.url) {
            // tempChildren.push({ title: `Url: ${values.url}`, value: `Url: ${values.url}`, key: `${apiCounter}-url`, level: 1, children: [], isLeaf: true, disabled: true });
            tempChildren.push({ title: `Url: ${values.url}`, value: `Url: ${values.url}`, level: 1, children: [], isLeaf: true, disabled: true });
        }

        if (values.params) {
            const paramsChildren: ApiLeafNode[] = [];
            // paramsChildren.push({ title: values.params, value: `Params: ${values.params}`, key: `${apiCounter}-params-data`, level: 2, children: [], isLeaf: true, disabled: true });
            paramsChildren.push({ title: values.params, value: `Params: ${values.params}`, level: 2, children: [], isLeaf: true, disabled: true });
            // tempChildren.push({ title: "Params", value: "Params", key: `${apiCounter}-params`, level: 1, children: paramsChildren, isLeaf: false, disabled: true });
            tempChildren.push({ title: "Params", value: "Params", level: 1, children: paramsChildren, isLeaf: false, disabled: true });
        }

        if (values.requestBody) {
            const requestBodyChildren: ApiLeafNode[] = [];
            // requestBodyChildren.push({ title: values.requestBody, value: `Request body: ${values.requestBody}`, key: `${apiCounter}-request-body-data`, level: 2, children: [], isLeaf: true, disabled: true });
            requestBodyChildren.push({ title: values.requestBody, value: `Request body: ${values.requestBody}`, level: 2, children: [], isLeaf: true, disabled: true });
            // tempChildren.push({ title: "Request body", value: "Request body", key: `${apiCounter}-request-body`, level: 1, children: requestBodyChildren, isLeaf: false, disabled: true });
            tempChildren.push({ title: "Request body", value: "Request body", level: 1, children: requestBodyChildren, isLeaf: false, disabled: true });
        }

        if (values.response) {
            const responseChildren: ApiLeafNode[] = [];
            // responseChildren.push({ title: values.response, value: `Response: ${values.requestBody}`, key: `${apiCounter}-response-data`, level: 2, children: [], isLeaf: true, disabled: true });
            responseChildren.push({ title: values.response, value: `Response: ${values.requestBody}`, level: 2, children: [], isLeaf: true, disabled: true });
            // tempChildren.push({ title: "Response", value: "Response", key: `${apiCounter}-response`, level: 1, children: responseChildren, isLeaf: false, disabled: true });
            tempChildren.push({ title: "Response", value: "Response", level: 1, children: responseChildren, isLeaf: false, disabled: true });
        }

        if (values.summary) {
            const summaryChildren: ApiLeafNode[] = [];
            // summaryChildren.push({ title: values.summary, value: `Summary: ${values.summary}`, key: `${apiCounter}-summary-data`, level: 2, children: [], isLeaf: true, disabled: true });
            summaryChildren.push({ title: values.summary, value: `Summary: ${values.summary}`, level: 2, children: [], isLeaf: true, disabled: true });
            // tempChildren.push({ title: "Summary", value: "Summary", key: `${apiCounter}-summary`, level: 1, children: summaryChildren, isLeaf: false, disabled: true });
            tempChildren.push({ title: "Summary", value: "Summary", level: 1, children: summaryChildren, isLeaf: false, disabled: true });
        }

        const newApiTreeData: ApiTreeNode = {
            // key: `${values.type}: ${values.title}`,
            apiValue: values,
            value: `${values.type}: ${values.title}`,
            title: `${values.type}: ${values.title}`,
            level: 0,
            children: tempChildren
        }

        setApiTreeData([...apiTreeData, newApiTreeData]);
        setApiCounter(apiCounter + 1);
    }

    const addNewTag = () => {
        if (tag) {
            setTags([...tags, tag]);
            setTag(undefined);
        }
    }

    const onTagChange = (data: any) => {
        const value = data.target.value;
        setTag({ displayName: value, uniqueName: value });
    }

    const onSelect = () => {

    };

    return (
        <CreateLayout
            title={"Create Document"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.CreateFlowDocumentScreen}>
                <CreateApiModal
                    onCreateApiAction={onCreateApiAction}
                />
                <Form
                    layout="vertical"
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            { required: true, message: "Please enter title" },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter title for document"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Visibility"
                        name="visibility"
                        required
                        initialValue={"PUBLIC"}
                    >
                        <Select
                            size="large"
                            placeholder="Select type"
                        >
                            <Select.Option value="PUBLIC">Public</Select.Option>
                            <Select.Option value="PRIVATE">Private</Select.Option>
                        </Select>
                    </Form.Item>
                    <Divider />
                    <div className={styles.nodesTitle}>
                        Create Node
                    </div>
                    <Form.Item
                        label="Node Title"
                        name="nodeTitle"
                        rules={[
                            { required: true, message: "Please enter title" },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter title for flow node"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input.TextArea
                            size="large"
                            placeholder="Start typing here..."
                            rows={2}
                        />
                    </Form.Item>
                    <Form.Item
                        className={styles.selectItem}
                        label="Tags"
                        name="tags"
                    >
                        <Select
                            className="item-name-id"
                            mode="multiple"
                            size="large"
                            placeholder="Select tags"
                            dropdownRender={(dropdownOptions) => (
                                <div>
                                    {dropdownOptions}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                        <Input
                                            size="large"
                                            style={{ flex: 'auto' }}
                                            value={tag?.uniqueName}
                                            onChange={onTagChange}
                                        />
                                        <Button
                                            style={{
                                                flex: 'none', padding: '8px', display: 'block', cursor: 'pointer',
                                            }}
                                            onClick={addNewTag}
                                            type="link"
                                        >
                                            <PlusOutlined />
                                            {' '}
                                            Add item
                                        </Button>
                                    </div>
                                </div>
                            )}
                        >
                            {tags.map((item, index) => (
                                <Select.Option key={item.uniqueName} value={item.uniqueName}>{item.displayName}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        className={styles.selectItem}
                        label="API's"
                        name="apis"
                        // initialValue={existingNote?.noteCategories?.map((val) => val._id)}
                    >
                        <TreeSelect
                            style={{ width: '100%' }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={apiTreeData}
                            // tagRender={(item) => <div>{item.value}</div>}
                            placeholder="Please select"
                            multiple
                            size="large"
                            onSelect={onSelect}
                            // treeDefaultExpandAll
                            // onChange={this.onChange}
                        />
                        <Button className={styles.addApi} size="large" onClick={onAddApi}>
                            <PlusOutlined />
                            {' '}
                            Add API
                        </Button>
                    </Form.Item>
                    <Form.Item
                        className={styles.selectItem}
                        label="From Nodes"
                        name="fromNodes"
                    >
                        <Select
                            className="item-name-id"
                            mode="multiple"
                            size="large"
                            placeholder="Select from nodes"
                            >

                        </Select>
                    </Form.Item>
                    <Form.Item
                        className={styles.selectItem}
                        label="To Nodes"
                        name="toNodes"
                    >
                        <Select
                            className="item-name-id"
                            mode="multiple"
                            size="large"
                            placeholder="Select to nodes"
                        >

                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default CreateFlowDocumentScreen;