import React, {useState} from 'react';
import styles from './create-workspaces-screen.module.scss';
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {Form, Input} from "antd";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useCreateWorkspace} from "./create-workspaces-hooks";
import {useQueryClient} from "react-query";
import {useCurrentUser} from "../../app-hooks";
import {TimeStamps} from "../../../models/TimeStamps";
import {ActionTypes} from "../../../actions/types";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";

function CreateWorkspacesScreen() {

    const customNavigation = useCustomNavigate();
    const { data: currentUser } = useCurrentUser();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const dispatch = useDispatch();

    const { mutate: createWorkspace, isLoading } = useCreateWorkspace( (workspace) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        const newTimestamps: Partial<TimeStamps> = { WORKSPACES: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        onBackClick();
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again."
        if ([400,409].includes(error.response?.status ?? 0)) {
            setErrorMessage("Workspace with unique name already exists");
        } else {
            setErrorMessage(defaultMessage);
        }
    }, {  });

    const onBackClick = () => {
        customNavigation.goBack('/workspaces');
    };

    const onFinish = (values: any) => {
        const data = new FormData();

        if (values.displayName) {
            data.append('displayName', values.displayName);
        }

        if (values.uniqueName) {
            data.append('uniqueName', values.uniqueName);
        }

        if (values.description) {
            data.append('description', values.description);
        }

        createWorkspace(data);
    };

    const onFinishFailed = () => {

    };

    const formId = "create-workspace";
    return (
        <CreateLayout
            title={"Create Workspace"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.CreateWorkspacesScreen}>
                <Form
                    layout="vertical"
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Unique Name"
                        name="uniqueName"
                        rules={[
                            { required: true, message: "Please enter unique name" },
                            {
                                pattern: /^[0-9A-Za-z-]+$/,
                                message: 'Please only use lowercase letters, numbers and hyphens'
                            },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                            ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter unique name for workspace"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Display Name"
                        name="displayName"
                        rules={[{ required: true, pattern: new RegExp("[A-Za-z0-9\\- ]+"), message: "Please enter display name" }]}
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter display name for workspace"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input
                            size="large"
                            placeholder="Enter description for workspace"
                        />
                    </Form.Item>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default CreateWorkspacesScreen;