import React from 'react';
import {Document} from '../../../../models/Document';
import styles from './document-preview-item.module.scss';
// @ts-ignore
import { Textfit } from 'react-textfit';
import {Progress, Tag} from "antd";
import {convertTimeToDaysAgo} from "../../../../utils/utils";
import {LockOutlined} from "@ant-design/icons";

type DocumentPreviewItemProps = {
    document: Document;
    onDocumentClick: (document: Document) => void;
}

const DOCUMENT_TYPE: any = {
    'FREE_FORM': 'Free form',
    'LIST': 'List'
}

function DocumentPreviewItem(props: DocumentPreviewItemProps) {
    const { onDocumentClick, document } = props;
    const { title, type, visibility, updatedAt, listItems } = document;

    const docPercent = Math.floor((((listItems?.filter((item) => item.checked))?.length ?? 0)/(listItems?.length ?? 0)*100));
    return (
        <div className={styles.DocumentPreviewItem} onClick={() => onDocumentClick(document)} >
            <div className={styles.title}>
                {convertTimeToDaysAgo(updatedAt)}
            </div>
            <Textfit className={styles.value} min={8}>
                {title}
            </Textfit>
            <div className={styles.timestamp}>
                {
                    visibility === 'PRIVATE' &&
                        <LockOutlined className={styles.privateIcon} />
                }
                {
                    type === "LIST" &&
                    <Progress
                        percent={docPercent}
                        steps={listItems?.length} size={(listItems?.length ?? 0) > 5 ? "small": "default"}
                        status="active"
                        strokeColor={docPercent === 100 ? "#52c41a": undefined}
                    />
                }
                {
                    type === "FREE_FORM" &&
                    <Tag>{DOCUMENT_TYPE[type]}</Tag>
                }
            </div>
        </div>
    );
}

export default DocumentPreviewItem;