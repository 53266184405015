import React, {useState} from 'react';
import styles from './list-documents-screen.module.scss';
import ListHeader from "../../../components/list-header/list-header";
import GridLayout from "../../../layouts/grid-layout/grid-layout";
import { Document } from '../../../models/Document';
import {useCustomNavigate} from "../../../hooks/navigation";
import {useParams} from "react-router-dom";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import DocumentPreviewItem from "./document-preview-item/document-preview-item";
import {useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {TimeStamps} from "../../../models/TimeStamps";
import {useCurrentWorkspace} from "../../app-hooks";
import {Button, Dropdown, Menu} from "antd";
import {PlusOutlined} from "@ant-design/icons";

function ListDocumentsScreen() {
    const { workspace } = useParams();
    const customNavigate = useCustomNavigate();
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const [search, setSearch] = useState('');
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace })

    const {
        data: documentsPaged,
        isLoading,
        fetchNextPage,
    } = useInfiniteQuery(
        ['documents', search],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ documents: Document[], nextId: number }>(`/api/documents/workspaces/${currentWorkspace?._id}?limit=${12}&page=${pageParam}&timestamp=${timeStamps.DOCUMENTS}&search=${search}`);
            if (data?.documents?.length < 12) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!currentWorkspace
        },
    );

    const onCreateClick = () => {
        customNavigate.push(`/${workspace}/documents/create`);
    };

    const onSearch = (value: string) => {
        setSearch(value);
    };

    const onDocumentClick = (document: Document) => {
        customNavigate.push(`/${workspace}/documents/${document._id}`);
    }

    const documents = documentsPaged?.pages.map((page) => page.documents).flat();

    const onCreateFreeFormClick = (value: string) => {
        customNavigate.push(`/${workspace}/documents/create/${value}`);
    };

    const onCreateNewEventClick = () => {
        customNavigate.push(`/${workspace}/documents/create/free-form`);
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => onCreateFreeFormClick('free-form')}>
                <Button size="large" type="link">
                    Free form
                </Button>
            </Menu.Item>
            <Menu.Item onClick={() => onCreateFreeFormClick('flow')}>
                <Button size="large" type="link">
                    Flow
                </Button>
            </Menu.Item>
            <Menu.Item onClick={() => onCreateFreeFormClick('list')}>
                <Button size="large" type="link">
                    Todo/check list
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <ListHeader
                title={"Documents"}
                onCreateClick={onCreateClick}
                onSearch={onSearch}
                searchPlaceholder={"Search your documents"}
                rightItems={<Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                    />
                </Dropdown>}
            />
            <div className={styles.ListDocumentsScreen}>
                <GridLayout
                    isFetching={false}
                    isLoading={isLoading}
                    data={documents}
                    fetchNextPage={fetchNextPage}
                    isAtTheEnd={isAtTheEnd}
                    emptyView={{
                        title: "",
                        subTitle: "",
                        url: "",
                        icon: "",
                        buttonTitle: "",
                        screenItem: ""
                    }}
                >
                    <>
                        {
                            documents?.map((doc) => {
                                return <DocumentPreviewItem
                                    key={doc?._id}
                                    document={doc}
                                    onDocumentClick={onDocumentClick}
                                />
                            })
                        }
                    </>
                </GridLayout>
            </div>
        </>
    );
}

export default ListDocumentsScreen;