import React, {useEffect, useState} from 'react';
import styles from './create-metric-item-screen.module.scss';
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useParams} from "react-router-dom";
import {DatePicker, Form, Input, InputNumber} from "antd";
import moment from 'moment';
import {useCreateMetricItem} from "./create-metric-item-hooks";
import {useMetric} from "../../metrics-screens/view-metric-screen/view-metric-hooks";
import {useDispatch} from "react-redux";
import {ActionTypes} from "../../../actions/types";

function CreateMetricItemScreen() {

    const { workspace, metricId } = useParams();
    const customNavigation = useCustomNavigate();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { data: metric } = useMetric(metricId!, { enabled: !!metricId });
    const dispatch = useDispatch();

    useEffect(() => {
    }, [metric])

    const { mutate: createMetricItem, isLoading } = useCreateMetricItem(metricId, (workspace) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        dispatch({ type: ActionTypes.UPDATE_TIME_STAMPS, value: { METRIC_ITEMS: newDate, METRICS: newDate } });
        onBackClick();
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again."
        setErrorMessage(defaultMessage);
    }, {  });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/metrics/${metricId}`);
    };

    const onFinish = (values: any) => {
        const data = {
            numericValue: values.value,
            description: values.description,
            timeStamp: new Date(values.date._d)
        }
        createMetricItem(data);
    };

    const onFinishFailed = () => {

    };

    const formId = "create-metric-item-form";
    return (
        <CreateLayout
            title={`Create Metric Item`}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.CreateMetricItemScreen}>
                <Form
                    layout="vertical"
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Value"
                        name="value"
                        required={true}
                        rules={[{ required: true, message: "Please select value" }]}
                    >
                        <InputNumber
                            className={styles.valueInputNumber}
                            // formatter={value => `${getCurrency(metric?.currency ?? '')} ${value}`}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Date"
                        name="date"
                        required={true}
                        initialValue={moment()}
                        rules={[{ required: true, message: "Please select date"}]}
                    >
                        <DatePicker size="large" />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input
                            className={styles.description}
                            size="large"
                            placeholder="Enter description..."
                            allowClear
                        />
                    </Form.Item>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default CreateMetricItemScreen;