import React, {useState} from 'react';
import styles from './profile-edit-screen.module.scss';
import {useCurrentUser} from "../app-hooks";
import {Button, Dropdown, Form, Input, Menu, Tabs} from "antd";
import {UserOutlined} from "@ant-design/icons";
import TabTitle from "../../components/tab-title/tab-title";
import {useCheckUsernameAvailability, useUpdateCurrentUser} from "./profile-edit-hooks";
import {User} from "../../models/User";
import {AxiosError} from "axios";
import {useQueryClient} from "react-query";
import {useCustomNavigate} from "../../hooks/navigation";

const { TabPane } = Tabs;
function ProfileEditScreen() {

    let globalFileInput: any;
    const [form] = Form.useForm();
    const [checkUsername, setCheckUsername] = useState(false);
    const [type, setType] = useState('');
    const [file, setFile] = useState<Blob | null>(null);
    const { data: currentUser } = useCurrentUser();
    const [username, setUsername] = useState(currentUser?.name);
    const customNavigation = useCustomNavigate();
    const queryClient = useQueryClient();

    const { mutate: updateCurrentUser } = useUpdateCurrentUser(currentUser?._id, (user) => {
        queryClient.refetchQueries('current-user').then(() => {
            customNavigation.replace(`/${user?.name}`);
        });
    }, () => {

    }, {  });

    const { isLoading } = useCheckUsernameAvailability(username, (data: User) => {
        setType('taken');
        setCheckUsername(false);
    }, (error: AxiosError) => {
        setType('available');
        setCheckUsername(false)
    }, { enabled: (checkUsername), retry: false });

    const onCheckAction = () => {
        if (form.getFieldValue('username')) {
            setUsername(form.getFieldValue('username'));
            setCheckUsername(true);
        }
    };

    const getCorrectCheckButton = (type: string) => {
        switch (type) {
            case 'available':
                return <Button size="large" style={{ background: 'green', color: 'white' }} className={styles.available} onClick={onCheckAction}>Available</Button>;
            case 'taken':
                return <Button size="large" style={{ background: 'red', color: 'white' }} className={styles.taken} onClick={onCheckAction}>Taken</Button>;
            default:
                return <Button size="large" loading={isLoading} className={styles.check} onClick={onCheckAction}>Check</Button>;
        }
    };

    const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setType('');
        setUsername(event.target.value?.toLowerCase());
    };

    const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const menu = () => (
        <Menu>
            <Menu.Item key="1">
                <input
                    style={{ display: 'none' }}
                    type="file"
                    name="avatar"
                    onChange={fileSelectedHandler}
                    ref={(fileInput) => globalFileInput = fileInput}
                    accept=".jpeg,.png"
                />
                <div role="button" tabIndex={0} onClick={() => globalFileInput.click()}>Upload New Photo</div>
            </Menu.Item>
        </Menu>
    );

    const onFinish = (values: any) => {
        const data = new FormData();
        let dataChanged = false;

        if (values.name !== currentUser?.name) {
            data.append('name', values.displayName);
            dataChanged = true;
        }

        if (file != null) {
            data.append('avatar', file, 'temp');
            dataChanged = true;
        }

        if (dataChanged) {
            updateCurrentUser(data);
        }
    };

    if (!currentUser) {
        return <div />
    }

    return (
        currentUser
        && (
            <div className={styles.ProfileEditScreen}>
                <div className={styles.container}>
                    <div className={styles.tabContainer}>
                        <Tabs tabBarGutter={0} defaultActiveKey="Edit Profile">
                            <TabPane tab={<TabTitle text="Edit Profile" />} key="Edit Profile">
                                <div className={styles.editContainer}>
                                    <div className={styles.header}>
                                        <div className={styles.imageContainer}>
                                            <img
                                                className={styles.image}
                                                src={file ? URL.createObjectURL(file) : currentUser.image}
                                                alt={currentUser.image}
                                            />
                                            <Dropdown overlay={menu} trigger={['click']}>
                                                <Button type="link">Change profile image</Button>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className={styles.body}>
                                        <Form
                                            layout="vertical"
                                            form={form}
                                            className="login-form"
                                            onFinish={onFinish}
                                            //onFinishFailed={onFinishFailed}
                                        >
                                            <Form.Item
                                                label="Display Name"
                                                name="displayName"
                                                rules={[{ required: true, message: 'Please input your name!' }]}
                                                initialValue={currentUser.name}
                                            >
                                                <Input
                                                    prefix={<UserOutlined className={styles.iconColor} />}
                                                    placeholder="Display Name"
                                                    size="large"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className={styles.username}
                                                label="Username"
                                                name="username"
                                                rules={[{
                                                    required: true, message: 'Please input your username!', min: 3, max: 20, transform: (value) => value.toLowerCase(),
                                                }]}
                                                initialValue={currentUser?.name}
                                            >
                                                <div className={styles.usernameContainer}>
                                                    <Input
                                                        prefix={
                                                            <UserOutlined
                                                                className={styles.iconColor}
                                                                //style={{ color: 'rgba(0,0,0,.25)' }}
                                                            />}
                                                        placeholder="Username"
                                                        defaultValue={currentUser.name}
                                                        value={username}
                                                        onChange={onUsernameChange}
                                                        size="large"
                                                    />
                                                    {
                                                        getCorrectCheckButton(type)
                                                    }
                                                </div>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" size="large" htmlType="submit" className="login-form-button">
                                                    Save
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </TabPane>
                            {/*<TabPane tab={<TabTitle text="Privacy" />} key="Privacy">*/}
                            {/*    <div className={styles.privateContainer}>*/}
                            {/*        <div className={styles.privateTitle}>*/}
                            {/*            Account Privacy*/}
                            {/*        </div>*/}
                            {/*        <Checkbox checked={privateAccount} className={styles.privacyCheckbox} onChange={onPrivacyChange}><span className={styles.privacyCheckboxTitle}>Private Account</span></Checkbox>*/}
                            {/*        <div className={styles.privacySubtitle}>*/}
                            {/*            When your account is private, only people you approve can see your posts on GoodLores. Your existing followers won&apos;t be affected.*/}
                            {/*        </div>*/}
                            {/*        <div className={styles.savePrivateData}>*/}
                            {/*            <Button className={styles.savePrivateButton} size="large" type="primary" onClick={onSavePrivateClick}>*/}
                            {/*                Save*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</TabPane>*/}
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    );
}

export default ProfileEditScreen;