import React, {useState} from 'react';
import styles from './list-calendar-screen.module.scss';
import ListHeader from "../../../components/list-header/list-header";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {TimeStamps} from "../../../models/TimeStamps";
import {useCurrentWorkspace} from "../../app-hooks";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import ListLayout from "../../../layouts/list-layout/list-layout";
import {Calendar} from "../../../models/Calendar";
import CalendarItem from "./calendar-item/calendar-item";

function ListCalendarScreen() {

    const { workspace } = useParams();
    const customNavigate = useCustomNavigate();
    const [search] = useState('');
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [limit] = useState(12);
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace })

    const {
        data: allCalendars,
        isLoading,
        fetchNextPage,
    } = useInfiniteQuery(
        ['calendars', limit, timeStamps.CALENDARS],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ calendars: Calendar[], nextId: number }>(`/api/calendars/workspaces/${currentWorkspace?._id}?limit=${limit}&page=${pageParam}&timestamp=${timeStamps.CALENDARS}&search=${search}`);
            if (data?.calendars?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            enabled: !!currentWorkspace?._id,
            getNextPageParam: (lastPage) => lastPage.nextId,
        },
    );

    const onCalendarClick = (calendar: Calendar) => {
        customNavigate.push(`/${workspace}/calendars/${calendar._id}`);
    }

    const calendars = allCalendars?.pages.map((page) => page.calendars).flat()

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/calendars`);
    };

    return (
        <>
            <ListHeader
                title={"Calendars"}
                onBackClick={onBackClick}
            />
            <ListLayout
                isFetching={isLoading}
                data={calendars}
                fetchNextPage={fetchNextPage}
                isAtTheEnd={isAtTheEnd}
                skeletonCardHeight={60}
            >
                <div className={styles.ListCalendarScreen}>
                    <div className={styles.selectCalendar}>
                        select a calendar
                    </div>
                    {
                        calendars?.map((c, index) => {
                            return <CalendarItem key={c._id} calendar={c} onCalendarClick={onCalendarClick} />
                        })
                    }
                </div>
            </ListLayout>
        </>
    );
}

export default ListCalendarScreen;