import {useQuery, useQueryClient, UseQueryOptions} from "react-query";
import axios, {AxiosError} from "axios";
import {User} from "../models/User";
import cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../models/StoreState";
import {ActionTypes} from "../actions/types";
import {Workspace} from "../models/Workspace";
import {useCustomNavigate} from "../hooks/navigation";
import {TimeStamps} from "../models/TimeStamps";

export const useCurrentUser = (options?: UseQueryOptions<User, AxiosError, User>) => {
    const customNavigation = useCustomNavigate();
    const queryClient = useQueryClient();
    // if (queryClient.getQueryCache().find('current-user')?.state?.data) {
    //     return queryClient.getQueryCache().find('current-user')?.state;
    // }

    return useQuery<User, AxiosError, User>(['current-user'], async () => {
            const res = await axios.get<User>('/api/users/current');
            return res.data;
        },
        {
            ...options,
            // staleTime: 10000,
            staleTime: Infinity,
            enabled: !!cookies.get('navyflow-token'),
            onSuccess: data => {
            }, onError: error => {
                if (error.response && error.response.status === 401) {
                    if (cookies.get('navyflow-token')) {
                        console.log("bruuuu")
                        // cookies.remove('navyflow-token');
                        // customNavigation.replace('/');
                        // window.location.reload();
                    }
                }
            },
        })
};

export const usePing = (options?: UseQueryOptions<string | undefined, AxiosError, string | undefined>) => {
    return useQuery<string | undefined, AxiosError, string | undefined>('ping', async () => {
            const res = await axios.get<string>('/api/ping');
            return res.config.baseURL;
        },
        {
            ...options,
            onSuccess: data => {
            }, onError: error => {
                if (error.response && error.response.status === 401) {
                    //console.log("useCurrentUser - well done: ",error);
                    //cookies.remove('theswsh-token');
                    //window.location.reload();
                }
            },
        })
};

export const useCurrentWorkspace = (workspace: string | undefined, options?: UseQueryOptions<Workspace, AxiosError, Workspace>) => {
    return useQuery<Workspace, AxiosError, Workspace>(['current-workspace', workspace], async () => {
        const res = await axios.get<Workspace>(`/api/workspaces/name/${workspace}`);
            return res.data;
        },
        {
            ...options,
            // staleTime: 10000,
            onSuccess: data => {
            }, onError: error => {
            },
        })
};

const invalidateChats = async (dispatch: any, queryClient: any) => {
    // await queryClient.invalidateQueries('chats-screen');
    // await queryClient.invalidateQueries('messages');
    // const newTimeStamp = new Date();
    // newTimeStamp.setSeconds(newTimeStamp.getSeconds() + 1000);
    // dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: { 'chats-screen': newTimeStamp } });
}

export const useTimeStamps = (key: string) => {
    const timeStamps = useSelector<StoreState, TimeStamps>(state => state.timeStamps);

    if (key === 'METRICS') {
        return timeStamps.METRICS;
    }

    if (key === 'METRIC-ITEMS') {
        return timeStamps.METRIC_ITEMS;
    }

    if (key === 'WORKSPACES') {
        return timeStamps.WORKSPACES;
    }

    const newDate = new Date();
    return newDate;
}