import React, {useState} from 'react';
import styles from './calendar-tags-tab.module.scss';
import {Button} from "antd";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {MetricItem} from "../../../../models/MetricItem";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {TimeStamps} from "../../../../models/TimeStamps";
import ListLayout from "../../../../layouts/list-layout/list-layout";
import {CalendarTag} from "../../../../models/CalendarTag";
import CalendarTagItem from "./calendar-tag-item/calendar-tag-item";

function CalendarTagsTab() {

    const { calendarId } = useParams();
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [limit] = useState(12);
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const customNavigation = useCustomNavigate();

    const {
        data: calendarTagsPaginated,
        fetchNextPage,
        isLoading,
    } = useInfiniteQuery(
        ['calendar-tags', calendarId],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ calendarTags: CalendarTag[], nextId: number }>(`/api/calendar-tags/calendars/${calendarId}?limit=${limit}&page=${pageParam}&timestamp=${timeStamps.CALENDAR_TAGS}`);
            if (data?.calendarTags?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!calendarId,
            keepPreviousData: true
        },
    );

    const onCreateNewCalendarTagClick = () => {
        customNavigation.push('tags/create');
    };

    const onCalendarTagItemClick = (calendarTag: CalendarTag) => {
        customNavigation.push(`tags/${calendarTag._id}`);
    }

    const calendarTags = calendarTagsPaginated?.pages.map((page) => page.calendarTags).flat();

    return (
        <div className={styles.CalendarTagsTab}>
            <div className={styles.addCalendarTagContainer}>
                <Button
                    type="dashed"
                    size="large"
                    className={styles.addCalendarTagButton}
                    onClick={onCreateNewCalendarTagClick}
                >
                    ADD CALENDAR TAG
                </Button>
            </div>
            <ListLayout
                isFetching={isLoading}
                data={calendarTags}
                fetchNextPage={fetchNextPage}
                isAtTheEnd={isAtTheEnd}
                skeletonCardHeight={60}
            >
                <>
                    {
                        calendarTags?.map((val, index) => {
                            return <CalendarTagItem
                                key={`${index}`}
                                calendarTag={val}
                                onCalendarTagItemClick={onCalendarTagItemClick}
                            />
                        })
                    }
                </>
            </ListLayout>
        </div>
    );
}

export default CalendarTagsTab;