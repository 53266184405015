import {useQuery, UseQueryOptions} from "react-query";
import axios, {AxiosError} from "axios";
import {Calendar} from "../../../models/Calendar";

export const useCalendar = (calendarId: string, options?: UseQueryOptions<Calendar, AxiosError, Calendar>) => {
    return useQuery<Calendar, AxiosError, Calendar>(['calendar', calendarId], async () => {
            const res = await axios.get<Calendar>(`/api/calendars/${calendarId}`);
            return res.data;
        },
        {
            ...options,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};