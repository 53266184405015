import React from 'react';
import styles from './view-metric-item-screen.module.scss';
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {useCustomNavigate} from "../../../hooks/navigation";
import {Form} from "antd";
import {useMetricItem} from "./view-metric-item-hooks";
import {useParams} from "react-router-dom";

function ViewMetricItemScreen() {

    const { workspace, metricId, metricItemId } = useParams();
    const customNavigation = useCustomNavigate();
    const { data: metricItem } = useMetricItem(metricItemId!, { enabled: !!metricItemId });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/metrics/${metricId}`);
    };

    const onEditClick = () => {
        customNavigation.push(`/${workspace}/metrics/${metricId}/metric-items/${metricItemId}/edit`);
    };

    return (
        <CreateLayout
            title={`View Metric Item`}
            formId={'view-metric-item'}
            onBackClick={onBackClick}
            isLoading={false}
            showSaveButton={false}
            editButtonTitle={'Edit'}
            onEditClick={onEditClick}
        >
            <>
                {
                    metricItem &&
                    <Form
                        layout="vertical"
                        className={styles.ViewMetricItemScreen}
                    >
                        <Form.Item
                            label="Value"
                        >
                            <div className={styles.value}>
                                {metricItem?.numericValue}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="Date"
                        >
                            <div className={styles.value}>
                                {new Date(metricItem.timeStamp).toLocaleString(undefined, {
                                    day: 'numeric', month: 'short', year: 'numeric'
                                })}
                            </div>
                        </Form.Item>
                        {
                            metricItem?.description &&
                            <Form.Item
                                label="Description"
                                name="description"
                            >
                                <div className={styles.value}>
                                    {metricItem?.description}
                                </div>
                            </Form.Item>
                        }
                    </Form>
                }
            </>
        </CreateLayout>
    );
}

export default ViewMetricItemScreen;