import {CalendarEvent} from "../../../../models/CalendarEvent";
import {DateTime} from "luxon";

const updateYear = (date: string, year: number): Date => {
    let tempDate = DateTime.fromJSDate(new Date(date));
    tempDate = tempDate.set({ year });
    return tempDate.toJSDate();
}

const updateYearMonth = (date: string, year: number, month: number): Date => {
    let tempDate = DateTime.fromJSDate(new Date(date));
    tempDate = tempDate.set({ year, month });
    return tempDate.toJSDate();
}

const updateYearMonthDay = (date: string, year: number, month: number, day: number): Date => {
    let tempDate = DateTime.fromJSDate(new Date(date));
    tempDate = tempDate.set({ year, month, day });
    return tempDate.toJSDate();
}

export const getConvertedCalendarEvent = (calendarEvent: CalendarEvent | undefined) => {
    if (calendarEvent) {
        return {...calendarEvent, startDate: new Date(calendarEvent.startDate), endDate: calendarEvent.endDate ? new Date(calendarEvent.endDate): undefined };
    }
}