import moment, {Moment} from 'moment';
import currencies from "./currencies.json";

export const convertTimeToDaysAgo = (time: string) => {
    const now = moment();
    const days = now.diff(moment(time), 'days');
    let displayTime = days;
    let displayTimeExt = 'days ago';
    const hours = now.diff(moment(time), 'hours');
    const minutes = now.diff(moment(time), 'minutes');

    if (days === 0) {
        displayTime = hours;
        displayTimeExt = 'hrs ago';
        if (hours === 0) {
            displayTime = minutes;
            displayTimeExt = 'min ago';
        } else if (hours === 1) {
            displayTimeExt = 'hr ago';
        }
    } else if (days === 1) {
        displayTimeExt = 'day ago';
    }
    return `${displayTime} ${displayTimeExt}`;
};

export const arrayOfCurrencies = Object.entries(currencies).map((currency) => {
    const key = currency[0];
    const value = currency[1];
    return {
        key: key,
        value: value.name
    }
});

const formatTimeNumber = (time: number): string => {
    if (time < 10) {
        return `0${time}`;
    }
    return `${time}`;
};

export const formatTime = (time: Moment) => {
    return `${formatTimeNumber(time.hours())}:${formatTimeNumber(time.minute())}`;
}

export const getFilteredDate = (date: Date, recurring: 'YEARLY' | 'MONTHLY' | 'WEEKLY' | 'DAILY' | undefined) => {

    if (recurring === 'YEARLY') {
        return {
            minute: -1,
            hour: -1,
            weekDay: -1,
            day: date.getUTCDate(),
            month: date.getUTCMonth() + 1,
            year: -1,
        }
    }

    if (recurring === 'MONTHLY') {
        return {
            minute: -1,
            hour: -1,
            day: -1,
            date: date.getUTCDate(),
            month: -1,
            year: -1,
        }
    }

    if (recurring === 'WEEKLY') {
        return {
            minute: -1,
            hour: -1,
            weekDay: date.getUTCDay() + 1,
            day: -1,
            month: -1,
            year: -1,
        }
    }

    if (recurring === 'DAILY') {
        return {
            minute: date.getUTCMinutes(),
            hour: date.getUTCHours(),
            weekDay: -1,
            day: -1,
            month: -1,
            year: -1,
        }
    }

    return {
        minute: date.getUTCMinutes(),
        hour: date.getUTCHours(),
        weekDay: date.getUTCDay(),
        day: date.getUTCDate(),
        month: date.getUTCMonth(),
        year: date.getUTCFullYear(),
    }
}

export const urlFormattedDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const dateOfMonth = date.getDate();
    return `${year}-${month < 10 ? `0${month}`: month}-${dateOfMonth < 10 ? `0${dateOfMonth}` : dateOfMonth}`;
}