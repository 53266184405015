import React from 'react';
import styles from './metric-children-item.module.scss';
import {Metric} from "../../../../../models/Metric";
import {Button} from "antd";
import {getCurrency, getDisplayFields} from "../../../list-metrics-screen/metrcis-item/metrics-item-helper";

type MetricChildrenItemProps = {
    metric: Metric;
    parentMetric: Metric;
    onMetricClick?: (metricId: string) => void;
    loading?: boolean;
    activeMetricId?: string;
}

function MetricChildrenItem(props: MetricChildrenItemProps) {
    const { metric, onMetricClick, loading, activeMetricId, parentMetric } = props;
    const { _id } = metric;

    const { value } = getDisplayFields(metric);

    return (
        <div className={styles.MetricChildrenItem}>
            <div className={styles.leftContent}>
                <div className={styles.numericValue}>
                    <span>{parentMetric.unit === 'CURRENCY' && getCurrency(parentMetric.currency)}</span>
                    <span>{value}</span>
                </div>
                <div className={styles.description}>
                    {metric.title}
                </div>
            </div>
            <div className={styles.rightContent}>
                {
                    onMetricClick &&
                    <Button
                        size="large"
                        type="primary"
                        onClick={() => onMetricClick(_id)}
                        disabled={loading === true && _id !== activeMetricId}
                        loading={_id === activeMetricId && loading}
                    >
                        ADD
                    </Button>
                }
            </div>
        </div>
    );
}

export default MetricChildrenItem;