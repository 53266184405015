import {ActionTypes} from "../actions/types";
import {CalendarFilters} from "../models/CalendarFilters";

interface setCalendarFilters {
    type: typeof ActionTypes.SET_CALENDAR_FILTERS | typeof ActionTypes.UPDATE_CALENDAR_FILTERS;
    value: CalendarFilters;
}

type filterActions = setCalendarFilters;

export const calendarFilters = (state: CalendarFilters = { startDate: new Date() }, action: filterActions) => {
    if (action.type === ActionTypes.SET_CALENDAR_FILTERS) {
        return action.value;
    }
    if (action.type === ActionTypes.UPDATE_CALENDAR_FILTERS) {
        return {...state, ...action.value};
    }
    return state;
};