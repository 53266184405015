import React from 'react';
import styles from './metrics-item.module.scss';
import {Metric} from "../../../../models/Metric";
// @ts-ignore
import { Textfit } from 'react-textfit';
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {convertTimeToDaysAgo} from "../../../../utils/utils";
import {MetricItem} from "../../../../models/MetricItem";
import currencies from '../../../../utils/currencies.json';
import {getCurrency, getDisplayFields} from "./metrics-item-helper";

type MetricsItemProps = {
    metric: Metric;
    onMetricClick: (metric: Metric) => void;
}

function MetricsItem(props: MetricsItemProps) {
    const { metric, onMetricClick } = props;
    const { objective, type } = metric;

    const { difference, timeStamp, value } = getDisplayFields(metric);

    const getDownOrUp = () => {
        if (difference < 0) {
            return <CaretDownOutlined />
        }
        return <CaretUpOutlined />
    }

    const getCorrectDifferenceStyles = () => {
        if (['INCREASE', 'CONSTANT', 'INCREASE_CONSTANT'].includes(objective)) {

            if (difference < 0) {
                return styles.negativeDifference;
            }
            return styles.positiveDifference;
        }

        if (['DECREASE', 'DECREASE_CONSTANT'].includes(objective)) {
            if (difference < 0) {
                return styles.positiveDifference;
            }
            return styles.negativeDifference;
        }
    }



    const renderCorrectSumDifferenceComponent = () => {
        if (metric.lastMetricItem && type !== "METRICS_SUM") {
            return <div className={getCorrectDifferenceStyles()}>
                    <span>
                        {getDownOrUp()}
                    </span>
                    <span>
                        {difference}
                    </span>
            </div>
        }

        if (metric.lastMetric &&
            Object.keys(metric.lastMetric).length !== 0 &&
            (metric.lastMetric as Metric).lastMetricItem &&
            type === "METRICS_SUM") {
            return <div className={getCorrectDifferenceStyles()}>
                    <span>
                        {getDownOrUp()}
                    </span>
                <span>
                        {difference}
                    </span>
            </div>
        }
    }



    return (
        <div className={styles.MetricsItem} onClick={() => onMetricClick(metric)}>
            <div className={styles.title}>
                {metric.title}
            </div>
            <Textfit className={styles.value} mode="single" min={8}>
                {metric.unit === 'CURRENCY' && getCurrency(metric.currency)}
                {value}
            </Textfit>
            {
                renderCorrectSumDifferenceComponent()
            }
            <div className={styles.timestamp}>
                {convertTimeToDaysAgo(timeStamp)}
            </div>
        </div>
    );
}

export default MetricsItem;