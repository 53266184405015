import React, {useState} from 'react';
import styles from './edit-metric-item-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useMetricItem} from "../view-metric-item-screen/view-metric-item-hooks";
import {DatePicker, Form, Input, InputNumber} from "antd";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import moment from "moment";
import {useUpdateMetricItem} from "./edit-metric-item-hooks";
import {useQueryClient} from "react-query";

function EditMetricItemScreen() {

    const { workspace, metricId, metricItemId } = useParams();
    const customNavigation = useCustomNavigate();
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { data: metricItem } = useMetricItem(metricItemId!, { enabled: !!metricItemId });

    const { mutate: updateMetricItem, isLoading } = useUpdateMetricItem(metricItemId!, (metricItem) => {
        queryClient.invalidateQueries(['metric-items', 'metric-item', metricItemId]).then(() => {
            onBackClick();
        });
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        setErrorMessage(defaultMessage);
    }, {  });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/metrics/${metricId}/metric-items/${metricItemId}`);
    };

    const onFinish = (values: any) => {
        const data = {
            numericValue: values.value,
            description: values.description,
            timeStamp: new Date(values.date._d)
        }
        updateMetricItem(data);
    };

    const onFinishFailed = () => {

    };

    const formId = "edit-metric-item";
    return (
        <CreateLayout
            title={`Edit Metric Item`}
            formId={'edit-metric-item'}
            onBackClick={onBackClick}
            isLoading={isLoading}
            showSaveButton={true}
            errorMessage={errorMessage}
        >
            <>
                {
                    metricItem &&
                    <Form
                        layout="vertical"
                        className={styles.EditMetricItemScreen}
                        id={formId}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Value"
                            name="value"
                            required={true}
                            rules={[{ required: true, message: "Please select value" }]}
                            initialValue={metricItem?.numericValue}
                        >
                            <InputNumber
                                className={styles.valueInputNumber}
                                // formatter={value => `${getCurrency(metric?.currency ?? '')} ${value}`}
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Date"
                            name="date"
                            required={true}
                            initialValue={moment(metricItem.timeStamp)}
                            rules={[{ required: true, message: "Please select date"}]}
                        >
                            <DatePicker size="large" />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            initialValue={metricItem?.description}
                        >
                            <Input
                                className={styles.description}
                                size="large"
                                placeholder="Enter description..."
                                allowClear
                            />
                        </Form.Item>
                    </Form>
                }
            </>
        </CreateLayout>
    );
}

export default EditMetricItemScreen;