import React from 'react';
import styles from './logo.module.scss';
import {useDispatch} from "react-redux";
import {ActionTypes} from "../../actions/types";
import {useCustomNavigate} from "../../hooks/navigation";

type LogoProps = {
    title: string
};

function Logo(props: LogoProps) {
    const { title } = props;
    const customNavigation = useCustomNavigate();
    const dispatch = useDispatch();

    const navigateToHome = () => {
        if (title === 'NAVYFLOW') {
            customNavigation.replace('/');
            dispatch({ type: ActionTypes.SET_MENU_ITEM, location: 'home' });
            dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: 'home' });
        }
    };

    return (
        <div className={styles.Logo} onClick={navigateToHome}>
            {title}
        </div>
    );
}

export default Logo;
