import React, { useState } from 'react';
import {
  Button, Form, Input, Modal,
} from 'antd';
import {
  FacebookFilled, TwitterOutlined, UserOutlined, EyeInvisibleOutlined, GoogleOutlined, MailFilled, CloseOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../models/StoreState';
import styles from './auth-modal.module.scss';
import { ActionTypes } from '../../actions/types';
import { access, loginWithEmail, signupWithEmail } from '../../services/authService';

function AuthModal() {
  const [form] = Form.useForm();
  const authModal = useSelector<StoreState, any>((state) => state.authModal);
  const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
  const [socialAuth, setSocialAuth] = useState(false);
  const [isEmailAuth, setEmailAuth] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch({ type: ActionTypes.TOGGLE_AUTH_MODAL });
  };

  const authWithSocial = (social: string) => {
    document.location.href = `${window.location.origin}/api/auth/${authModal.authType}/${social}`;
  };

  const authWithEmail = () => {
    setEmailAuth(true);
  };

  const onFinish = (values: any) => {
    dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });

    if (isEmailAuth) {
      if (authModal.authType === 'login') {
        dispatch(loginWithEmail(values.email as string, () => {
          Modal.success({
            content: 'Thank you! An email was sent your email address.',
          });
        }));
      } else {
        dispatch(signupWithEmail(values.name as string, values.email as string, () => {
          Modal.success({
            content: 'Thank you! An email was sent your email address.',
          });
        }));
      }
    } else {
      dispatch(access(values.username as string, values.access as string, () => {
        setSocialAuth(true);
      }));
    }
  };

  const onFinishFailed = () => {
  };

  const getCorrectForm = () => {
    if (isEmailAuth) {
      return (
        <div>
          <Form
            layout="vertical"
            form={form}
            className="login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {
              authModal.authType !== 'login'
              && (
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!', min: 3 }]}
              >
                <Input size="large" placeholder="Please enter your name" allowClear />
              </Form.Item>
              )
            }
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
            >
              <Input size="large" placeholder="Please enter your email" allowClear />
            </Form.Item>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      );
    }
    return (
      <Form
        className="login-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/*<Form.Item>*/}
        {/*  <Button*/}
        {/*    className={styles.facebookButton}*/}
        {/*    onClick={() => authWithSocial('facebook')}*/}
        {/*  >*/}
        {/*    <FacebookFilled className={styles.facebookIcon} type="facebook" />*/}
        {/*    {authModal.authType === 'login' ? 'Login' : 'Sign up'}*/}
        {/*    {' '}*/}
        {/*    with Facebook*/}
        {/*  </Button>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item>*/}
        {/*  <Button*/}
        {/*    className={styles.twitterButton}*/}
        {/*    onClick={() => authWithSocial('twitter')}*/}
        {/*  >*/}
        {/*    <TwitterOutlined className={styles.twitterIcon} type="twitter" />*/}
        {/*    {authModal.authType === 'login' ? 'Login' : 'Sign up'}*/}
        {/*    {' '}*/}
        {/*    with Twitter*/}
        {/*  </Button>*/}
        {/*</Form.Item>*/}
        <Form.Item>
          <Button
            className={styles.googleButton}
            onClick={() => authWithSocial('google')}
          >
            <GoogleOutlined className={styles.googleIcon} type="google" />
            {authModal.authType === 'login' ? 'Login' : 'Sign up'}
            {' '}
            with Google
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.mailButton}
            onClick={authWithEmail}
          >
            <MailFilled className={styles.mailIcon} />
            {authModal.authType === 'login' ? 'Login' : 'Sign up'}
            {' '}
            with Email
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    socialAuth || authModal.authType === 'login'
      ? (
        <Modal
          style={{ background: 'var(--main-background-color)' }}
          className={styles.AuthModal}
          visible={authModal.visible}
          onCancel={handleCancel}
          centered
          title={<div className={styles.modalTitle}>{authModal.authType}</div>}
          footer={null}
          closeIcon={<CloseOutlined className={styles.modalCloseIcon} />}
        >
          <div className={styles.modalBody}>
            {
              getCorrectForm()
            }
          </div>
        </Modal>
      ) : (
        <Modal
          style={{ background: 'var(--main-background-color)' }}
          className={styles.AuthModal}
          visible={authModal.visible}
          onCancel={handleCancel}
          centered
          title={<div className={styles.modalTitle}>{authModal.authType}</div>}
          footer={null}
          closeIcon={<CloseOutlined className={styles.modalCloseIcon} />}
        >
          <div className={styles.modalBody}>
            <div>
              {
                authModal.authType !== 'login'
                    && (
                    <div className={styles.privateBeta}>
                      We are currently in private beta
                    </div>
                    )
              }
            </div>
            <Form
              className="login-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
                style={{ background: 'transparent' }}
              >
                <Input
                  prefix={<UserOutlined type="user" className={styles.modalIcon} />}
                  placeholder="Username"
                  size="large"
                  style={{ background: 'transparent' }}
                  className={styles.modalInput}
                />
              </Form.Item>
              <Form.Item
                name="access"
                rules={[{ required: true, message: 'Please enter your access code!' }]}
                style={{ background: 'transparent' }}
              >
                <Input
                  prefix={<EyeInvisibleOutlined className={styles.modalIcon} />}
                  type="password"
                  placeholder="Access code"
                  size="large"
                  style={{ background: 'transparent' }}
                  className={styles.modalInput}
                />
              </Form.Item>
              {
                authModal.authType === 'login' ? (
                  <Button type="primary" htmlType="submit" className={styles.loginButton} loading={isLoading}>
                    Log in
                  </Button>
                )
                  : (
                    <Button type="primary" htmlType="submit" className={styles.signupButton} loading={isLoading}>
                      Sign up
                    </Button>
                  )
              }
            </Form>
          </div>
        </Modal>
      )
  );
}

export default AuthModal;
