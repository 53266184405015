import React from 'react';
import styles from './create-layout.module.scss';
import {Alert, Button, Form} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";

type CreateLayoutProps = {
    children: JSX.Element,
    onBackClick: () => void;
    title: string;
    isLoading: boolean;
    formId: string;
    errorMessage?: string;
    editButtonTitle?: string;
    onEditClick?: () => void;
    showSaveButton: boolean;
};

function CreateLayout(props: CreateLayoutProps) {
    const { children, onBackClick, title, isLoading, formId, errorMessage, editButtonTitle, showSaveButton, onEditClick } = props;

    return (
        <div className={styles.CreateLayout}>
            <div className={styles.header}>
                <Button
                    className={styles.goBack}
                    type="link"
                    icon={<ArrowLeftOutlined />}
                    onClick={onBackClick}
                />
                <div className={styles.name}>
                    {title}
                </div>
                <div className={styles.rightHeaderContent}>
                    {
                        editButtonTitle &&
                        <Button size="large" onClick={onEditClick}>
                            {editButtonTitle}
                        </Button>
                    }
                </div>
            </div>
            <div className={styles.children}>
                {children}
            </div>
            {
                errorMessage &&
                <Alert message={errorMessage} type="error" />
            }
            <div className={styles.footer}>
                {
                    showSaveButton &&
                    <Form.Item>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            form={formId}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </Form.Item>
                }
            </div>
        </div>
    );
}

export default CreateLayout;