import React from 'react';
import styles from './view-calendar-event-screen.module.scss';
import {useLocation, useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {
    useCalendarEvent,
    useCreateCalendarCompletedEvent
} from "./view-calendar-event-hooks";
import {Button, Checkbox, Form, Tag} from "antd";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {useCurrentUser, useCurrentWorkspace} from "../../app-hooks";
import {useQueryClient} from "react-query";
import {urlFormattedDate} from "../../../utils/utils";
import queryString from 'query-string';

function ViewCalendarEventScreen() {

    const { workspace, eventId } = useParams();
    const location = useLocation();
    const search = location.search;
    const currentParams: { date?: string } = queryString.parse(search);
    const customNavigate = useCustomNavigate();
    const queryClient = useQueryClient();
    const { data: calendarEvent } = useCalendarEvent(eventId!, { enabled: !!eventId });
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace });
    const { data: currentUser } = useCurrentUser({});
    console.log("calendarEvent: ",calendarEvent);
    const calendars = calendarEvent?.calendars;
    const calendarTags = calendarEvent?.tags;
    const completedEvents = calendarEvent?.completedEvents;
    const calendarRecurringEvent = calendarEvent?.calendarRecurringEvent;

    const { mutate: createCalendarCompletedEvent, isLoading } = useCreateCalendarCompletedEvent(currentWorkspace?._id, (calendarCompletedEvent) => {
        queryClient.invalidateQueries(['calendarEvent', eventId]);
    }, (error) => {
        console.log("error: ",error.response);
    }, { });

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/calendars`);
    };

    const onEditClick = () => {
        customNavigate.push(`/${workspace}/calendars/events/${calendarEvent?._id}/edit`);
    };

    const formatShortDateHeader = (dateAsStr: string, allDay: boolean) => {
        const date = new Date(dateAsStr+" ");

        if (!allDay) {
            return date.toLocaleString(undefined, {
                day: 'numeric', weekday: 'long', month: 'short', year: "numeric", hour: "numeric", minute: "numeric"
            })
        }

        return date.toLocaleString(undefined, {
            day: 'numeric', weekday: 'long', month: 'short', year: "numeric"
        })
    }

    const formatDateHeader = (dateAsStr: string, allDay: boolean) => {
        const date = new Date(dateAsStr);

        if (!allDay) {
            return date.toLocaleString(undefined, {
                day: 'numeric', weekday: 'long', month: 'short', year: "numeric", hour: "numeric", minute: "numeric"
            })
        }

        return date.toLocaleString(undefined, {
            day: 'numeric', weekday: 'long', month: 'short', year: "numeric"
        })
    }

    const onCompletedClick = () => {
        if (calendarEvent?.startDate) {
            const currDate = currentParams.date ? new Date(currentParams.date+" ").toUTCString() : new Date(calendarEvent?.startDate).toUTCString();
            createCalendarCompletedEvent({ calendarEvent: calendarEvent?._id, date: currDate });
        }
    }

    const isAlreadyCompleted = () => {
        if (currentParams.date && calendarEvent?.recurring) {
            const completedEvent = completedEvents?.find((ce) => (urlFormattedDate(new Date(ce.date)) === urlFormattedDate(new Date(currentParams.date+" ")) && ce.createdBy === currentUser?._id));
            return !!completedEvent
        }
        const completedEvent = completedEvents?.find((ce) => (urlFormattedDate(new Date(ce.date)) === urlFormattedDate(new Date()) && ce.createdBy === currentUser?._id));
        return !!completedEvent
    }

    return (
        <CreateLayout
            title={`Calendar Event`}
            formId={'calendar-event'}
            onBackClick={onBackClick}
            isLoading={false}
            showSaveButton={false}
            editButtonTitle={'Edit'}
            onEditClick={onEditClick}
        >
            <>
                {
                    calendarEvent &&
                    <Form
                        layout="vertical"
                        className={styles.ViewCalendarEventScreen}
                    >
                        <Form.Item
                            label={"Title"}
                        >
                            <div className={styles.value}>
                                {calendarEvent?.title}
                            </div>
                        </Form.Item>
                        {
                            calendarEvent?.description &&
                            <Form.Item
                                label={"Description"}
                            >
                                <div className={styles.value}>
                                    {calendarEvent?.description}
                                </div>
                            </Form.Item>
                        }
                        {
                            calendarEvent?.allDay &&
                            <Form.Item
                            >
                                <Checkbox checked={true} >All Day</Checkbox>
                            </Form.Item>
                        }
                        {
                            calendarEvent?.recurring && currentParams.date &&
                            <Form.Item
                                label={"Event Date"}
                            >
                                {formatShortDateHeader(currentParams.date, calendarEvent.allDay)}
                            </Form.Item>
                        }
                        {
                            !!calendarRecurringEvent &&
                            <Form.Item
                                label={"Repeats"}
                            >
                                {calendarRecurringEvent?.rrule.text}
                            </Form.Item>
                        }
                        <Form.Item
                            label={"Start Date"}
                        >
                            {formatDateHeader(calendarEvent?.startDate as string, calendarEvent.allDay)}
                        </Form.Item>
                        {
                            calendarEvent?.endDate &&
                            <Form.Item
                                label={"End Date"}
                            >
                                {formatDateHeader(calendarEvent?.endDate, calendarEvent.allDay)}
                            </Form.Item>
                        }
                        <Form.Item
                            label={"Calendars"}
                        >
                            {
                                calendars?.map((c) => {
                                    return <Tag key={c._id}>{c.title}</Tag>
                                })
                            }
                        </Form.Item>
                        {
                            (calendarTags?.length ?? 0) > 0 &&
                            <Form.Item
                                label={"Tags"}
                            >
                                {
                                    calendarTags?.map((c) => {
                                        return <Tag key={c._id}>{c.name}</Tag>
                                    })
                                }
                            </Form.Item>
                        }
                        {
                            (completedEvents?.length ?? 0) > 0 &&
                            <Form.Item
                                label={"Completed"}
                                className={styles.completeContainer}
                            >
                                {
                                    completedEvents?.map((ce) => {
                                        return <Tag key={ce._id} color="green">{new Date(ce.date).toLocaleString(undefined, {
                                            day: 'numeric', month: 'short', year: 'numeric'
                                        })}</Tag>
                                    })
                                }
                            </Form.Item>
                        }
                        {
                            !isAlreadyCompleted() &&
                            <div
                                className={styles.completeContainer}
                            >
                                <Button
                                    className={styles.completeButton}
                                    size="large"
                                    type="primary"
                                    onClick={onCompletedClick}
                                    loading={isLoading}
                                >
                                    COMPLETE
                                </Button>
                            </div>
                        }
                    </Form>
                }
            </>
        </CreateLayout>
    );
}

export default ViewCalendarEventScreen;