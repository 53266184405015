import React from 'react';
import styles from './calendar-event-item.module.scss';
import {Tag} from "antd";
import {CalendarEvent} from "../../../../models/CalendarEvent";
import {getConvertedCalendarEvent} from "./calendar-vent-item-helper";
import {urlFormattedDate} from "../../../../utils/utils";

type CalendarEventItemProps = {
    calendarEvent: CalendarEvent;
    index: number;
    prevCalendarEvent?: CalendarEvent;
    nextCalendarEvent?: CalendarEvent;
    onCalendarEventClick: (calendarEvent: CalendarEvent, startDate: Date) => void;
    filterStartDate: Date;
    currentUserId?: string;
};

function CalendarEventItem(props: CalendarEventItemProps) {
    const { calendarEvent, index, onCalendarEventClick,
        nextCalendarEvent, prevCalendarEvent, filterStartDate, currentUserId } = props;
    const { calendars, tags, completedEvents } = calendarEvent;
    const { title, allDay, startDate, endDate } = getConvertedCalendarEvent(calendarEvent)!;
    const convertedPrevCalendarEvent = getConvertedCalendarEvent(prevCalendarEvent);

    const isAlreadyCompleted = () => {
        const completedEvent = completedEvents?.find((ce) => (urlFormattedDate(new Date(ce.utcDateWithNoTime)) === urlFormattedDate(startDate) && ce.createdBy === currentUserId));
        return !!completedEvent
    }

    const getDate = (date: string | Date | undefined) => {
        if (date) {
            return new Date(date).toLocaleString(undefined, {
                year: 'numeric', month: 'numeric', day: 'numeric'
            })
        }
    };

    const formatDate = (date: Date) => {

        const currentYear = new Date().getFullYear();

        if (currentYear !== date.getFullYear()) {
            return date.toLocaleString(undefined, {
                day: 'numeric', weekday: 'short', month: 'short', year: "numeric"
            })
        }

        return date.toLocaleString(undefined, {
            day: 'numeric', weekday: 'short', month: 'short'
        })
    }

    const formatDateHeader = (date: Date) => {
        if (date <= filterStartDate) {
            return date.toLocaleString(undefined, {
                day: 'numeric', weekday: 'short', month: 'short'
            })
        }

        const currentYear = new Date().getFullYear();

        if (currentYear !== date.getFullYear()) {
            return date.toLocaleString(undefined, {
                day: 'numeric', weekday: 'short', month: 'short', year: "numeric"
            })
        }

        return date.toLocaleString(undefined, {
            day: 'numeric', weekday: 'short', month: 'short'
        })
    }

    const getDateSpread = (startDate: Date, endDate?: Date) => {

        if (!endDate) {
            return formatDate(startDate);
        }

        if (formatDate(startDate) === formatDate(endDate)) {
            return formatDate(startDate);
        }
        return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }

    const shouldShowHeader = () => {
        return (getDate(startDate) !== getDate(prevCalendarEvent?.startDate));
    }

    const shouldShowNewYear = () => {
        return (startDate.getUTCFullYear() !== convertedPrevCalendarEvent?.startDate.getUTCFullYear());
    }

    return (
        <div className={styles.CalendarEventItem}>
            {
                index !== 0 && shouldShowNewYear() &&
                <div className={styles.startOfYear}>
                    🥳 START OF 2023 🥳
                </div>
            }
            {
                index === 0 &&
                <div className={styles.dateHeader}>
                    {formatDateHeader(startDate) === formatDateHeader(new Date()) && 'Today - '}
                    {formatDateHeader(startDate)}
                </div>
            }
            {
                index !== 0 &&
                (shouldShowHeader()) &&
                <div className={styles.dateHeader}>
                    {formatDate(startDate)}
                </div>
            }
            <div className={styles.event} onClick={() => onCalendarEventClick(calendarEvent, startDate)}>
                <div className={styles.eventHeader}>
                    <div>
                        {getDateSpread(startDate, endDate)}
                    </div>
                    {
                        isAlreadyCompleted() &&
                        <div>
                            <Tag color={'green'} className={styles.completedTag}>completed</Tag>
                        </div>
                    }
                </div>
                <div className={styles.eventBody}>
                    <div className={styles.leftContainer}>
                        <div className={styles.title}>
                            {title}
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        {
                            allDay ?
                                <div className={styles.time}>All day</div>
                                :
                                <div className={styles.time}>
                                    {new Date(startDate).toLocaleString(undefined, {
                                        hour: "numeric", minute: "numeric"
                                    })}
                                </div>
                        }
                    </div>
                </div>
                <div className={styles.eventFooter}>
                    <div className={styles.tags}>
                        {
                            tags.length > 0 &&
                            <Tag className={styles.leftTag} key={tags[0]._id}>{tags[0].name}</Tag>
                        }
                        {
                            tags.length > 1 &&
                            <Tag className={styles.leftTag} key={tags[1]._id}>+{tags.length - 1}</Tag>
                        }
                    </div>
                    <div className={styles.calendars}>
                        {
                            calendars.length > 0 &&
                            <Tag className={styles.rightTag} key={calendars[0]._id}>{calendars[0].title}</Tag>
                        }
                        {
                            calendars.length > 1 &&
                            <Tag className={styles.rightTag} key={calendars[1]._id}>+{calendars.length - 1}</Tag>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalendarEventItem;