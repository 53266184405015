import React, {useEffect, useState} from 'react';
import styles from "./chats-screen.module.scss";
import SearchableHeader from "../../components/searchable-header/searchable-header";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import Empty from "../../components/empty/empty";
import InfiniteScroll from "react-infinite-scroll-component";
import {Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../models/StoreState";
import ChatCard from "./chat-card/chat-card";
import CardOneSkeleton from "./card-one-skeleton/card-one-skeleton";
import {useCurrentUser} from "../app-hooks";
import {ChatRoomOverview} from "../../models/ChatRoomOverview";
import {ActionTypes} from "../../actions/types";
import {useCustomNavigate} from "../../hooks/navigation";
import {TimeStamps} from "../../models/TimeStamps";

function ChatsScreen() {

    const customNavigation = useCustomNavigate();
    const dispatch = useDispatch();
    const [limit] = useState(12);
    const [searchQuery, setSearchQuery] = useState('');
    const timeStamps = useSelector<StoreState, TimeStamps>(state => state.timeStamps);
    //const timeStamp = useTimeStamps('chats-screen');
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const { data: currentUser } = useCurrentUser();

    const {
        data,
        isFetching,
        fetchNextPage,
        isLoading,
    } = useInfiniteQuery(
        `chats-screen-${searchQuery}`,
        async ({ pageParam = 0 }) => {
            const newTimeStamp = new Date();
            newTimeStamp.setSeconds(newTimeStamp.getSeconds() + 10000);
            dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: { 'chats-screen': newTimeStamp } });
            const { data } = await axios.get<{ rooms: ChatRoomOverview[], nextId: number }>(`/api/chats?search=${searchQuery}&limit=${limit}&page=${pageParam}&timestamp=${timeStamps.TEAMS || newTimeStamp}`);
            if (data?.rooms?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage, pages) => lastPage.nextId,
            enabled: !!currentUser
        },
    );

    useEffect(() => {
    }, [data, timeStamps])

    const onSearch = (data: string) => {
        setSearchQuery(data);
    };

    const onCreateClick = () => {
        customNavigation.push('/chats/friends');
    };

    const onChatClick = (userId:string) => {
        customNavigation.push(`/chats/${userId}`)
    };

    // if (!currentUser) {
    //     return <JoinUs/>
    // }

    return (
        <div className={styles.ChatsScreen}>
            <SearchableHeader
                onSearch={onSearch}
                onCreateClick={onCreateClick}
                title="Chats"
                placeholder="Search your chats"
            />
            <div className={styles.container}>
                {
                    isFetching === false && data?.pages.map((page) => page.rooms).flat().length === 0 &&
                    <Empty
                        title="Start your"
                        subTitle="Create notes in a few clicks"
                        icon="plus"
                        buttonTitle="Start chat"
                        url="/storynotes/create"
                        screen="chats"
                    />
                }
                {
                    isFetching && !data
                        ? (
                            [1, 1, 1, 1, 1, 1].map((item, index) => <CardOneSkeleton key={`${index}`} />)
                        ) : (
                            <InfiniteScroll
                                dataLength={data?.pages.map((page) => page.rooms).flat().length || 0}
                                next={() => fetchNextPage()}
                                hasMore={(data?.pages.map((page) => page.rooms).flat().length || 0) > 0 && !isAtTheEnd}
                                loader={(
                                    <div className={styles.spinningContainer}>
                                        <Spin />
                                    </div>
                                )}
                                endMessage={(<p style={{ textAlign: 'center' }} />)}
                            >
                                {
                                    data?.pages.map((val) => val.rooms).flat()
                                        .map((chatRoomOverview: ChatRoomOverview) => (
                                            <ChatCard
                                                key={chatRoomOverview?._id}
                                                chatRoomOverview={chatRoomOverview}
                                                currentUserId={currentUser?._id || ''}
                                                onOverviewCard={onChatClick}
                                            />
                                        ))
                                }
                            </InfiniteScroll>
                        )
                }
            </div>
        </div>
    );
}

export default ChatsScreen;