import React from 'react';
import styles from './chat-card.module.scss';
import {Badge, Typography} from "antd";
import {Chat} from "../../../models/Chat";
import {ChatRoomOverview} from "../../../models/ChatRoomOverview";
import {convertTimeToDaysAgo} from "../../../utils/utils";
import {Img} from "react-image";

type ChatCard = {
    chatRoomOverview: ChatRoomOverview,
    currentUserId: string,
    onOverviewCard: (userId: string) => void
}

const { Paragraph, Text } = Typography;
function ChatCard(props: ChatCard) {

    const { chatRoomOverview, currentUserId, onOverviewCard } = props;

    const otherUser = chatRoomOverview?.user1?._id === currentUserId ? chatRoomOverview?.user2 : chatRoomOverview?.user1;
    const newMessagesCountDiff = chatRoomOverview?.chatRoom?.messagesCount - chatRoomOverview?.messagesCount;

    return (
        <div className={styles.ChatCard} onClick={() => onOverviewCard(otherUser?._id)}>
            <Img
                className={styles.image}
                src={otherUser?.image}
                loader={<div>loading</div>}
            />
            <div className={styles.infoContainer}>
                <div className={styles.username}>
                    {otherUser?.name}
                </div>
                <Text
                    className={styles.subtitle}
                    ellipsis={{
                        //suffix: ` · ${convertTimeToDaysAgo(recentActivity.createdAt)}`
                        suffix: `Active · ${convertTimeToDaysAgo(chatRoomOverview.createdAt)}`
                    }}
                >
                </Text>
            </div>
            <div className={styles.messagesCount}>
                {
                    newMessagesCountDiff > 0
                    && (
                        <Badge
                            count={newMessagesCountDiff}
                            overflowCount={100}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default ChatCard;