import React from 'react';
import styles from './calendar-tag-item.module.scss';
import {CalendarTag} from "../../../../../models/CalendarTag";

type CalendarTagItemProps = {
    calendarTag: CalendarTag;
    onCalendarTagItemClick: (calendarTag: CalendarTag) => void;
}

function CalendarTagItem(props: CalendarTagItemProps) {
    const { calendarTag, onCalendarTagItemClick } = props;

    return (
        <div className={styles.CalendarTagItem} onClick={() => onCalendarTagItemClick(calendarTag)}>
            #{calendarTag?.name}
        </div>
    );
}

export default CalendarTagItem;