import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './main.module.scss';
import dev from '../../../assets/images/scrum.svg';
import { ActionTypes } from '../../../actions/types';
import {useTranslation} from "react-i18next";

function Main() {
  const {t, i18n} = useTranslation ();
  const dispatch = useDispatch();

  const onNavItemAction = () => {
    dispatch({ type: ActionTypes.TOGGLE_AUTH_MODAL, authType: 'signup' });
  };

  return (
    <div className={styles.Main}>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          <span className={styles.bureaucracy}>{t("main.no bureaucracy")}.</span>
          <br />
          <span className={styles.noise}>{t("main.no noise")}.</span>
          <br />
          <span className={styles.shipping}>{t("main.more shipping")}.</span>
        </div>
        <div className={styles.subtitle}>
          {t("main.slogan")}
        </div>
        <div className={styles.actions}>
          <Button size="large" className={styles.actionButton} type="primary" onClick={onNavItemAction}>
            {t("main.join")}
          </Button>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={dev} alt="" />
      </div>
    </div>
  );
}

export default Main;
