import React from 'react';
import Empty from "../../components/empty/empty";
import styles from './grid-layout.module.scss';
import CardOneSkeleton from "../../screens/chats-screen/card-one-skeleton/card-one-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {Skeleton, Spin} from "antd";

type emptyView = {
    title: string,
    subTitle: string,
    icon: string,
    buttonTitle: string,
    url: string,
    screenItem: string
}

type GridLayoutProps = {
    isFetching: boolean,
    isLoading: boolean,
    data: any[] | undefined,
    fetchNextPage: () => void;
    isAtTheEnd: boolean;
    children: JSX.Element,
    emptyView: emptyView
};

function GridLayout(props: GridLayoutProps) {
    const { isFetching, isLoading, data, fetchNextPage, isAtTheEnd, children, emptyView } = props;
    const { title, subTitle, icon, buttonTitle, url, screenItem  } = emptyView;

    return (
        <div className={styles.GridLayout}>
            <InfiniteScroll
                className={styles.infiniteScrollGrid}
                next={() => fetchNextPage()}
                hasMore={(data?.length ?? 0) > 0 && !isAtTheEnd}
                loader={(
                    <div className={styles.spinningContainer}>
                        <Spin />
                    </div>
                )}
                dataLength={data?.length ?? 0}
                scrollableTarget="app-main"
                scrollThreshold={0.3}
            >
                {
                    (isLoading === true) && data === undefined &&
                    <>
                        {
                            [1,1,1,1,1,1,1,1,1].map(() => {
                                return <Skeleton.Input active className={styles.skeletonItem} />
                            })
                        }
                    </>
                }
                {
                    isLoading === false && data?.length === 0 &&
                    <div className={styles.emptyView}>
                        <Empty
                            title={title}
                            subTitle={subTitle}
                            icon={icon}
                            url={url}
                            buttonTitle={buttonTitle}
                            screen={screenItem}
                        />
                    </div>
                }
                {
                    isLoading === false && data && data?.length > 0 &&
                    <>{children}</>
                }
            </InfiniteScroll>
        </div>
    );
}

export default GridLayout;