import React from 'react';
import styles from './view-metric-screen.module.scss';
import {Tabs} from "antd";
import CustomTab from "../../../components/custom-tab/custom-tab";
import MetricItemsTab from "./metric-items-tab/metric-items-tab";
import InfoItemsTab from "./info-items-tab/info-items-tab";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useMetric} from "./view-metric-hooks";
import ViewHeader from "../../../components/view-header/view-header";
import MetricsTab from "./metrics-tab/metrics-tab";

const { TabPane } = Tabs;
function ViewMetricScreen() {

    const { workspace, metricId } = useParams();
    const customNavigate = useCustomNavigate();
    const { data: metric, isLoading } = useMetric(metricId!, { enabled: !!metricId });

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/metrics`);
    };

    const onEditClick = () => {
        customNavigate.push(`/${workspace}/metrics/${metricId}/edit`);
    };

    return (
        <div className={styles.ViewMetricScreen}>
            <ViewHeader
                title={metric?.title}
                onBackClick={onBackClick}
                onEditClick={onEditClick}
            />
            {/*<div className={styles.graphContainer}>*/}

            {/*</div>*/}
            <div>
                {
                    !isLoading &&
                    <Tabs defaultActiveKey="metrics-items" tabBarGutter={0} >
                        {
                            metric?.type === "METRICS_SUM" &&
                                <TabPane tab={<CustomTab name={"Metrics"} />} key="metrics">
                                    <MetricsTab metric={metric} />
                                </TabPane>
                        }
                        {
                            (metric?.type === "METRICS_ITEMS_SUM" || metric?.type === "LAST_METRIC_ITEM") &&
                                <TabPane tab={<CustomTab name={"Metric Items"} />} key="metrics-items">
                                    <MetricItemsTab metric={metric} />
                                </TabPane>
                        }
                        <TabPane tab={<CustomTab name={"Information"} />} key="info">
                            <InfoItemsTab metric={metric} />
                        </TabPane>
                    </Tabs>
                }
            </div>
        </div>
    );
}

export default ViewMetricScreen;