import React, {useState} from 'react';
import styles from './edit-metric-screen.module.scss';
import {Form, Input, InputNumber, Select} from "antd";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useParams} from "react-router-dom";
import {useQueryClient} from "react-query";
import {useMetric} from "../view-metric-screen/view-metric-hooks";
import {useCurrentUser} from "../../app-hooks";
import {useUpdateMetric} from "./edit-metric-hooks";
import {ActionTypes} from "../../../actions/types";
import {useDispatch} from "react-redux";

function EditMetricScreen() {

    const { workspace, metricId } = useParams();
    const customNavigate = useCustomNavigate();
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { data: currentUser } = useCurrentUser();
    const { data: metric } = useMetric(metricId!, { enabled: !!metricId && !!currentUser && !!workspace });
    const dispatch = useDispatch();

    const { mutate: updateMetric, isLoading } = useUpdateMetric(metricId!, (workspace) => {
        queryClient.refetchQueries(['metric', metricId]).then(() => {
            let newDate = new Date();
            newDate.setSeconds(newDate.getSeconds() + 5);
            dispatch({ type: ActionTypes.UPDATE_TIME_STAMPS, value: { METRICS: newDate } });
            onBackClick();
        });
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        setErrorMessage(defaultMessage);
    }, {  });

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/metrics/${metricId}`);
    };

    const onFinish = (values: any) => {
        updateMetric(values);
    };

    const onFinishFailed = () => {

    };

    const formId = "edit-metric";
    return (
        <CreateLayout
            title={"Edit Metric"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.EditMetricScreen}>
                {
                    metric &&
                    <Form
                        name={formId}
                        layout="vertical"
                        id={formId}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                { required: true, message: "Please enter title" },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]
                            }
                            required
                            initialValue={metric?.title}
                        >
                            <Input
                                size="large"
                                placeholder="Enter title for metric"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            initialValue={metric?.description}
                        >
                            <Input
                                size="large"
                                placeholder="Enter description for metric"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Objective"
                            name="objective"
                            required
                            initialValue={metric?.objective}
                        >
                            <Select
                                size="large"
                                placeholder="Select objective"
                            >
                                <Select.Option value="INCREASE">Increase</Select.Option>
                                <Select.Option value="DECREASE">Decrease</Select.Option>
                                <Select.Option value="CONSTANT">Keep constant</Select.Option>
                                <Select.Option value="INCREASE_CONSTANT">Increase then keep constant</Select.Option>
                                <Select.Option value="DECREASE_CONSTANT">Decrease then keep constant</Select.Option>
                            </Select>
                        </Form.Item>
                        {
                            metric?.target &&
                            <Form.Item
                                label="Target"
                                name="target"
                                initialValue={metric?.target}
                            >
                                <InputNumber
                                    size="large"
                                />
                            </Form.Item>
                        }
                        {
                            metric?.ranking &&
                                <div>
                                    <Form.Item
                                        label="Ranking"
                                        name="ranking"
                                        initialValue={metric?.ranking}
                                    >
                                        <InputNumber
                                            min={1}
                                            size="large"
                                        />
                                    </Form.Item>
                                </div>
                        }
                    </Form>
                }
            </div>
        </CreateLayout>
    );
}

export default EditMetricScreen;