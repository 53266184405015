import React from 'react';
import styles from './how-it-works-item.module.scss';
//import book from '../../../../assets/images/book4_red.svg';
//import book from '../../../../assets/images/book5_red.svg';
//import book from '../../../../assets/images/book7_red.svg';
import book from '../../../../assets/images/quiz_1.svg';
import {Button} from "antd";

type HowItWorksItemProps = {
    product: {
        title: string,
        subtitle: string,
        description: string,
        image: string
    }
}

function HowItWorksItem(props: HowItWorksItemProps) {
    const { product } = props;
    const viewSite = () => {

    };

    return (
        <div className={styles.HowItWorksItem} >
            <div className={styles.textContainer}>
                <div className={styles.step} >
                    {product?.title}
                </div>
                <div className={styles.title} >
                    {product?.subtitle}
                </div>
                <div className={styles.subtitle}>
                    {product?.description}
                </div>
            </div>
            <div className={styles.imageContainer}>
                <img className={styles.image}
                     src={product?.image}
                />
            </div>
        </div>
    );
}

export default HowItWorksItem;
