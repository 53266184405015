import React, {useEffect, useState} from 'react';
import styles from './edit-workspaces-screen.module.scss';
import {useCustomNavigate} from "../../../hooks/navigation";
import {useCurrentUser} from "../../app-hooks";
import {useQueryClient} from "react-query";
import {useCreateWorkspace} from "../create-workspaces-screen/create-workspaces-hooks";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import {Checkbox, Form, Input} from "antd";
import CustomFormInput from "../../../components/custom-form/custom-form-input/custom-form-input";
import {useParams} from "react-router-dom";
import {useUpdateWorkspace, useWorkspace} from "./edit-workspace-hooks";
import cookies from "js-cookie";

function EditWorkspacesScreen() {

    const { workspaceId } = useParams();
    const customNavigation = useCustomNavigate();
    const { data: currentUser } = useCurrentUser();
    const { data: workspace } = useWorkspace(workspaceId!, { enabled: !!workspaceId && !!currentUser?._id });
    const queryClient = useQueryClient();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [mode, setMode] = useState<'View' | 'Edit'>('View');

    const { mutate: updateWorkspace, isLoading } = useUpdateWorkspace(workspaceId!, (workspace) => {
        queryClient.refetchQueries(['workspace', workspaceId]).then(() => {
            customNavigation.goBack(`/workspaces/${workspaceId}`);
        });
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again.";
        if ([400,409].includes(error.response?.status ?? 0)) {
            setErrorMessage("Workspace with unique name already exists");
        } else {
            setErrorMessage(defaultMessage);
        }
    }, { });

    const onBackClick = () => {
        customNavigation.goBack(`/workspaces/${workspaceId}`);
    };

    const onFinish = (values: any) => {
        const data = new FormData();

        if (values.displayName) {
            data.append('displayName', values.displayName);
        }

        if (values.uniqueName) {
            data.append('uniqueName', values.uniqueName);
        }

        if (values.description) {
            data.append('description', values.description);
        }

        updateWorkspace(data);
    };

    const onFinishFailed = () => {

    };

    const onEditClick = () => {
        setMode(editButtonValue[mode] as any);
    };

    const onSelectDefault = (data: any) => {
        if (data.target.checked === true) {
            cookies.set('default-workspace', workspace!.uniqueName);
        } else {
            cookies.remove('default-workspace');
        }
    };

    useEffect(() => {
    }, [workspace]);

    const formId = "edit-workspace";
    const editButtonValue = {
        'View': 'Edit',
        'Edit': 'View'
    }
    return (
        <CreateLayout
            title={`Edit Workspace`}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            onEditClick={onEditClick}
            showSaveButton={true}
        >
            <div className={styles.EditWorkspacesScreen}>
                {
                    workspace &&
                    <Form
                        layout="vertical"
                        id={formId}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label={"Unique Name"}
                            name={"uniqueName"}
                            rules={[
                                { required: true, message: "Please enter unique name" },
                                {
                                    pattern: /^[0-9A-Za-z-]+$/,
                                    message: 'Please only use lowercase letters, numbers and hyphens'
                                },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]}
                            required
                            initialValue={workspace?.uniqueName}
                        >
                            <Input
                                size="large"
                                placeholder={"Enter unique name for workspace"}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Display Name"}
                            name={"displayName"}
                            rules={[
                                { required: true, message: "Please enter display name" },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]}
                            required
                            initialValue={workspace?.displayName}
                        >
                            <Input
                                size="large"
                                placeholder={"Enter display name for workspace"}
                            />
                        </Form.Item>
                        <Form.Item
                            label={"Description"}
                            name={"description"}
                            rules={[]}
                            required
                            initialValue={workspace?.description}
                        >
                            <Input
                                size="large"
                                placeholder={"Enter description for workspace"}
                            />
                        </Form.Item>
                        <Checkbox
                            onClick={onSelectDefault}
                            defaultChecked={workspace?.uniqueName === cookies.get('default-workspace')}
                        >
                            Default
                        </Checkbox>
                    </Form>
                }
            </div>
        </CreateLayout>
    );

}

export default EditWorkspacesScreen;