import React from 'react';
import styles from './CreateApiModal.module.scss';
import {Button, Form, Input, Modal, Select} from "antd";
import {useStore} from "../../../../../index";

type CreateApiModalProps = {
    onCreateApiAction: (values: any) => void;
}

function CreateApiModal(props: CreateApiModalProps) {

    const { onCreateApiAction } = props;
    const [form] = Form.useForm();
    const isCreateApiModalVisible = useStore(state => state.isCreateApiModalVisible);
    const setIsCreateApiModalVisible = useStore(state => state.setIsCreateApiModalVisible);

    const handleCancel = () => {
        setIsCreateApiModalVisible(false)
    }

    const formId = "create-api";

    const onFinish = (values: any) => {
        onCreateApiAction(values);
        setIsCreateApiModalVisible(false);
        form.resetFields();
    };

    const onFinishFailed = () => {

    };

    return (
        <Modal
            title={null}
            visible={isCreateApiModalVisible}
            closable={false}
            footer={null}
            onCancel={handleCancel}
        >
            <div className={styles.CreateApiModal}>
                <div className={styles.title}>
                    Create API
                </div>
                <Form
                    layout="vertical"
                    id={formId}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            { required: true, message: "Please enter title" },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter title for API"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Type"
                        name="type"
                        required
                    >
                        <Select
                            size="large"
                            placeholder="Select type"
                        >
                            <Select.Option value="POST">POST</Select.Option>
                            <Select.Option value="PUT">PUT</Select.Option>
                            <Select.Option value="GET">GET</Select.Option>
                            <Select.Option value="DELETE">DELETE</Select.Option>
                            <Select.Option value="PATCH">PATCH</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Url"
                        name="url"
                        rules={[
                            { required: true, message: "Please enter url" },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter url for API"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Request Params"
                        name="params"
                    >
                        <Input.TextArea
                            size="large"
                            placeholder="Start typing here..."
                            rows={2}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Request Body"
                        name="requestBody"
                    >
                        <Input.TextArea
                            size="large"
                            placeholder="Start typing here..."
                            rows={2}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Response"
                        name="response"
                    >
                        <Input.TextArea
                            size="large"
                            placeholder="Start typing here..."
                            rows={2}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Summary"
                        name="summary"
                    >
                        <Input.TextArea
                            size="large"
                            placeholder="Start typing here..."
                            rows={2}
                        />
                    </Form.Item>
                    <div>
                        <Button
                            className={styles.saveButton}
                            size="large"
                            type="primary"
                            htmlType="submit"
                            form={formId}
                        >
                            SAVE
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}

export default CreateApiModal;