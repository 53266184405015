import React, {useState} from 'react';
import styles from './reading-comp.module.scss';
import {Slider} from "antd";

function ReadingComp() {
    const [sliderValue, setSliderValue] = useState(0);
    const text = "This is the beginning of this story and the words should be highlighted";
    const words = text.split(" ");

    const onSliderChange = (value: number) => {
        setSliderValue(value);
    };

    return (
        <div className={styles.ReadingComp}>
            <div>
                {sliderValue}
            </div>
            <div>
                {words.map((word, index) => (<span><span className={`${index < sliderValue ? styles.highlightWord: ''}`}>{word}</span><span>{' '}</span></span>))}
            </div>
            <div>
                <Slider
                    className={styles.slider}
                    min={0}
                    max={words.length}
                    value={sliderValue}
                    onChange={onSliderChange}
                    // value={typeof inputValue === 'number' ? inputValue : 0}
                />
            </div>
        </div>
    );
}

export default ReadingComp;