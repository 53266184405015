import React, {useState} from 'react';
import styles from './edit-flow-node-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useCurrentUser} from "../../../app-hooks";
import {useQueryClient} from "react-query";
import {useStore} from "../../../../index";
import {
    useCreateDocumentApi,
    useCreateDocumentTag,
    useDocumentApis,
    useDocumentTags
} from "../../create-document-screens/create-document-hooks";
import {useDocumentFlowNodes, useFlowNode, useUpdateFlowNode} from "../../view-document-screens/view-document-hooks";
import {ApiLeafNode, ApiTreeNode} from "../../../../models/TreeNode";
import CreateLayout from "../../../../layouts/create-layout/create-layout";
import CreateApiModal from "../../create-document-screens/create-flow-document-screen/CreateApiModal/CreateApiModal";
import {Button, Divider, Form, Input, Select, TreeSelect} from "antd";
import {PlusOutlined} from "@ant-design/icons";

function EditFlowNodeScreen() {

    const { workspace, documentId, flowNodeId } = useParams();
    const customNavigation = useCustomNavigate();
    const { data: currentUser } = useCurrentUser();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const queryClient = useQueryClient();
    const [selectedApis, setSelectedApis] = useState<string[]>([]);
    const [apiCounter, setApiCounter] = useState(0);
    const [tag, setTag] = useState<string>();
    const setIsCreateApiModalVisible = useStore(state => state.setIsCreateApiModalVisible);
    const { data: allTags } = useDocumentTags(documentId!, { enabled: !!documentId });
    const { data: allApis } = useDocumentApis(documentId!, { enabled: !!documentId });
    const { data: flowNodes } = useDocumentFlowNodes(documentId!, { enabled: !!documentId });
    console.log("flowNodeId: ",flowNodeId);
    const { data: flowNode } = useFlowNode(flowNodeId!, { enabled: !!flowNodeId });
    console.log("flowNode: ",flowNode);

    const { mutate: createDocumentTag, isLoading: isLoadingDocumentTag } = useCreateDocumentTag(documentId, (document) => {
        queryClient.invalidateQueries(['document-tags', documentId]);
        setTag(undefined);
    }, (error) => {
        setErrorMessage("Something went wrong. Please try again.");
    }, {  });

    const { mutate: createDocumentApi, isLoading: isLoadingDocumentApi } = useCreateDocumentApi(documentId, (document) => {
        queryClient.invalidateQueries(['document-apis', documentId]);
        setTag(undefined);
    }, (error) => {
        setErrorMessage("Something went wrong. Please try again.");
    }, {  });

    const { mutate: updateFlowNode, isLoading: isLoadingFlowNode } = useUpdateFlowNode(flowNodeId, (document) => {
        queryClient.invalidateQueries(['documents-flow', documentId]).then(() => {
            onBackClick();
        });
    }, (error) => {
        setErrorMessage("Something went wrong. Please try again.");
    }, {  });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/documents/${documentId}`);
    };

    const formId = "create-document-flow-node";

    const onFinish = (values: any) => {
        const newDocumentFlow = {...values };
        updateFlowNode(newDocumentFlow);
    };

    const onFinishFailed = () => {

    };

    const onAddApi = () => {
        setIsCreateApiModalVisible(true);
    }

    const onCreateApiAction = (values: any) => {
        const tempChildren: ApiLeafNode[] = [];

        if (values.title) {
            // tempChildren.push({ title: `Title: ${values.title}`, value: `Title: ${values.title}`, key: `${apiCounter}-title`, level: 1, children: [], isLeaf: true, disabled: true });
            tempChildren.push({ title: `Title: ${values.title}`, value: `Title: ${values.title}`, level: 1, children: [], isLeaf: true, disabled: true });
        }

        if (values.type) {
            // tempChildren.push({ title: `Type: ${values.type}`, value: `Type: ${values.type}`, key: `${apiCounter}-type`, level: 1, children: [], isLeaf: true, disabled: true });
            tempChildren.push({ title: `Type: ${values.type}`, value: `Type: ${values.type}`, level: 1, children: [], isLeaf: true, disabled: true });
        }

        if (values.url) {
            // tempChildren.push({ title: `Url: ${values.url}`, value: `Url: ${values.url}`, key: `${apiCounter}-url`, level: 1, children: [], isLeaf: true, disabled: true });
            tempChildren.push({ title: `Url: ${values.url}`, value: `Url: ${values.url}`, level: 1, children: [], isLeaf: true, disabled: true });
        }

        if (values.params) {
            const paramsChildren: ApiLeafNode[] = [];
            // paramsChildren.push({ title: values.params, value: `Params: ${values.params}`, key: `${apiCounter}-params-data`, level: 2, children: [], isLeaf: true, disabled: true });
            paramsChildren.push({ title: values.params, value: `Params: ${values.params}`, level: 2, children: [], isLeaf: true, disabled: true });
            // tempChildren.push({ title: "Params", value: "Params", key: `${apiCounter}-params`, level: 1, children: paramsChildren, isLeaf: false, disabled: true });
            tempChildren.push({ title: "Params", value: "Params", level: 1, children: paramsChildren, isLeaf: false, disabled: true });
        }

        if (values.requestBody) {
            const requestBodyChildren: ApiLeafNode[] = [];
            // requestBodyChildren.push({ title: values.requestBody, value: `Request body: ${values.requestBody}`, key: `${apiCounter}-request-body-data`, level: 2, children: [], isLeaf: true, disabled: true });
            requestBodyChildren.push({ title: values.requestBody, value: `Request body: ${values.requestBody}`, level: 2, children: [], isLeaf: true, disabled: true });
            // tempChildren.push({ title: "Request body", value: "Request body", key: `${apiCounter}-request-body`, level: 1, children: requestBodyChildren, isLeaf: false, disabled: true });
            tempChildren.push({ title: "Request body", value: "Request body", level: 1, children: requestBodyChildren, isLeaf: false, disabled: true });
        }

        if (values.response) {
            const responseChildren: ApiLeafNode[] = [];
            // responseChildren.push({ title: values.response, value: `Response: ${values.requestBody}`, key: `${apiCounter}-response-data`, level: 2, children: [], isLeaf: true, disabled: true });
            responseChildren.push({ title: values.response, value: `Response: ${values.requestBody}`, level: 2, children: [], isLeaf: true, disabled: true });
            // tempChildren.push({ title: "Response", value: "Response", key: `${apiCounter}-response`, level: 1, children: responseChildren, isLeaf: false, disabled: true });
            tempChildren.push({ title: "Response", value: "Response", level: 1, children: responseChildren, isLeaf: false, disabled: true });
        }

        if (values.summary) {
            const summaryChildren: ApiLeafNode[] = [];
            // summaryChildren.push({ title: values.summary, value: `Summary: ${values.summary}`, key: `${apiCounter}-summary-data`, level: 2, children: [], isLeaf: true, disabled: true });
            summaryChildren.push({ title: values.summary, value: `Summary: ${values.summary}`, level: 2, children: [], isLeaf: true, disabled: true });
            // tempChildren.push({ title: "Summary", value: "Summary", key: `${apiCounter}-summary`, level: 1, children: summaryChildren, isLeaf: false, disabled: true });
            tempChildren.push({ title: "Summary", value: "Summary", level: 1, children: summaryChildren, isLeaf: false, disabled: true });
        }

        const newApiTreeData: ApiTreeNode = {
            apiValue: values,
            value: `${values.type}: ${values.title}`,
            title: `${values.type}: ${values.title}`,
            level: 0,
            children: tempChildren
        }

        setApiCounter(apiCounter + 1);
        createDocumentApi(newApiTreeData);
    }

    const addNewTag = () => {
        if (tag) {
            createDocumentTag(tag);
        }
    }

    const onTagChange = (data: any) => {
        setTag(data.target.value);
    }

    const onTreeChange = (selectedOptions: string[]) => {
        setSelectedApis(selectedOptions);
    };

    return (
        <CreateLayout
            title={"Edit Flow Node"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={false}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.EditFlowNodeScreen}>
                <CreateApiModal
                    onCreateApiAction={onCreateApiAction}
                />
                {
                    flowNode &&
                    <Form
                        layout="vertical"
                        id={formId}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                { required: true, message: "Please enter title" },
                                {
                                    min: 3,
                                    message: 'Please enter minimum of 3 letters'
                                }
                            ]
                            }
                            initialValue={flowNode.title}
                            required
                        >
                            <Input
                                size="large"
                                placeholder="Enter title for flow node"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            initialValue={flowNode.description}
                        >
                            <Input.TextArea
                                size="large"
                                placeholder="Start typing here..."
                                rows={2}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.selectItem}
                            label="Tags"
                            name="documentTags"
                            initialValue={flowNode.documentTags.map((tag) => tag._id)}
                        >
                            <Select
                                className="item-name-id"
                                mode="multiple"
                                size="large"
                                placeholder="Select tags"
                                dropdownRender={(dropdownOptions) => (
                                    <div>
                                        {dropdownOptions}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Input
                                                size="large"
                                                style={{ flex: 'auto' }}
                                                value={tag}
                                                onChange={onTagChange}
                                            />
                                            <Button
                                                style={{
                                                    flex: 'none', padding: '8px', display: 'block', cursor: 'pointer',
                                                }}
                                                onClick={addNewTag}
                                                type="link"
                                            >
                                                <PlusOutlined />
                                                {' '}
                                                Add item
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            >
                                {allTags?.map((item, index) => (
                                    <Select.Option key={item._id} value={item._id}>{item.displayName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.selectItem}
                            label="API's"
                            name="documentApis"
                            initialValue={flowNode.documentApis.map((api) => api._id)}
                        >
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={allApis?.map((api) => {
                                    return {
                                        ...api.apiTreeData,
                                        value: api._id
                                    }
                                })}
                                dropdownRender={(dropdownOptions) => (
                                    <div>
                                        {dropdownOptions}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                            <Button style={{ flex: 1 }} className={styles.addApi} size="large" onClick={onAddApi}>
                                                <PlusOutlined />
                                                {' '}
                                                Add API
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                placeholder="Please select"
                                multiple
                                size="large"
                                onChange={onTreeChange}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.selectItem}
                            label="From Nodes"
                            name="fromNodes"
                            initialValue={flowNode.fromNodes.map((api) => api._id)}
                        >
                            <Select
                                className="item-name-id"
                                mode="multiple"
                                size="large"
                                placeholder="Select from nodes"
                            >
                                {flowNodes?.filter((fNode) => fNode._id !== flowNode?._id).map((item, index) => (
                                    <Select.Option key={item._id} value={item._id}>{item.title}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.selectItem}
                            label="To Nodes"
                            name="toNodes"
                            initialValue={flowNode.toNodes.map((api) => api._id)}
                        >
                            <Select
                                className="item-name-id"
                                mode="multiple"
                                size="large"
                                placeholder="Select to nodes"
                            >
                                {flowNodes?.filter((fNode) => fNode._id !== flowNode?._id).map((item, index) => (
                                    <Select.Option key={item._id} value={item._id}>{item.title}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                }
            </div>
        </CreateLayout>
    );
}

export default EditFlowNodeScreen;