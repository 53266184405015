import React from 'react';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import { useDispatch } from 'react-redux';
import styles from './empty.module.scss';
import search1_blue from '../../assets/images/search1_blue.svg';
import post1_blue from '../../assets/images/post2_blue.svg';
import empty_users from '../../assets/images/empty_users.svg';
import noData from '../../assets/images/no-data.svg';
import { ActionTypes } from '../../actions/types';
import {useCustomNavigate} from "../../hooks/navigation";

interface EmptyProps {
    title: string;
    subTitle: string;
    screen?: string;
    icon: string;
    buttonTitle?: string;
    url: string;
    hideButton?: boolean;
}

function Empty(props:EmptyProps) {
    const customNavigation = useCustomNavigate();
    const dispatch = useDispatch();
    const { hideButton } = props;

    const renderCorrectImage = (screen?: string) => {
        switch (screen) {
            case 'posts':
                return (
                    <img
                        className={styles.emptyImage}
                        src={post1_blue}
                        alt={post1_blue}
                    />
                );
            case 'home':
                return (
                    <img
                        className={styles.emptyImage}
                        src={search1_blue}
                        alt={search1_blue}
                    />
                );
            case 'notifications':
                return (
                    <img
                        className={styles.emptyImage}
                        src={noData}
                        alt={noData}
                    />
                );
            case 'empty_users':
                return (
                    <img
                        className={styles.emptyImage}
                        src={empty_users}
                        alt={empty_users}
                    />
                );
            case 'chats':
                return (
                    <img
                        className={styles.emptyImage}
                        src={noData}
                        alt={noData}
                    />
                );
            default:
                return (
                    <img
                        className={styles.emptyImage}
                        src={search1_blue}
                        alt={search1_blue}
                    />
                );
        }
    };

    const getCorrectImage = (icon: string) => {
        switch (icon) {
            case 'search':
                return <SearchOutlined />;
            case 'plus':
                return <PlusOutlined />;
            default:
                return <div />;
        }
    };

    const onActionClick = () => {
        customNavigation.push(props.url);
        dispatch({ type: ActionTypes.SET_MENU_ITEM, location: props.url.replace('/', '') });
        dispatch({ type: ActionTypes.SET_LAST_LOCATION, location: props.url.replace('/', '') });
    };

    return (
        <Result
            className={styles.Empty}
            icon={renderCorrectImage(props.screen)}
            title={""}
            subTitle={""}
            extra={props.buttonTitle && (!hideButton) && (
                <Button type="primary" onClick={onActionClick}>
                    {getCorrectImage(props.icon)}
                    {props.buttonTitle}
                </Button>
            )}
        />
    );
}

export default Empty;
