import axios, {AxiosError} from "axios";
import {useMutation, useQuery, UseQueryOptions, UseMutationOptions} from "react-query";
import {DocumentTag} from "../../../models/DocumentTag";
import {DocumentApi} from "../../../models/DocumentApi";
import {DocumentFlowNode} from "../../../models/DocumentFlowNode";

export const useCreateDocument = (workspaceId: string | undefined, onSuccess: (data: Document) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<Document, AxiosError, Document>) =>
    useMutation<Document, AxiosError, any>(['create-document'], async (documentData) => {
        const res = await axios.post<Document>(`/api/documents/workspaces/${workspaceId}`, {...documentData});
        return res.data;
    }, { ...options, onSuccess, onError, });

export const useCreateDocumentFlow = (workspaceId: string | undefined, onSuccess: (data: Document) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<Document, AxiosError, Document>) =>
    useMutation<Document, AxiosError, any>(['create-document-flow'], async (documentData) => {
        const res = await axios.post<Document>(`/api/documents/workspaces/${workspaceId}/flow`, {...documentData});
        return res.data;
    }, { ...options, onSuccess, onError, });

export const useCreateDocumentTag = (documentId: string | undefined, onSuccess: (data: Document) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<Document, AxiosError, Document>) =>
    useMutation<Document, AxiosError, any>(['create-document-tag', documentId], async (tag) => {
        const res = await axios.post<Document>(`/api/document-tags`, { tag, documentId });
        return res.data;
    }, { ...options, onSuccess, onError, });

export const useDocumentTags = (documentId: string, options?: UseQueryOptions<DocumentTag[], AxiosError, DocumentTag[]>) =>
    useQuery<DocumentTag[], AxiosError, DocumentTag[]>(['document-tags', documentId], async () => {
            const res = await axios.get<DocumentTag[]>(`/api/document-tags/documents/${documentId}`);
            return res.data;
        },
        { ...options,
            onSuccess: data => {
                //console.log("useCurrentUser - data: ",data);
            }, onError: error => {
                //console.log("useCurrentUser - err: ",error);
            }, });

export const useCreateDocumentApi = (documentId: string | undefined, onSuccess: (data: Document) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<Document, AxiosError, Document>) =>
    useMutation<Document, AxiosError, any>(['create-document-api', documentId], async (api) => {
        const res = await axios.post<Document>(`/api/document-apis`, { api, documentId });
        return res.data;
    }, { ...options, onSuccess, onError, });

export const useDocumentApis = (documentId: string, options?: UseQueryOptions<DocumentApi[], AxiosError, DocumentApi[]>) =>
    useQuery<DocumentApi[], AxiosError, DocumentApi[]>(['document-apis', documentId], async () => {
            const res = await axios.get<DocumentApi[]>(`/api/document-apis/documents/${documentId}`);
            return res.data;
        },
        { ...options,
            onSuccess: data => {
                //console.log("useCurrentUser - data: ",data);
            }, onError: error => {
                //console.log("useCurrentUser - err: ",error);
            }, });

export const useDocumentFlowNodes = (documentId: string, options?: UseQueryOptions<DocumentApi[], AxiosError, DocumentApi[]>) =>
    useQuery<DocumentApi[], AxiosError, DocumentApi[]>(['document-apis', documentId], async () => {
            const res = await axios.get<DocumentApi[]>(`/api/document-flow-nodes/documents/${documentId}`);
            return res.data;
        },
        { ...options,
            onSuccess: data => {
                //console.log("useCurrentUser - data: ",data);
            }, onError: error => {
                //console.log("useCurrentUser - err: ",error);
            }, });

export const useCreateDocumentFlowNode = (documentId: string | undefined, onSuccess: (data: DocumentFlowNode) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<DocumentFlowNode, AxiosError, DocumentFlowNode>) =>
    useMutation<DocumentFlowNode, AxiosError, any>(['create-document-api', documentId], async (flowNodeData) => {
        const res = await axios.post<DocumentFlowNode>(`/api/document-flow-nodes/documents/${documentId}`, flowNodeData);
        return res.data;
    }, { ...options, onSuccess, onError, });