import {useMutation, useQuery, UseQueryOptions, UseMutationOptions} from "react-query";
import {Document} from "../../../models/Document";
import axios, {AxiosError} from "axios";
import {DocumentFlowNode} from "../../../models/DocumentFlowNode";

export const useDocument = (documentId: string, options?: UseQueryOptions<Document, AxiosError, Document>) => {
    return useQuery<Document, AxiosError, Document>(['document', documentId], async () => {
            const res = await axios.get<Document>(`/api/documents/${documentId}`);
            return res.data;
        },
        {
            ...options,
            // staleTime: 5000,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};

export const useUpdateListItemsDocument = (documentId: string, onSuccess: (data: Document) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<Document, AxiosError, Document>) =>
    useMutation<Document, AxiosError, any>(['update-list-items-document', documentId], async (documentData) => {
        const res = await axios.put<Document>(`/api/documents/${documentId}/done`, documentData);
        return res.data;
    }, { ...options, onSuccess, onError, });

export const useCompleteDocumentList = (documentId: string, onSuccess: (data: Document) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<Document, AxiosError, Document>) =>
    useMutation<Document, AxiosError, any>(['completed-document-list', documentId], async (documentData) => {
        const res = await axios.post<Document>(`/api/documents-list`, documentData);
        return res.data;
    }, { ...options, onSuccess, onError, });

export const useDocumentFlowNodes = (documentId: string, options?: UseQueryOptions<DocumentFlowNode[], AxiosError, DocumentFlowNode[]>) => {
    return useQuery<DocumentFlowNode[], AxiosError, DocumentFlowNode[]>(['documents-flow', documentId], async () => {
            const res = await axios.get<DocumentFlowNode[]>(`/api/document-flow-nodes/documents/${documentId}`);
            return res.data;
        },
        {
            ...options,
            // staleTime: 5000,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};

export const useFlowNode = (flowNodeId: string, options?: UseQueryOptions<DocumentFlowNode, AxiosError, DocumentFlowNode>) => {
    return useQuery<DocumentFlowNode, AxiosError, DocumentFlowNode>(['flow-node', flowNodeId], async () => {
            const res = await axios.get<DocumentFlowNode>(`/api/document-flow-nodes/${flowNodeId}`);
            return res.data;
        },
        {
            ...options,
            // staleTime: 5000,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};

export const useUpdateFlowNode = (flowNodeId: string | undefined, onSuccess: (data: DocumentFlowNode) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<DocumentFlowNode, AxiosError, DocumentFlowNode>) =>
    useMutation<DocumentFlowNode, AxiosError, any>(['update-flow-node', flowNodeId], async (flowNodeData) => {
        const res = await axios.put<DocumentFlowNode>(`/api/document-flow-nodes/${flowNodeId}`, flowNodeData);
        return res.data;
    }, { ...options, onSuccess, onError, });