import React, {Key, useState} from 'react';
import styles from './view-flow-document-screen.module.scss';
import {Document} from "../../../../models/Document";
import {LockOutlined} from "@ant-design/icons";
import ViewHeader from "../../../../components/view-header/view-header";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useParams} from "react-router-dom";
import {Button, Input, Tag, Tree} from "antd";
import treeData from "../flow-document/flow.json";
import {useDocumentFlowNodes} from "../view-document-hooks";
import {ApiTreeNode} from "../../../../models/TreeNode";
import {DocumentFlowNode} from "../../../../models/DocumentFlowNode";

type ViewFlowDocumentScreenProps = {
    document: Document;
}

const { Search } = Input;
function ViewFlowDocumentScreen(props: ViewFlowDocumentScreenProps) {
    const { document } = props;
    const { workspace, documentId } = useParams();
    const customNavigate = useCustomNavigate();
    const [isInEditMode, setIsInEditMode] = useState(false);
    const { data } = useDocumentFlowNodes(document._id);

    let keysItem: { [key: string]: { fromNodes: string[], toNodes: string[] } } = {}
    data?.forEach((level1, index) => {
        const fromNodes = level1.fromNodes.map((fn) => fn._id);
        const toNodes = level1.toNodes.map((fn) => fn._id);
        if (fromNodes.length > 0 || toNodes.length > 0) {
            keysItem = {
                ...keysItem,
                [`${level1._id}`]: {
                    fromNodes,
                    toNodes,
                }
            }
        }
    });

    const parentNodes = data?.filter((node) => {
        let result = true;
        node.fromNodes.map((fromNodeId) => fromNodeId._id).forEach((fromNodeId: string) => {
            const toNodes = keysItem[fromNodeId]?.toNodes;
            if (toNodes?.includes(`${node._id}`)) {
                result = false;
            }
        })
        return result;
    });

    const childNodes = data?.filter((node) => !parentNodes?.map((pn) => pn._id).includes(node._id));

    const getItem = (node: DocumentFlowNode, level: number, index: number) => {
        const flowItemChildren: any[] = [];

        if (node.description) {
            const descriptionChildren: any[] = [{
                title: node.description,
                key: `${node.title} - Description - value`,
                value: `${node.title} - Description - value`,
                level: level + 1,
                children: [],
                isLeaf: true
            }];

            flowItemChildren.push({
                title: `Description`,
                key: `${node.title} - Description`,
                value: `${node.title} - Description`,
                level: level + 1,
                children: descriptionChildren,
                isLeaf: false
            });
        }

        if (node.documentTags && node.documentTags.length > 0) {
            flowItemChildren.push({
                title: `Tags`,
                key: `${node.title} - documentTags - ${level}`,
                value: `${node.title} - documentTags - ${level}`,
                level: level + 1,
                children: node.documentTags.length > 0 ? [{
                    title: node.documentTags?.map((tag: any) => `${tag.uniqueName}`).join(","),
                    key: `${node.title} - documentTags - value`,
                    value: `${node.title} - documentTags - value`,
                    level: level + 1,
                    children: [],
                    isLeaf: true
                }]: [],
                isLeaf: node.documentTags.length <= 0
            });
        }

        if (node.documentApis && node.documentApis.length > 0) {
            flowItemChildren.push({
                title: `Apis`,
                key: `${node.title} - documentApis`,
                value: `${node.title} - documentApis`,
                level: level + 1,
                children: node.documentApis.map((documentApi: any) => {
                    return {...documentApi.apiTreeData, value: `${node._id}-${documentApi.apiTreeData.value}`, key: `${node._id}-${documentApi.apiTreeData.value}`, isLeaf: documentApi.apiTreeData.children.length === 0 };
                }),
                isLeaf: false
            });
        }

        if (node.fromNodes && node.fromNodes.length > 0) {
            const fromNodes = node.fromNodes.map((dfn, index) => {
                return {
                    title: dfn.title,
                    key: `${dfn.title} - ${index}`,
                    value: `${dfn.title} - ${index}`,
                    level: level + 1,
                    children: [],
                    isLeaf: true
                }
            })

            flowItemChildren.push({
                title: `From Screens`,
                key: `${node.title} - From Screens`,
                value: `${node.title} - From Screens`,
                level: level + 1,
                children: fromNodes,
                isLeaf: false
            });
        }

        if (node.toNodes && node.toNodes.length > 0) {
            const toNodes = node.toNodes.map((dfn, index2) => {
                const childNode = childNodes?.find((cn) => cn._id === dfn._id);
                if (childNode) {
                    return getItem(childNode, 2, index2);
                }
                return {
                    title: dfn.title,
                    key: `${dfn.title} - ${index}`,
                    value: `${dfn.title} - ${index}`,
                    level: level + 1,
                    children: [],
                    isLeaf: true
                }
            })

            flowItemChildren.push({
                title: `To Screens`,
                key: `${node.title} - To Screens`,
                value: `${node.title} - To Screens`,
                level: level + 1,
                children: toNodes,
                isLeaf: false
            });
        }

        if (isInEditMode) {
            flowItemChildren.push({
                title: `Edit`,
                value: `Edit ${node._id} ${index}`,
                key: `Edit ${node._id} ${index}`,
                level: -1,
                isLeaf: true,
                _id: node._id,
            });
        }

        const flowItem: any = {
            key: node._id,
            value: node._id,
            title: node.title,
            level: level,
            children: flowItemChildren,
            isLeaf: false
        }

        return flowItem;
    }

    const newTreeDataFromSource = parentNodes?.map((node, index) => {
        return getItem(node, 0, index);
    });


    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/documents`);
    };

    const onEditClick = () => {
        customNavigate.push(`/${workspace}/documents/${documentId}/edit`);
    };

    const onEditFlowClick = (node: any) => {
        customNavigate.push(`/${workspace}/documents/${documentId}/edit-flow-node/${node._id}`);
    };

    const [searchValue, setSearchValue] = useState('');
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    const onClick = (title: string, level: number, _id: string | undefined) => {
        if (title === "Add Screen" && level === 3) {

        }
    };

    const highlightText = (text: string, isLeaf: boolean | undefined, url: string | undefined, level: number, _id: string | undefined) => {
        const startIndex = text.toLowerCase().indexOf(searchValue.toLowerCase());
        const lastIndex = text.toLowerCase().lastIndexOf(searchValue.toLowerCase());

        if (searchValue && startIndex !== -1 && lastIndex !== -1) {
            const prefix = text.substring(0, startIndex);
            const mainText = text.substring(startIndex, lastIndex + searchValue.length);
            const suffix = text.substring(lastIndex + searchValue.length);

            if (isLeaf) {
                return <span style={{ padding: '2px 4px' }} onClick={() => onClick(text, level, _id)}>
                    {prefix}
                    <span style={{ background: 'rgb(255,223,59)' }}>{mainText}</span>
                    {suffix}
                </span>;
            } else {
                return <span style={{ padding: '2px 4px' }} onClick={() => onClick(text, level, _id)}>
                    {prefix}
                    <span style={{ background: 'rgb(255,223,59)' }}>{mainText}</span>
                    {suffix}
                </span>;
            }

        }

        if (isLeaf) {
            return <a style={{ padding: '2px 4px' }} onClick={() => onClick(text, level, _id)}>{text}</a>;
        }
        return <span style={{ padding: '2px 4px' }}>{text}</span>;
    };

    const getAllValues = (items: any[]) => {
        const values = getValues(items);
        return values;
    }

    const getValues = (items: any[]) => {
        let index = 0;
        let values: string[] = []
        while (index < items.length && items[index].isLeaf === false) {
            const currItem = items[index]
            values.push(currItem.value);
            index = index + 1;
            if (currItem.children.length > 0) {
                const childValues = getValues(currItem.children);
                values = values.concat(childValues);
            }
        }
        return values;
    }

    const onSearch = (search: string) => {
        if (newTreeDataFromSource && search) {
            const allKeys = getAllValues(newTreeDataFromSource);
            setAutoExpandParent(true);
            const filteredKeys = allKeys.filter((key) => key.toLowerCase().includes(search.toLowerCase()));
            setExpandedKeys([...filteredKeys]);
        } else {
            setExpandedKeys([])
            setAutoExpandParent(false);
        }
        setSearchValue(search);
    }

    const onExpand = (keys: Key[], type: { node: any; expanded: boolean; nativeEvent: MouseEvent; }) => {
        setAutoExpandParent(false);
        setExpandedKeys(keys);
    }

    const onExpandAll = () => {
        if (newTreeDataFromSource) {
            const allKeys = getAllValues(newTreeDataFromSource);
            setExpandedKeys(allKeys);
        }
    };

    const onCreateFlowNodeClick = () => {
        customNavigate.push(`/${workspace}/documents/${documentId}/create-flow-node`);
    };

    const onCollapseAll = () => {
        setExpandedKeys([]);
    };

    const titleRender = (node: any) => {
        if (node.isLeaf && node.level === -1 && isInEditMode) {
            return <Button type="link" style={{ padding: 0 }} onClick={() => onEditFlowClick(node)} >Edit</Button>
        }
        return highlightText(node.title!.toString(), node.isLeaf, node.url, node.level, node._id);
    }

    const onDragEnter = () => {

    }

    const onEditScreenClick = () => {
        if (isInEditMode) {

        }
        setIsInEditMode(!isInEditMode);
    }

    const onDrop = (data: any) => {
        console.log("onDrop - data: ",data);
    }

    const onSelectItem = (data: any, data2: any) => {
        console.log("onSelectItem - data: ",data);
        console.log("onSelectItem - data2: ",data2);
    }

    // const onSearchChange = (data: any) => {
    //     console.log("onSearchChange: ",data.target.value);
    //     setSearchValue(data.target.value);
    // }

    return (
        <>
            <ViewHeader
                title={document?.title}
                iconComponent={document?.visibility === "PRIVATE" && <LockOutlined className={styles.privateIcon} />}
                onBackClick={onBackClick}
                onEditClick={onEditClick}
            />
            <div className={styles.ViewFlowDocumentScreen}>
                <Search
                    allowClear
                    size="large"
                    placeholder={"Enter text..."}
                    onSearch={onSearch}
                    // value={searchValue}
                    // onChange={onSearchChange}
                />
                <div className={styles.actionBar}>
                    <div>
                        {
                            expandedKeys.length > 0 ?
                                <Button className={styles.collapseButton} type="link" onClick={onCollapseAll} size="large" >Close All</Button>:
                                <Button className={styles.collapseButton} type="link" onClick={onExpandAll} size="large" >Expand All</Button>
                        }
                    </div>
                    <div>
                        {
                            isInEditMode ? <Button
                                className={styles.collapseButton}
                                type="link"
                                onClick={onEditScreenClick}
                                size="large"
                            >
                                Cancel
                            </Button>:
                                <Button
                                    className={styles.collapseButton}
                                    type="link"
                                    onClick={onEditScreenClick}
                                    size="large"
                                >
                                    Edit
                                </Button>
                        }
                        <Button
                            className={styles.collapseButton}
                            type="link"
                            onClick={onCreateFlowNodeClick}
                            size="large"
                        >
                            Add Screen
                        </Button>
                    </div>
                </div>
                <Tree
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    titleRender={titleRender}
                    blockNode
                    onDragEnter={onDragEnter}
                    onDrop={onDrop}
                    treeData={newTreeDataFromSource}
                    onSelect={onSelectItem}
                    // showLine
                />
            </div>
        </>
    );
}

export default ViewFlowDocumentScreen;