import {useQuery, UseQueryOptions} from "react-query";
import axios, {AxiosError} from "axios";
import {CalendarTag} from "../../../models/CalendarTag";

export const useCalendarTag = (calendarTagId: string, options?: UseQueryOptions<CalendarTag, AxiosError, CalendarTag>) => {
    return useQuery<CalendarTag, AxiosError, CalendarTag>(['calendar-tag', calendarTagId], async () => {
            const res = await axios.get<CalendarTag>(`/api/calendar-tags/${calendarTagId}`);
            return res.data;
        },
        {
            ...options,
            // staleTime: 5000,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};