import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useCurrentUser} from "../../app-hooks";
import {useWorkspace} from "../edit-workspaces-screen/edit-workspace-hooks";
import CreateLayout from "../../../layouts/create-layout/create-layout";
import styles from "./view-workspace-screen.module.scss";
import {Form} from "antd";

function ViewWorkspaceScreen() {
    const { workspaceId } = useParams();
    const customNavigation = useCustomNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const { data: currentUser } = useCurrentUser();
    const { data: workspace } = useWorkspace(workspaceId!, { enabled: !!workspaceId && !!currentUser?._id });

    const onBackClick = () => {
        if (pathname.includes('workspaces')) {
            customNavigation.replace('/workspaces');
        } else {
            customNavigation.goBack('/workspaces');
        }

    };

    useEffect(() => {
    }, [workspace]);

    const onEditClick = () => {
        customNavigation.push(`/workspaces/${workspaceId}/edit`)
    };

    return (
        <CreateLayout
            title={`View Workspace`}
            formId={'view-workspace'}
            onBackClick={onBackClick}
            isLoading={false}
            showSaveButton={false}
            editButtonTitle={'Edit'}
            onEditClick={onEditClick}
        >
            <div className={styles.ViewWorkspaceScreen}>
                <Form
                    layout="vertical"
                >
                    <Form.Item
                        label={"Unique Name"}
                        name={"uniqueName"}
                    >
                        <div className={styles.value}>
                            {workspace?.uniqueName}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label={"Display Name"}
                        name={"displayName"}
                    >
                        <div className={styles.value}>
                            {workspace?.displayName}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label={"Description"}
                        name={"description"}
                    >
                        <div className={styles.value}>
                            {workspace?.description}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default ViewWorkspaceScreen;