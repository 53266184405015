import React from 'react';
import styles from './skeleton-message.module.scss';
import {Skeleton} from "antd";

function SkeletonMessage() {
    return (
        <div className={styles.SkeletonMessage}>
            <Skeleton avatar active paragraph={{ rows: 1 }} />
        </div>
    );
}

export default SkeletonMessage;