import React, {useState} from 'react';
import styles from "./TenorGifs.module.scss";
import SkeletonGifPreview from "../../../components/skeleton-gif-preview/skeleton-gif-preview";
import Empty from "../../../components/empty/empty";
import InfiniteScroll from "react-infinite-scroll-component";
import {Button, Input, Spin} from "antd";
import {Img} from "react-image";
import SkeletonGifLoader from "../../../components/skeleton-gif-loader/skeleton-gif-loader";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";

const { Search } = Input;

type TenorGifsProps = {
    id: string,
    searchQuery: string,
    execSearch: boolean,
    onSendClick: (gifUrl: string) => void
};

function TenorGifs(props: TenorGifsProps) {
    const { id, onSendClick, searchQuery, execSearch } = props;
    const [limit] = useState(12);
    const [isAtTheEndGif, setIsAtTheEndGif] = useState(false);
    const isLoadingGlobal = useSelector<StoreState, boolean>(state => state.isLoading);

    const {
        data: gifsData,
        isFetching: gifIsFetching,
        fetchNextPage: gifFetchNextPage,
        isLoading: gifIsLoading,
    } = useInfiniteQuery(
        ['gifs', id, searchQuery],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ gifs: any[], nextId: number }>(`/api/search/gif?searchText=${searchQuery}&limit=${limit}&page=${pageParam}`);
            if (data?.gifs?.length < limit) {
                setIsAtTheEndGif(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage, pages) => lastPage.nextId,
            enabled: execSearch
        },
    );

    return (
        <div className={styles.TenorGifs}>
            <div id="scrollableDivGif" className={styles.gifPreviewContainer}>
                {
                    gifIsFetching === true && !gifsData &&
                    [1, 1, 1, 1].map((item, index) => <SkeletonGifPreview key={`${index}`} />)
                }
                {
                    gifIsFetching === false && !gifsData &&
                    <div className={styles.searchImageContainer}>
                        <img
                            className={styles.searchImage}
                            src="https://c.tenor.com/NqtDXFtjZ2UAAAAd/looking-binoculars.gif"
                            alt=""
                        />
                    </div>
                }
                {
                    gifIsFetching === false && gifsData?.pages.map((page) => page.gifs).flat().length === 0 &&
                    <Empty
                        title="No messages"
                        subTitle="Messages will appear here"
                        icon="plus"
                        url="/createclubs"
                        screen="posts"
                    />
                }
                {
                    gifsData?.pages.map((page) => page.gifs).flat().length !== 0 &&
                    <InfiniteScroll
                        //style={{ display: 'flex', flexDirection: 'column-reverse' }}
                        dataLength={gifsData?.pages.map((page) => page.gifs).flat().length || 0}
                        next={() => gifFetchNextPage()}
                        hasMore={(gifsData?.pages.map((page) => page.gifs).flat().length || 0) > 0 && !isAtTheEndGif}
                        loader={(
                            <div className={styles.spinningContainer}>
                                <Spin />
                            </div>
                        )}
                        endMessage={(<p style={{ textAlign: 'center' }} />)}
                        //inverse
                        scrollableTarget="scrollableDivGif"
                        //scrollThreshold={0.3}
                    >
                        {
                            gifsData?.pages.map((page) => page.gifs).flat().map((gif, index) => (
                                <div
                                    key={`${index}-hey`}
                                    className={styles.preview}
                                >
                                    <Img
                                        className={styles.gifPreviewImage}
                                        src={gif?.media[0].tinygif?.url}
                                        loader={<SkeletonGifLoader key={`${index}-image`} />}
                                    />
                                    <Button
                                        size="large"
                                        type="primary"
                                        className={styles.sendGifButton}
                                        onClick={() => onSendClick(gif?.media[0].tinygif?.url)}
                                        loading={isLoadingGlobal}
                                    >
                                        Send
                                    </Button>
                                </div>
                            ))
                        }
                    </InfiniteScroll>
                }
            </div>
        </div>
    );
}

export default TenorGifs;