import React from 'react';
import styles from './card-user.module.scss';
import {User} from "../../models/User";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../models/StoreState";
import {ActionTypes} from "../../actions/types";
import {Button} from "antd";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";

type CardUserProps = {
    user: User;
    currentUserId: string;
    onFollowAction?: (user: User, callback: Function) => void;
    onCardSelect: (user: User) => void;
    followingUserIds: { [key: string]: string };
}

function CardUser(props: CardUserProps) {

    const {
        user, currentUserId, followingUserIds, onFollowAction, onCardSelect,
    } = props;

    const currentId = useSelector<StoreState, string>((state) => state.currentId);
    const isLoading = useSelector<StoreState, boolean>((state) => state.isLoading);
    const dispatch = useDispatch();

    const onFollowClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        dispatch({ type: ActionTypes.TOGGLE_LOADING, value: true });
        dispatch({ type: ActionTypes.SET_CURRENT_ID, value: user._id });
        if (onFollowAction) {
            onFollowAction(user, (followRequest: boolean) => {
                dispatch({type: ActionTypes.TOGGLE_LOADING, value: false});
            });
        }
    };

    return (
        <div role="button" tabIndex={0} className={styles.CardUser} onClick={() => onCardSelect(user)}>
            <div className={styles.imageContainer}>
                <img
                    className={styles.image}
                    src={user.image}
                    alt={user.image}
                    // preview={false}
                    // fallback={fallBackUrl}
                />
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.header}>
                    <div className={styles.name}>
                        <div className={styles.displayName}>
                            {user.name}
                        </div>
                        {/*<div className={styles.username}>*/}
                        {/*    @*/}
                        {/*    {user.username}*/}
                        {/*</div>*/}
                    </div>
                    {
                        currentUserId !== user._id && onFollowAction
                        ? (
                            <Button
                                type="primary"
                                className={followingUserIds[`${user._id}`] ? styles.following : styles.follow}
                                ghost={!followingUserIds[`${user._id}`]}
                                loading={currentId === user._id && isLoading}
                                onClick={onFollowClick}
                            >
                                {/*{followingUserIds[`${user._id}`] ? 'Following' : followRequestUserIds[`${user._id}`] ? 'Requested' : 'Follow'}*/}
                            </Button>
                        ):
                            <Button className={styles.follow} type="link" icon={<ArrowRightOutlined/>} />
                    }
                </div>
                <div className={styles.bio}>
                    {user.bio}
                </div>
            </div>
        </div>
    );
}

export default CardUser;