import {ActionTypes} from "../actions/types";
import {IsAtEndScrolls} from "../models/IsAtEndScrolls";

interface setIsAtEnd {
    type: typeof ActionTypes.SET_IS_AT_END_SCROLL | typeof ActionTypes.UPDATE_IS_AT_END_SCROLL;
    value: IsAtEndScrolls;
}

type scrollActions = setIsAtEnd;

const defaultIsAtEndScrolls: IsAtEndScrolls = {
    METRICS: false,
    METRIC_ITEMS: false,
    WORKSPACES: false,
    TEAMS: false,
    EVENTS: false,
    PRODUCTS: false,
    CREATE_CALENDAR_EVENT: false,
    CALENDAR_EVENTS: false,
    CALENDARS: false,
    CALENDAR_TAGS: false,
}

export const isAtEnd = (state: IsAtEndScrolls = defaultIsAtEndScrolls, action: scrollActions) => {
    if (action.type === ActionTypes.SET_IS_AT_END_SCROLL) {
        return action.value;
    }
    if (action.type === ActionTypes.UPDATE_IS_AT_END_SCROLL) {
        return {...state, ...action.value};
    }
    return state;
};