import React, {useState} from 'react';
import styles from './create-calendar-tag-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {TimeStamps} from "../../../models/TimeStamps";
import {ActionTypes} from "../../../actions/types";
import {useCreateCalendarTag} from "./create-calendar-tag-hooks";
import {Form, Input} from "antd";
import CreateLayout from "../../../layouts/create-layout/create-layout";

function CreateCalendarTagScreen() {

    const { workspace, calendarId } = useParams();
    const customNavigation = useCustomNavigate();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const dispatch = useDispatch();

    const { mutate: createCalendarTag, isLoading } = useCreateCalendarTag(calendarId, (workspace) => {
        let newDate = new Date();
        newDate.setSeconds(newDate.getSeconds() + 5);
        const newTimestamps: Partial<TimeStamps> = { CALENDAR_TAGS: newDate };
        const updatedTimestamps = {...timeStamps, ...newTimestamps};
        dispatch({ type: ActionTypes.SET_TIME_STAMPS, value: updatedTimestamps });
        onBackClick();
    }, (error) => {
        const defaultMessage = "Something went wrong. Please try again."
        setErrorMessage(defaultMessage);
    }, { });

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/calendars/${calendarId}`);
    };

    const onFinish = (values: any) => {
        const data = {
            name: values.name,
        }
        createCalendarTag(data);
    };

    const onFinishFailed = () => {

    };

    const formId = "create-calendar-tag-form";

    return (
        <CreateLayout
            title={"Create Tag"}
            formId={formId}
            onBackClick={onBackClick}
            isLoading={isLoading}
            errorMessage={errorMessage}
            showSaveButton={true}
        >
            <div className={styles.CreateCalendarTagScreen}>
                <Form
                    layout="vertical"
                    id={formId}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            { required: true, message: "Please enter unique name" },
                            {
                                pattern: /^[0-9A-Za-z-]+$/,
                                message: 'Please only use lowercase letters, numbers and hyphens'
                            },
                            {
                                min: 3,
                                message: 'Please enter minimum of 3 letters'
                            }
                        ]
                        }
                        required
                    >
                        <Input
                            size="large"
                            placeholder="Enter unique name for tag"
                        />
                    </Form.Item>
                </Form>
            </div>
        </CreateLayout>
    );
}

export default CreateCalendarTagScreen;