import {useQuery, UseQueryOptions} from "react-query";
import axios, {AxiosError} from "axios";
import {MetricItem} from "../../../models/MetricItem";

export const useMetricItem = (metricItemId: string, options?: UseQueryOptions<MetricItem, AxiosError, MetricItem>) => {
    return useQuery<MetricItem, AxiosError, MetricItem>(['metric-item', metricItemId], async () => {
            const res = await axios.get<MetricItem>(`/api/metric-items/${metricItemId}`);
            return res.data;
        },
        {
            ...options,
            // staleTime: 5000,
            onSuccess: data => {

            }, onError: error => {

            },
        })
};