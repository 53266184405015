import React from 'react';
import { Skeleton } from 'antd';
import styles from './card-one-skeleton.module.scss';

function CardOneSkeleton() {
  return (
    <div className={styles.CardOneSkeleton}>
      <Skeleton avatar active paragraph={{ rows: 1 }} />
    </div>
  );
}

export default CardOneSkeleton;
