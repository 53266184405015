import {useQuery, UseQueryOptions, useMutation} from "react-query";
import {User} from "../../models/User";
import axios, {AxiosError} from "axios";
import {UseMutationOptions} from "react-query/types/react/types";

export const useUpdateCurrentUser = (userId: string | undefined, onSuccess: (data: User) => void, onError: (error: AxiosError) => void, options?: UseMutationOptions<User, AxiosError, FormData>) =>
    useMutation<User, AxiosError, FormData>(['profile', userId], async (userData) => {
            const res = await axios.put<User>(`/api/users/current`, userData);
            return res.data;
        }, { ...options, onSuccess, onError, });

export const useCheckUsernameAvailability = (username: string | undefined, onSuccess: (data: User) => void, onError: (error: AxiosError) => void, options?: UseQueryOptions<User, AxiosError, User>) =>
    useQuery<User, AxiosError, User>(['profile', username], async () => {
            const res = await axios.get<User>(`/api/users/available/username/${username}`);
            return res.data;
        }, { ...options, onSuccess, onError, });