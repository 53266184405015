import React from 'react';
import {Button, Dropdown, Menu} from 'antd';
import {Link, useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './nav-bar.module.scss';
import Logo from '../logo/logo';
import logoIcon from '../../assets/images/small-logo.svg';
import { ActionTypes } from '../../actions/types';
import {useTranslation} from "react-i18next";
import {useCurrentUser, useCurrentWorkspace} from "../../screens/app-hooks";
import WorkspacesDrawer from "./workspaces-drawer/workspaces-drawer";
import {useQueryClient} from "react-query";
import cookies from "js-cookie";

const ProfileMenu = (onLogoutAction: () => void) => (
    <Menu>
      <Menu.Item>
        {" "}
        Profile
        {" "}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={onLogoutAction}>
        {" "}
        <Link to="/" >
          Logout
        </Link>
        {" "}
      </Menu.Item>
    </Menu>
);

function NavBar() {
  const {t} = useTranslation();
  const { workspace, item } = useParams();
  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();
  const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace && workspace !== "workspaces" && !!currentUser?._id })
  const dispatch = useDispatch();

  const isNavBarHidden = () => {

    if (!!item) {
      return true;
    }

    return false;
  };

  const login = () => {
    dispatch({ type: ActionTypes.TOGGLE_AUTH_MODAL, authType: 'login' });
  };

  const signUp = () => {
    dispatch({ type: ActionTypes.TOGGLE_AUTH_MODAL, authType: 'signup' });
  };

  const onProfileImageClick = () => {

  };

  const onOpenDrawerClick = () => {
    if (workspace && currentWorkspace) {
      dispatch({ type: ActionTypes.SET_IS_WORKSPACES_DRAWER_OPEN, value: true })
    }
  }

  const onLogoutAction = () => {
    cookies.remove('navyflow-token');
    queryClient.invalidateQueries().then(() => {
      window.location.reload();
    })
  };

  return (
      <div className={`${styles.navBar} ${isNavBarHidden() && styles.hideNav} `}>
        <WorkspacesDrawer />
        <div className={styles.container}>
          <div className={styles.left} onClick={onOpenDrawerClick}>
            <div className={styles.navMenu}>
              {/*<MenuOutlined className={styles.menuIcon} />*/}
              <img
                  className={styles.logoIcon}
                  src={workspace && currentWorkspace ? currentWorkspace?.image : logoIcon}
                  alt=""
              />
            </div>
            <Logo title={workspace && currentWorkspace ? currentWorkspace.displayName : 'NAVYFLOW'} />
          </div>
          {
            currentUser
                ? (
                    <Dropdown
                        trigger={["click"]}
                        overlay={() => ProfileMenu(onLogoutAction)}
                    >
                      <div className={styles.right}>
                        <div className={styles.username}>
                          {currentUser?.name}
                        </div>
                        <img
                            className={styles.profileImage}
                            // src={workspace && currentWorkspace ? currentWorkspace?.image : currentUser?.image}
                            src={currentUser?.image}
                            alt={currentUser?.image}
                            onClick={onProfileImageClick}
                        />
                      </div>
                    </Dropdown>
                ) : (
                    <div className={styles.right}>
                      {/*<Skeleton.Input style={{ width: '200px', marginRight: '24px' }} />*/}
                      {/*<Skeleton.Avatar active size="default" shape="circle" />*/}
                      <Button type="link" className={styles.loginButton} onClick={login}>{t('main.login')}</Button>
                      <Button type="primary" className={styles.signUpButton} onClick={signUp}>{t('main.signup')}</Button>
                    </div>
                )
          }
        </div>
      </div>
  );
}

export default NavBar;
