import React from 'react';
import styles from './Information.module.scss';
import simple from '../../../assets/images/simple-image.svg';
import speed from '../../../assets/images/speed-image.svg';
import ship from '../../../assets/images/ship-image.svg';
import {useTranslation} from "react-i18next";

function Information() {
  const {t} = useTranslation ();
  return (
    <div className={styles.Information}>
      <div className={styles.informationContainer}>
        <div className={styles.title}>
          Navy
          <span className={styles.lemon}>Flow</span>
          {' '}{t("main.is not")}
          {' '}
          <br />
          {t("main.just-another")}
        </div>
        <div className={styles.subtitle}>
          {t("main.we-rebuilt")} Navy<span className={styles.lemon}>Flow</span> {t("main.from-the")}
          {' '}
          <br />
          {t("main.we-specifically")}
        </div>
        <div className={styles.theWs}>
          <div className={styles.item}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={simple} alt="" />
            </div>
            <div className={styles.text_title}>
              {t("main.simple-title")}
            </div>
            <div className={styles.text_subtitle}>
              {t("main.simple-description")}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={speed} alt="" />
            </div>
            <div className={styles.text_title}>
              {t("main.speed-title")}
            </div>
            <div className={styles.text_subtitle}>
              {t("main.speed-description")}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={ship} alt="" />
            </div>
            <div className={styles.text_title}>
              {t("main.ship-title")}
            </div>
            <div className={styles.text_subtitle}>
              {t("main.ship-description")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
