import React, {useState} from 'react';
import styles from './workspace-drawer-content.module.scss';
import {AppstoreOutlined, SettingOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {ActionTypes} from "../../../../actions/types";
import {useCustomNavigate} from "../../../../hooks/navigation";
import ListLayout from "../../../../layouts/list-layout/list-layout";
import {useInfiniteQuery} from "react-query";
import axios from "axios";
import {UserXWorkspace} from "../../../../models/UserXWorkspace";
import WorkspaceDrawerItem from "./workspace-drawer-item/workspace-drawer-item";
import {useParams} from "react-router-dom";

function WorkspaceDrawerContent() {

    const { workspace, item } = useParams();
    const customNavigation = useCustomNavigate();
    const dispatch = useDispatch();

    const onOpenPreferencesClick = () => {
        dispatch({ type: ActionTypes.SET_IS_PREFERENCES_DRAWER_OPEN, value: true });
    };

    const onViewAllWorkspacesClick = () => {
        customNavigation.replace('/');
        dispatch({ type: ActionTypes.SET_IS_WORKSPACES_DRAWER_OPEN, value: false });
    }

    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [timeStamp] = useState(new Date());
    const [limit] = useState(12);

    const {
        data: users_x_workspaces,
        isFetching,
        fetchNextPage,
    } = useInfiniteQuery(
        ['workspaces-drawer', limit, timeStamp],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ users_x_workspaces: UserXWorkspace[], nextId: number }>(`/api/workspaces?limit=${limit}&page=${pageParam}&timestamp=${timeStamp}&roles=ADMIN,MEMBER`);
            if (data?.users_x_workspaces?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
        },
    );

    const onWorkspaceClick = (e: any, workspaceId: string) => {
        e.stopPropagation();
        customNavigation.push(`/workspaces/${workspaceId}`);
        dispatch({ type: ActionTypes.SET_IS_WORKSPACES_DRAWER_OPEN, value: false });
    };

    const onEnterClick = (workspaceUniqueName: string) => {
        customNavigation.replace(`/${workspaceUniqueName}`);
        dispatch({ type: ActionTypes.SET_IS_WORKSPACES_DRAWER_OPEN, value: false });
    };

    return (
        <div className={styles.WorkspaceDrawerContent}>
            <div className={styles.header}>
                Workspaces
            </div>
            <div className={styles.body}>
                <ListLayout
                    isFetching={isFetching}
                    data={users_x_workspaces?.pages.map((page) => page.users_x_workspaces).flat()}
                    fetchNextPage={fetchNextPage}
                    isAtTheEnd={isAtTheEnd}
                    skeletonCardHeight={100}
                >
                    <>
                        {
                            users_x_workspaces?.pages.map((page) => page.users_x_workspaces).flat().map((user_x_workspace) => {
                                return <WorkspaceDrawerItem
                                    key={user_x_workspace.workspace?._id}
                                    user_x_workspace={user_x_workspace}
                                    onWorkspaceClick={onWorkspaceClick}
                                    workspace={workspace}
                                    onEnterClick={onEnterClick}
                                />
                            })
                        }
                    </>
                </ListLayout>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerItem} onClick={onViewAllWorkspacesClick}>
                    <AppstoreOutlined className={styles.icon} />
                    <span className={styles.title}>View all workspaces</span>
                </div>
                <div className={styles.footerItem} onClick={onOpenPreferencesClick}>
                    <SettingOutlined className={styles.icon} />
                    <span className={styles.title}>Preferences</span>
                </div>
            </div>
        </div>
    );
}

export default WorkspaceDrawerContent;