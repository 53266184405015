import { combineReducers } from 'redux';
import {
    authModal,
    currentId, isCalendarDrawerVisible,
    isLoading,
    isMobileMenuOpen,
    isNewMessageModalOpen, isPreferencesDrawerOpen,
    isSettingsVisible, isWorkspacesDrawerOpen
} from "./display-reducers";
import {timeStamps} from "./time-reducers";
import {calendarFilters} from "./advanced-filters-reducers";
import {isAtEnd} from "./scroll-reducers";
import { calendarRecurringEvents } from './home-calendar-reducers';


export default combineReducers({
    authModal,
    isLoading,
    currentId,
    isNewMessageModalOpen,
    isSettingsVisible,
    isMobileMenuOpen,
    timeStamps,
    isWorkspacesDrawerOpen,
    isPreferencesDrawerOpen,
    isCalendarDrawerVisible,
    calendarFilters,
    isAtEnd,
    calendarRecurringEvents,
});
