import React from 'react';
import styles from './calendar-info-tab.module.scss';
import {Checkbox, Form} from "antd";
import {Calendar} from "../../../../models/Calendar";

type CalendarInfoTabProps = {
    calendar?: Calendar;
}

function CalendarInfoTab(props: CalendarInfoTabProps) {
    const { calendar } = props;
    return (
        <div className={styles.CalendarInfoTab}>
            <Form
                layout="vertical"
                className={styles.ViewCalendarScreen}
            >
                <Form.Item
                    label={"Title"}
                >
                    <div className={styles.value}>
                        {calendar?.title}
                    </div>
                </Form.Item>
                {
                    calendar?.description &&
                    <Form.Item
                        label={"Description"}
                    >
                        <div className={styles.value}>
                            {calendar?.description}
                        </div>
                    </Form.Item>
                }
                <Form.Item
                >
                    <Checkbox checked={true} >{calendar?.private ? "Private": "Public"}</Checkbox>
                </Form.Item>
            </Form>
        </div>
    );
}

export default CalendarInfoTab;