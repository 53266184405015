import {Metric} from "../../../../models/Metric";
import {MetricItem} from "../../../../models/MetricItem";
import currencies from "../../../../utils/currencies.json";

export interface MetricDisplayFields {
    title: string,
    value: number,
    difference: number,
    objective: string,
    timeStamp: string
}

const getMetricSumData = (metric: Metric): MetricDisplayFields => {
    const lastMetric = metric.lastMetric as Metric;
    let difference = 0;

    if (lastMetric) {
        if (lastMetric?.type === "METRICS_ITEMS_SUM") {
            difference = lastMetric.lastMetricItem ? (lastMetric.lastMetricItem as MetricItem).numericValue : 0;
        } else {
            const lastMetricItemValue = lastMetric?.lastMetricItem ? (lastMetric.lastMetricItem as MetricItem).numericValue : 0;
            const secondMetricItemValue = lastMetric.secondLastMetricItem ? (lastMetric.secondLastMetricItem as MetricItem).numericValue : 0;
            difference = lastMetricItemValue - secondMetricItemValue;
        }
    }

    return {
        title: metric.title,
        value: metric.metricsSum ?? 0,
        difference: difference,
        objective: metric.objective,
        timeStamp: metric.updatedAt
    }
}

const getMetricItemSumData = (metric: Metric): MetricDisplayFields => {
    const lastMetricItemValue = metric.lastMetricItem ? (metric.lastMetricItem as MetricItem).numericValue : 0;

    return {
        title: metric.title,
        value: metric.metricItemsSum ?? 0,
        difference: lastMetricItemValue,
        objective: metric.objective,
        timeStamp: metric.updatedAt
    }
}

const getLastMetricItemData = (metric: Metric): MetricDisplayFields => {
    const lastMetricItemValue = metric.lastMetricItem ? (metric.lastMetricItem as MetricItem).numericValue : 0;
    const secondMetricItemValue = metric.secondLastMetricItem ? (metric.secondLastMetricItem as MetricItem).numericValue : 0;

    return {
        title: metric.title,
        value: lastMetricItemValue ?? 0,
        difference: lastMetricItemValue - secondMetricItemValue,
        objective: metric.objective,
        timeStamp: metric.updatedAt
    }
}

export const getDisplayFields = (metric: Metric): MetricDisplayFields => {

    if (metric.type === "METRICS_SUM") {
        return getMetricSumData(metric);
    }

    if (metric.type === "METRICS_ITEMS_SUM") {
        return getMetricItemSumData(metric);
    }

    return getLastMetricItemData(metric);
}

export const getCurrency = (currency: string) => {
    const foundCurrency = Object.entries(currencies).find((entry) => entry[0] === currency);
    if (foundCurrency && foundCurrency[1]) {
        return foundCurrency[1].symbol
    }
    return '';
}