import React, {useEffect} from 'react';
import styles from './home-calendar-screen.module.scss';
import {useLocation, useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../models/StoreState";
import {useCurrentUser, useCurrentWorkspace} from "../../app-hooks";
import {useInfiniteQuery, useQuery} from "react-query";
import axios from "axios";
import {Button, Dropdown, Menu} from "antd";
import {CalendarEvent} from "../../../models/CalendarEvent";
import ListHeader from "../../../components/list-header/list-header";
import CalendarQuickFilters from "./calendar-quick-filters/calendar-quick-filters";
import {PlusOutlined} from "@ant-design/icons";
import CalendarDrawerFilters from "./calendar-drawer-filters/calendar-drawer-filters";
import {ActionTypes} from "../../../actions/types";
import {CalendarFilters} from "../../../models/CalendarFilters";
import queryString from "query-string";
import CalendarView from "./calendar-view/calendar-view";
import { DateTime } from "luxon";
import ListLayout from "../../../layouts/list-layout/list-layout";
import CalendarEventItem from "./calendar-event-item/calendar-event-item";
import {TimeStamps} from "../../../models/TimeStamps";
import {RRule} from "rrule";
import {urlFormattedDate} from "../../../utils/utils";
import {useStore} from "../../../index";

function HomeCalendarScreen() {
    const { workspace } = useParams();
    const customNavigate = useCustomNavigate();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const calendarRecurringEvents = useStore(state => state.calendarRecurringEvents);
    const setCalendarRecurringEvents = useStore(state => state.setCalendarRecurringEvents);
    const isNonRecurringAtTheEnd = useStore(state => state.isNonRecurringAtTheEnd);
    const setIsNonRecurringAtTheEnd = useStore(state => state.setIsNonRecurringAtTheEnd);
    const calendarFilters = useSelector<StoreState, CalendarFilters>((state) => state.calendarFilters);
    const tempNonRecurringLastDate = useStore(state => state.tempNonRecurringLastDate);
    const setTempNonRecurringLastDate = useStore(state => state.setTempNonRecurringLastDate);
    const dispatch = useDispatch();
    const location = useLocation();
    const locationSearch = queryString.parse(location.search) as { tab: string, year?: string, month?: string, day?: string, search?: string, calendar?: string };
    const search = useStore(state => state.search);
    const setSearch = useStore(state => state.setSearch);
    const untilDate = useStore(state => state.untilDate);
    const setUntilDate = useStore(state => state.setUntilDate);
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace });
    const { data: currentUser } = useCurrentUser({});

    const {
        data: calendarEventsPaged,
        isLoading,
        fetchNextPage: fetchNextNonRecurringEventsPage,
    } = useInfiniteQuery(
        ['calendar-events', workspace, timeStamps.CALENDAR_EVENTS, search],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ calendarEvents: CalendarEvent[], nextId: number }>(`/api/calendar-events/workspaces/${currentWorkspace?._id}/non-recurring?limit=${12}&page=${pageParam}&timestamp=${timeStamps.CALENDAR_EVENTS}&search=${search ?? ''}&calendarTag=${calendarFilters.calendarTag ?? ''}&calendar=${locationSearch.calendar ?? ''}&startDate=${calendarFilters.startDate ?? ''}&endDate=${calendarFilters.endDate ?? ''}`);
            if (data?.calendarEvents?.length < 12) {
                setIsNonRecurringAtTheEnd(true);
            }
            if (data?.calendarEvents?.length > 0) {
                const tempStartDate = data?.calendarEvents[data?.calendarEvents?.length - 1].startDate
                setTempNonRecurringLastDate(tempStartDate);
                if (pageParam > 0) {
                    setUntilDate(DateTime.fromJSDate(new Date(tempStartDate)));
                    updateRecurringEventsWithDate(DateTime.fromJSDate(new Date(tempStartDate)));
                }
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!currentWorkspace
        },
    );

    const getDates = (ce: CalendarEvent, tempUntilDate: DateTime) => {
        const tempRrule = new RRule({ ...ce.calendarRecurringEvent.rrule.rrule.origOptions, dtstart: DateTime.now().toJSDate(), until: tempUntilDate.toJSDate() });
        return tempRrule.all().map(date =>
            DateTime.fromJSDate(date)
                .toUTC()
                .setZone('local', { keepLocalTime: true })
                .toJSDate()
        );
    }

    const { data: origCalendarRecurringEvents, isLoading: isOrigCalendarRecurringEventsLoading } = useQuery(['calendar-recurring-events', workspace, search, timeStamps.CALENDAR_EVENTS, locationSearch.calendar], async ({ pageParam = 0 }) => {
        const { data } = await axios.get<CalendarEvent[]>(`/api/calendar-events/workspaces/${currentWorkspace?._id}/recurring?calendarTag=${calendarFilters.calendarTag ?? ''}&calendar=${locationSearch.calendar ?? ''}&timestamp=${timeStamps.CALENDAR_EVENTS}&search=${search ?? ''}&startDate=${calendarFilters.startDate ?? ''}&endDate=${calendarFilters.endDate ?? ''}`);
        return data;
    }, { enabled: !!currentWorkspace?._id && isLoading });

    const calendarEvents = calendarEventsPaged?.pages.map((page) => page.calendarEvents).flat();

    const getCorrectUntilDate = (): DateTime => {
        if (!isNonRecurringAtTheEnd && tempNonRecurringLastDate) {
            return DateTime.fromJSDate(new Date(tempNonRecurringLastDate))
        }
        return untilDate;
    }

    const toISO = (date: Date): string => {
        return date.toUTCString();
    }

    const updateRecurringEventsWithDate = (correctUntilDate2: DateTime) => {
        setCalendarRecurringEvents(origCalendarRecurringEvents?.map((cre) => {
            const dates = getDates(cre, (cre.calendarRecurringEvent?.endDate ? DateTime.fromJSDate(new Date(cre.calendarRecurringEvent?.endDate)): correctUntilDate2)).map((date) => {
                return { ...cre, startDate: toISO(date), endDate: toISO(date) }
            });
            return dates;
        }).flat());
    }

    const updateRecurringEvents = () => {
        const vals = origCalendarRecurringEvents?.map((cre) => {
            const correctUntilDate = getCorrectUntilDate();
            const dates = getDates(cre, (cre.calendarRecurringEvent?.endDate ? DateTime.fromJSDate(new Date(cre.calendarRecurringEvent?.endDate)): correctUntilDate)).map((date) => {
                return { ...cre, startDate: toISO(date), endDate: toISO(date) }
            });
            return dates;
        }).flat();
        setCalendarRecurringEvents(vals);
    }

    useEffect(() => {
        if (!!currentWorkspace?._id && isOrigCalendarRecurringEventsLoading === false) {
            updateRecurringEvents();
        }
    }, [origCalendarRecurringEvents]);

    const onCreateNewEventClick = () => {
        customNavigate.push(`/${workspace}/calendars/events/create`);
    }

    const onCreateNewCalendarClick = () => {
        customNavigate.push(`/${workspace}/calendars/create`);
    }

    const onCreateTagClick = () => {
        customNavigate.push('all');
    }

    const onSearch = (value: string) => {
        customNavigate.pushWithUrlParams(location.pathname, {...locationSearch, search: value, tab: 'list' });
        setSearch(value);
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={onCreateNewEventClick}>
                <Button size="large" type="link">
                    Create Event
                </Button>
            </Menu.Item>
            <Menu.Item onClick={onCreateNewCalendarClick}>
                <Button size="large" type="link">
                    Create Calendar
                </Button>
            </Menu.Item>
            <Menu.Item onClick={onCreateTagClick}>
                <Button size="large" type="link">
                    Create Tag
                </Button>
            </Menu.Item>
        </Menu>
    );

    const onCalendarEventClick = (calendarEvent: CalendarEvent, startDate: Date) => {
        if (calendarEvent.recurring) {
            customNavigate.push(`/${workspace}/calendars/events/${calendarEvent._id}?date=${urlFormattedDate(startDate)}`);
        } else {
            customNavigate.push(`/${workspace}/calendars/events/${calendarEvent._id}`);
        }
    }

    const onAdvanceFilter = () => {
        dispatch({ type: ActionTypes.SET_IS_CALENDAR_DRAWER_VISIBLE, value: true });
    };

    const allCalendarEvents = (calendarEvents ?? []).concat(calendarRecurringEvents ?? []).sort((a, b) => new Date(a.startDate as string).getTime() - new Date(b.startDate as string).getTime());

    const onFetchNextPage = async () => {
        if (isNonRecurringAtTheEnd && calendarRecurringEvents) {
            // this means there are no more non-recurring events -> offset = getLast, recurringMaxOccurence = 24
            const tempUntil = untilDate.plus({ month: 6 });
            setUntilDate(tempUntil);
            const newList = origCalendarRecurringEvents?.map((cre) => {
                const dates = getDates(cre, (cre.calendarRecurringEvent?.endDate ? DateTime.fromJSDate(new Date(cre.calendarRecurringEvent?.endDate)): tempUntil)).map((date) => {
                    return { ...cre, startDate: toISO(date), endDate: toISO(date) }
                });
                return dates;
            }).flat();
            // dispatch({ type: ActionTypes.SET_CALENDAR_RECURRING_EVENTS, value: newList });
            setCalendarRecurringEvents(newList);
        } else {
            await fetchNextNonRecurringEventsPage();
        }
    };

    const getIsRecurringAtTheEnd = () => {
        if (origCalendarRecurringEvents && origCalendarRecurringEvents.length > 0) {
            if (allCalendarEvents && allCalendarEvents.length > 0) {
                const lastItem = allCalendarEvents[allCalendarEvents.length - 1];
                if (new Date(lastItem.startDate) > DateTime.now().plus({ year: 1 }).toJSDate()) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return true;
    }

    return (
        <>
            <CalendarDrawerFilters currentWorkspaceId={currentWorkspace?._id} />
            <ListHeader
                title={"Calendars"}
                onSearch={onSearch}
                defaultSearchValue={locationSearch.search}
                searchPlaceholder={"Search your events"}
                // filterComponent={<CalendarQuickFilters />}
                filterComponent={<div />}
                // onAdvanceFilter={onAdvanceFilter}
                rightItems={<Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                    />
                </Dropdown>}
            />
            {
                locationSearch.tab === "calendar" ?
                    <CalendarView />
                    :
                    <div>
                        <ListLayout
                            isFetching={isLoading}
                            data={allCalendarEvents}
                            fetchNextPage={onFetchNextPage}
                            isAtTheEnd={isNonRecurringAtTheEnd && getIsRecurringAtTheEnd()}
                            skeletonCardHeight={120}
                        >
                            <div className={styles.HomeCalendarScreen}>
                                {
                                    allCalendarEvents.map((val, index) => {
                                        return <CalendarEventItem
                                            key={`${val._id}-${index}-CalendarEventItem`}
                                            index={index}
                                            prevCalendarEvent={allCalendarEvents && index > 0 ? allCalendarEvents[index - 1]: undefined}
                                            nextCalendarEvent={allCalendarEvents && index < allCalendarEvents.length ? allCalendarEvents[index + 1]: undefined}
                                            calendarEvent={val}
                                            onCalendarEventClick={onCalendarEventClick}
                                            filterStartDate={calendarFilters.startDate ?? new Date()}
                                            currentUserId={currentUser?._id}
                                        />
                                    })
                                }
                            </div>
                        </ListLayout>
                    </div>
            }
        </>
    );
}

export default HomeCalendarScreen;