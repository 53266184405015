import React from 'react';
import styles from './view-calendar-screen.module.scss';
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../hooks/navigation";
import {useCalendar} from "./view-calendar-hooks";
import {Tabs} from "antd";
import ViewHeader from "../../../components/view-header/view-header";
import CustomTab from "../../../components/custom-tab/custom-tab";
import CalendarTagsTab from "./calendar-tags-tab/calendar-tags-tab";
import CalendarInfoTab from "./calendar-info-tab/calendar-info-tab";

const { TabPane } = Tabs;
function ViewCalendarScreen() {

    const { workspace, calendarId } = useParams();
    const customNavigate = useCustomNavigate();
    const { data: calendar } = useCalendar(calendarId!, { enabled: !!calendarId });

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/calendars/all`);
    };

    const onEditClick = () => {
        customNavigate.push(`/${workspace}/calendars/${calendar?._id}/edit`);
    };

    return (
        <div
            className={styles.ViewCalendarScreen}
        >
            <ViewHeader
                title={calendar?.title}
                onBackClick={onBackClick}
                onEditClick={onEditClick}
            />
            <Tabs defaultActiveKey="calendar-tags" tabBarGutter={0} >
                <TabPane tab={<CustomTab name={"Tags"} />} key="calendar-tags">
                    <CalendarTagsTab />
                </TabPane>
                <TabPane tab={<CustomTab name={"Information"} />} key="calendar-info">
                    <CalendarInfoTab calendar={calendar} />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default ViewCalendarScreen;