import React from 'react';
import styles from './message-item.module.scss';
import {ChatMessage} from "../../../models/ChatMessage";
import {convertTimeToDaysAgo} from "../../../utils/utils";
import {Img} from "react-image";
import SkeletonGifPreview from "../../../components/skeleton-gif-preview/skeleton-gif-preview";
import SkeletonGifLoader from "../../../components/skeleton-gif-loader/skeleton-gif-loader";

type MessageItemProps = {
    chatMessage: ChatMessage
}

function MessageItem(props: MessageItemProps) {
    const { chatMessage } = props;
    return (
        <div className={styles.MessageItem}>
            <img
                className={styles.image}
                src={chatMessage?.user?.image}
            />
            <div className={styles.content} >
                <div className={styles.header}>
                    <span className={styles.name}>{chatMessage?.user?.name}</span>
                    <span className={styles.timeStamp}>{convertTimeToDaysAgo(chatMessage?.createdAt)}</span>
                </div>
                <Img
                    className={styles.messageBody}
                    src={chatMessage?.gifUrl}
                    loader={<SkeletonGifLoader key={chatMessage._id} />}
                />
            </div>
        </div>
    );
}

export default MessageItem;