import React from 'react';
import styles from './custom-tab.module.scss';

type MetricTabProps = {
    name: string;
};

function CustomTab(props: MetricTabProps) {
    const { name } = props;

    return (
        <div className={styles.CustomTab}>
            <div className={styles.title}>
                {name}
            </div>
        </div>
    );
}

export default CustomTab;