import React from 'react';
import styles from './free-form-document.module.scss';
import {Document} from '../../../../../models/Document'

type FreeFormDocumentProps = {
    document: Document;
}

function FreeFormDocument(props: FreeFormDocumentProps) {
    const { document } = props;
    return (
        <div className={styles.FreeFormDocument}>
            {document.freeForm}
        </div>
    );
}

export default FreeFormDocument;