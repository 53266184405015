import React from 'react';
import {useParams} from "react-router-dom";
import {useDocument} from "./view-document-hooks";
import ViewFreeFormDocumentScreen from "./view-free-form-document-screen/view-free-form-document-screen";
import ViewListDocumentScreen from "./view-list-document-screen/view-list-document-screen";
import ViewFlowDocumentScreen from "./view-flow-document-screen/view-flow-document-screen";

function ViewDocumentsScreens() {

    const { documentId } = useParams();
    const { data: document } = useDocument(documentId!, { enabled: !!documentId });

    return (
        <div>
            {
                document?.type === "FREE_FORM" &&
                <ViewFreeFormDocumentScreen document={document} />
            }
            {
                document?.type === "LIST" &&
                <ViewListDocumentScreen document={document} />
            }
            {
                document?.type === "FLOW" &&
                <ViewFlowDocumentScreen document={document} />
            }
        </div>
    );
}

export default ViewDocumentsScreens;