import React, {useState} from 'react';
import styles from './view-list-document-item.module.scss';
import {Button, Checkbox, Input} from "antd";
import {CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {ListItem} from "../../../../../models/ListItem";

type ViewListDocumentItemProp = {
    onConfirmAction: (itemKey: number, value: ListItem) => void;
    onDeleteAction: (itemKey: number) => void;
    value: ListItem;
}

function ViewListDocumentItem(props: ViewListDocumentItemProp) {

    const { onConfirmAction, value, onDeleteAction } = props;
    const [tempValue, setTempValue] = useState(value);
    const [isInEditMode, setIsInEditMode] = useState(false);

    const onChange = (data: any) => {
        onConfirmAction(tempValue.index, {...tempValue, checked: data.target.checked });
    }

    const onEditClick = () => {
        setIsInEditMode(!isInEditMode);
    }

    const onCancelClick = () => {
        setIsInEditMode(!isInEditMode);
    }

    const onDeleteClick = () => {
        setIsInEditMode(false);
        onDeleteAction(tempValue.index);
    }

    const onConfirmClick = () => {
        onConfirmAction(tempValue.index, tempValue);
        setIsInEditMode(false);
    }

    const onTextChange = (data: any) => {
        setTempValue({ index: tempValue.index, item: data.target.value, checked: false });
    };

    return (
        <div className={styles.ViewListDocumentItem}>
            <Checkbox onChange={onChange} defaultChecked={tempValue.checked}>
                {
                    isInEditMode ?
                        <Input placeholder="Enter text..." value={tempValue.item} onChange={onTextChange} size="large" />
                        :
                        <span className={styles.text}>{tempValue.item}</span>
                }
            </Checkbox>
            {
                isInEditMode ?
                    <div>
                        <Button
                            type="primary"
                            size="large"
                            onClick={onConfirmClick}
                            className={styles.confirmButton}
                        >
                            <CheckOutlined className={styles.confirmIcon} />
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            onClick={onCancelClick}
                            className={styles.cancelButton}
                        >
                            <CloseOutlined className={styles.cancelIcon} />
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            onClick={onDeleteClick}
                            className={styles.deleteButton}
                        >
                            <DeleteOutlined className={styles.deleteIcon} />
                        </Button>
                    </div>
                    :
                    <Button
                        type="link"
                        size="small"
                        onClick={onEditClick}
                    >
                        <EditOutlined className={styles.editIcon} />
                    </Button>
            }
        </div>
    );
}

export default ViewListDocumentItem;