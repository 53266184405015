import React, {useState} from 'react';
import styles from './view-list-document-screen.module.scss';
import {DownOutlined, LockOutlined, PlusOutlined} from "@ant-design/icons";
import ViewHeader from "../../../../components/view-header/view-header";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {Document} from "../../../../models/Document";
import ViewListDocumentItem from "./view-list-document-item/view-list-document-item";
import {Button, Collapse, Dropdown, Menu, Progress, Tabs} from "antd";
import CustomTab from "../../../../components/custom-tab/custom-tab";
import {useUpdateDocument} from "../../edit-document-screens/edit-document-hooks";
import {TimeStamps} from "../../../../models/TimeStamps";
import {ActionTypes} from "../../../../actions/types";
import {useInfiniteQuery, useQueryClient} from "react-query";
import {useCompleteDocumentList, useDocument, useUpdateListItemsDocument} from "../view-document-hooks";
import axios from "axios";
import {Metric} from "../../../../models/Metric";
import {useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {DocumentList} from "../../../../models/DocumentList";
import {useStore} from "../../../../index";
import {DateTime} from "luxon";
import ListLayout from "../../../../layouts/list-layout/list-layout";

type ViewListDocumentScreenProps = {
    document: Document;
}

const { TabPane } = Tabs;
const { Panel } = Collapse;
function ViewListDocumentScreen(props: ViewListDocumentScreenProps) {
    const { document } = props;
    const { completedListsCount } = document;
    const { workspace, documentId } = useParams();
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const timeStampsDocumentList = useStore(state => state.timeStampsDocumentList);
    const setTimeStampsDocumentList = useStore(state => state.setTimeStampsDocumentList);
    const customNavigate = useCustomNavigate();
    const [tempListItems, setTempListItems] = useState<any[]>([]);
    const queryClient = useQueryClient();

    const {
        data: documentListsPaged,
        fetchNextPage,
    } = useInfiniteQuery(
        ['documents-lists', documentId, timeStampsDocumentList],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ documentLists: DocumentList[], nextId: number }>(`/api/documents-list/documents/${documentId}?limit=${12}&page=${pageParam}&timestamp=${timeStampsDocumentList.toJSDate()}`);
            if (data?.documentLists?.length < 12) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!documentId
        },
    );

    const { mutate: updateDocument, isLoading } = useUpdateListItemsDocument(documentId!, (workspace) => {
        queryClient.refetchQueries(['document', documentId]);
    }, (error) => {
    }, {  });

    const { mutate: createDocumentList } = useCompleteDocumentList(documentId!, (workspace) => {
        queryClient.refetchQueries(['document', documentId]);
        setTimeStampsDocumentList(DateTime.now().plus({ second: 5 }));
        let listItems = [...tempListItems.map((item) => ({...item, checked: false }))];
        setTempListItems(listItems);
        // queryClient.refetchQueries(['documents-lists', documentId]);
    }, (error) => {
    }, {  });

    const onBackClick = () => {
        customNavigate.goBack(`/${workspace}/documents`);
    };

    const onEditClick = () => {
        customNavigate.push(`/${workspace}/documents/${documentId}/edit`);
    };

    const onChange = () => {

    }

    const onCompleteList = () => {
        createDocumentList({ documentId });
    }

    const onResetList = () => {
        if (tempListItems.length === 0 && document?.listItems) {
            let listItems = [...document?.listItems?.map((item) => ({...item, checked: false }))];
            updateDocument({ listItems });
            setTempListItems(listItems);
        } else {
            let listItems = [...tempListItems.map((item) => ({...item, checked: false }))];
            updateDocument({ listItems });
            setTempListItems(listItems);
        }
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={onCompleteList}>
                <Button size="large" type="link">
                    Complete
                </Button>
            </Menu.Item>
            <Menu.Item onClick={onResetList}>
                <Button size="large" type="link">
                    Reset
                </Button>
            </Menu.Item>
        </Menu>
    );

    const onCheckboxChange = (index: number, checked: boolean) => {
        if (tempListItems.length === 0 && document?.listItems) {
            let listItems = [...document?.listItems];
            listItems[index] = {...listItems[index], checked }
            updateDocument({ listItems });
            setTempListItems(listItems);
        } else {
            let listItems = [...tempListItems];
            listItems[index] = {...listItems[index], checked }
            updateDocument({ listItems });
            setTempListItems(listItems);
        }
    }

    const documentLists = documentListsPaged?.pages.map((page) => page.documentLists).flat()
    const percent = Math.floor((((document?.listItems?.filter((item) => item.checked))?.length ?? 0)/(document?.listItems?.length ?? 0)*100));
    return (
        <div className={styles.ViewListDocumentScreen}>
            <ViewHeader
                title={document?.title}
                iconComponent={document?.visibility === "PRIVATE" && <LockOutlined className={styles.privateIcon} />}
                onBackClick={onBackClick}
                onEditClick={onEditClick}
            />
            <Tabs className={styles.tabContainer} tabBarGutter={0} defaultActiveKey="Lists" onChange={onChange}>
                <TabPane tab={<CustomTab name={"List"} />} key="List">
                    <div className={styles.listContainer} style={{ padding: 16 }}>
                        <div className={styles.actionsContainer}>
                            <Progress
                                percent={percent}
                                steps={document?.listItems?.length} size={(document?.listItems?.length ?? 0) > 20 ? "small": "default"}
                                status="active"
                                strokeColor={percent === 100 ? "#52c41a": undefined}
                            />
                            <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
                                <Button className={styles.complete} type="primary" size="large" >Complete<DownOutlined /></Button>
                            </Dropdown>
                        </div>
                        <div>
                            {
                                document?.listItems?.map((item) => {
                                    return <ViewListDocumentItem
                                        key={item.index}
                                        value={item.item}
                                        index={item.index}
                                        checked={item.checked}
                                        onCheckboxChange={onCheckboxChange}
                                    />
                                })
                            }
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={<CustomTab name={`Completed Lists ${completedListsCount ? `(${completedListsCount})`: ''}`} />} key="completed lists">
                    <ListLayout
                        isFetching={isLoading}
                        data={documentLists}
                        fetchNextPage={fetchNextPage}
                        isAtTheEnd={isAtTheEnd}
                        skeletonCardHeight={120}
                    >
                        <div className={styles.completedListContainer}>
                            {
                                documentLists?.map((doc, index) => {
                                    const docPercent = Math.floor((((doc?.listItems?.filter((item) => item.checked))?.length ?? 0)/(doc?.listItems?.length ?? 0)*100));
                                    return <Collapse className={styles.completedListItem} key={doc?._id} >
                                        <Panel
                                            header={
                                                <span>
                                            <span>
                                                {(new Date(doc?.createdAt)).toLocaleString(undefined, {
                                                    day: 'numeric', month: 'short', year: "numeric"
                                                })}
                                            </span>
                                            <span style={{ marginLeft: 16 }}>
                                                <Progress
                                                    percent={docPercent}
                                                    steps={doc?.listItems?.length} size={(doc?.listItems?.length ?? 0) > 20 ? "small": "default"}
                                                    status="active"
                                                    strokeColor={docPercent === 100 ? "#52c41a": undefined}
                                                />
                                            </span>
                                        </span>
                                            }
                                            key="1"
                                        >
                                            {
                                                doc?.listItems?.map((item) => {
                                                    return <ViewListDocumentItem
                                                        key={item.index}
                                                        value={item.item}
                                                        index={item.index}
                                                        checked={item.checked}
                                                    />
                                                })
                                            }
                                        </Panel>
                                    </Collapse>
                                })
                            }
                        </div>
                    </ListLayout>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default ViewListDocumentScreen;