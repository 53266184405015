import {ActionTypes} from "../actions/types";
import {TimeStamps} from "../models/TimeStamps";

interface setTimeStamps {
    type: typeof ActionTypes.SET_TIME_STAMPS | typeof ActionTypes.UPDATE_TIME_STAMPS;
    value: TimeStamps;
}

type timeActions = setTimeStamps;

const defaultTimestamps: TimeStamps = {
    METRICS: new Date(),
    METRIC_ITEMS: new Date(),
    METRICS_CHILDREN: new Date(),
    WORKSPACES: new Date(),
    TEAMS: new Date(),
    EVENTS: new Date(),
    PRODUCTS: new Date(),
    CREATE_CALENDAR_EVENT: new Date(),
    CALENDAR_EVENTS: new Date(),
    CALENDARS: new Date(),
    CALENDAR_TAGS: new Date(),
    CALENDAR_VIEW: new Date(),
    DOCUMENTS: new Date()
}

export const timeStamps = (state: TimeStamps = defaultTimestamps, action: timeActions) => {
    if (action.type === ActionTypes.SET_TIME_STAMPS) {
        return action.value;
    }
    if (action.type === ActionTypes.UPDATE_TIME_STAMPS) {
        return {...state, ...action.value};
    }
    return state;
};