import React, {useState} from 'react';
import styles from './metrics-tab.module.scss';
import {Button, Drawer} from "antd";
import ListLayout from "../../../../layouts/list-layout/list-layout";
import {useParams} from "react-router-dom";
import {useCustomNavigate} from "../../../../hooks/navigation";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../../models/StoreState";
import {TimeStamps} from "../../../../models/TimeStamps";
import {useCurrentWorkspace} from "../../../app-hooks";
import {useInfiniteQuery, useQueryClient} from "react-query";
import axios from "axios";
import {Metric} from "../../../../models/Metric";
import MetricChildrenItem from "./metric-children-item/metric-children-item";
import {ActionTypes} from "../../../../actions/types";
import {useAddMetricToMetric} from "./metrics-tab-hooks";
import {getCurrency, getDisplayFields} from "../../list-metrics-screen/metrcis-item/metrics-item-helper";

type MetricsTabProps = {
    metric: Metric
};

function MetricsTab(props: MetricsTabProps) {

    const { metric } = props;
    const { workspace, metricId } = useParams();
    const customNavigation = useCustomNavigate();
    const [isAtTheEnd, setIsAtTheEnd] = useState(false);
    const [limit] = useState(120);
    const queryClient = useQueryClient();
    const [isMetricsDrawerOpen, setIsMetricsDrawerOpen] = useState(false);
    const [activeMetricId, setActiveMetricId] = useState<string>();
    const timeStamps = useSelector<StoreState, TimeStamps>((state) => state.timeStamps);
    const { data: currentWorkspace } = useCurrentWorkspace(workspace, { enabled: !!workspace });
    const dispatch = useDispatch();
    const { value } = getDisplayFields(metric);


    const onClose = () => {
        setIsMetricsDrawerOpen(false);
    }

    const onBackClick = () => {
        customNavigation.goBack(`/${workspace}/metrics`);
    };

    const { mutate: createMetricXMetric, isLoading: createIsLoading } = useAddMetricToMetric(metricId!, (workspace) => {
        queryClient.refetchQueries(['metric-assigned', metricId]).then(() => {
            let newDate = new Date();
            newDate.setSeconds(newDate.getSeconds() + 5);
            dispatch({ type: ActionTypes.UPDATE_TIME_STAMPS, value: { METRICS_CHILDREN: newDate } });
            onClose();
        });
    }, (error) => {
    }, {  });

    const {
        data: metricAssignablePaged,
        fetchNextPage: fetchNextAssignablePage,
        isLoading: isLoadingAssignable,
    } = useInfiniteQuery(
        ['metric-assignable', metricId],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ metrics: Metric[], nextId: number }>(`/api/metrics-x-metrics/${metricId}/workspaces/${currentWorkspace?._id}/assignable?limit=${limit}&page=${pageParam}&unit=${metric.unit}&timestamp=${timeStamps.METRICS_CHILDREN}`);
            if (data?.metrics?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!currentWorkspace?._id
        },
    );

    const {
        data: metricAssignedPaged,
        fetchNextPage,
        isLoading,
    } = useInfiniteQuery(
        ['metric-assigned', metricId],
        async ({ pageParam = 0 }) => {
            const { data } = await axios.get<{ metrics: Metric[], nextId: number }>(`/api/metrics-x-metrics/${metricId}/workspaces/${currentWorkspace?._id}/assigned?limit=${limit}&page=${pageParam}&timestamp=${timeStamps.METRICS_CHILDREN}`);
            if (data?.metrics?.length < limit) {
                setIsAtTheEnd(true);
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextId,
            enabled: !!currentWorkspace?._id
        },
    );

    const onCreateNewMetricClick = () => {
        setIsMetricsDrawerOpen(true);
    };

    const onMetricClick = (childMetricId: string) => {
        setActiveMetricId(childMetricId);
        createMetricXMetric({ childMetricId: childMetricId, workspaceId: currentWorkspace?._id! });
    };

    const metricAssigned = metricAssignedPaged?.pages.map((page) => page.metrics).flat();
    const metricAssignable = metricAssignablePaged?.pages.map((page) => page.metrics).flat();

    return (
        <div className={styles.MetricsTab}>
            <Drawer
                placement="bottom"
                closable={false}
                onClose={onClose}
                visible={isMetricsDrawerOpen}
                bodyStyle={{  }}
                height={500}
            >
                <div className={styles.MetricsTabDrawer}>
                    <div className={styles.metricsTabDrawerTitle}>
                        Select Metric
                    </div>
                    <ListLayout
                        isFetching={isLoading}
                        data={metricAssignable}
                        fetchNextPage={fetchNextPage}
                        isAtTheEnd={isAtTheEnd}
                        skeletonCardHeight={60}
                    >
                        <>
                            {
                                metricAssignable?.map((val, index) => {
                                    return <MetricChildrenItem
                                        key={`${val?._id}-assignable`}
                                        metric={val}
                                        parentMetric={metric}
                                        activeMetricId={activeMetricId}
                                        onMetricClick={onMetricClick}
                                        loading={createIsLoading}
                                    />
                                })
                            }
                        </>
                    </ListLayout>
                </div>
            </Drawer>
            <div className={styles.sumContainer}>
                <span>{metric.unit === 'CURRENCY' && getCurrency(metric.currency)}</span>
                <span>{value}</span>
            </div>
            <div className={styles.addMetricContainer}>
                <Button
                    type="dashed"
                    size="large"
                    className={styles.addMetricButton}
                    onClick={onCreateNewMetricClick}
                >
                    ADD METRIC
                </Button>
            </div>
            <ListLayout
                isFetching={isLoading}
                data={metricAssigned}
                fetchNextPage={fetchNextPage}
                isAtTheEnd={isAtTheEnd}
                skeletonCardHeight={60}
            >
                <>
                    {
                        metricAssigned?.map((val, index) => {
                            return <MetricChildrenItem
                                key={`${val?._id}-assigned`}
                                parentMetric={metric}
                                metric={val}
                            />
                        })
                    }
                </>
            </ListLayout>
        </div>
    );
}

export default MetricsTab;