import React, {useEffect, useRef} from 'react';
import styles from "./landing-screen.module.scss";
import Main from "./main/main";
import JoinUs from "./join-us/join-us";
import Footer from './footer/footer';
import {Button, Modal, Statistic} from "antd";
import Info from "./info/info";
import HowItWorks from "./how-it-works/how-it-works";
import Information from "./Information/Information";

const { Countdown } = Statistic;
type LandingScreenProps = {
    userExists?: boolean;
    notFound?: boolean;
};

function LandingScreen(props: LandingScreenProps) {
    const { userExists, notFound } = props;

    const error = (title: string, content: string) => {
        Modal.error({
            title,
            content,
        });
    };

    useEffect(() => {
        if (userExists) {
            error('User already exists', 'Please login in');
        }

        if (notFound) {
            error('User not found', 'Please sign up');
        }
    }, [])

    return (
        <div className={styles.LandingScreen}>
            {/*<Affix offsetTop={0}>*/}
            {/*    <NavBar />*/}
            {/*</Affix>*/}
            <div className={styles.body}>
                <Main />
                <Information />
                <HowItWorks />
                <Footer />
            </div>
        </div>
    );
}

export default LandingScreen;